import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import MomentUtils from "@date-io/moment/build/index";
import moment from "moment/moment";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Import extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            files: '',
            isManage: false,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Impor Produk';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Daftar Produk');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        let params = {
            'csv-encode': this.state.products,
        };


        Api.post('/product/upload-file', params).then(resp => {

            if(resp.status === 200) {
                history.push('/products');

                this.showMessage(true, 'Produk berhasil diimpor');
            }
            else {
                this.showMessage(true, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });



    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleFile = e => {
        let idCardBase64 = '';
        this.setState({
            loadingButton: true,
        });
        if(e.target.files[0]) {
            this.getBase64(e.target.files[0], (result) => {
                idCardBase64 = result;
            });

            setTimeout(
                () => {
                    this.setState({
                        products: idCardBase64,
                        loadingButton: false,
                    });

                },2000);
        }else {
            this.showMessage(false, 'Upload bermasalah');

            this.setState({
                loadingButton: false,
            });
        }

        this.setState({
            products: e.target.files[0],
        })
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Impor</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/products" >Produk</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Impor</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Impor Produk</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="import" id="import" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>File </label>
                                    <TextField variant="outlined"
                                               type="file"
                                               id='products'
                                               name="products"
                                               className="products"
                                               onChange={this.handleFile}
                                    />
                                    {validator.message('products', this.state.products, 'required')}
                                    <div className='text-danger'>{this.state.errors.products}</div>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/products')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Impor{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>

                                }

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default Import;
