import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class PageEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            editorState: '',

            id: '',
            title: '',
            content: '',
            slug: '',
            photo: null,
            image: '',
            tags: [],

            categories: [],

            isEditor: false,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Ubah Halaman';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Halaman');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        const id = this.props.match.params.id;

        this.setState({
            loading: true
        });

        Api.get('/blog-page/by-slug/'+id).then(resp => {

            if (resp.data) {
                let editorState = '';
                if(!resp.data['is-editor']) {
                    let data = convertFromRaw(JSON.parse(resp.data.content));
                    editorState = EditorState.createWithContent(data);
                }

                this.setState({
                    id: resp.data.id,
                    title: resp.data.title,
                    content: resp.data.content,
                    isEditor: resp.data['is-editor'],
                    editorState: editorState,
                    slug: resp.data.slug,
                    loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){
        if(prop === 'title') {

            this.setState({
                slug: this.convertToSlug(e.target.value)
            })
        }

        this.setState({

            [prop]: e.target.value

        })

    };

    convertToSlug(Text) {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    onEditorStateChange = (editorState) => {
        console.log(editorState)
        this.setState({
            editorState,
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let text = '';

        if(this.state.editorState) {
            text = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
        }

        let params = {
            'id': this.state.id,
            'title': this.state.title,
            'content': this.state.isEditor ? this.state.content : text,
            'slug': this.state.slug,
            'is-blog': false,
            'is-editor': this.state.isEditor,
        };
        Api.put('/blog-page', params).then(resp => {

            if(resp.status === 200) {
                history.push('/pages');
                this.showMessage(true, 'Halaman berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });


    };


    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                image: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleGo  = (link) => {

        history.push(link);
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleDeleteAvatar = () => {

        this.setState({
            photo: user,
            image: null
        });
    };

    handleDelete = (chipToDelete) => () => {
        let data = this.state.categories.filter((chip) => chip.id !== chipToDelete.id);
        this.setState({
            categories: data,
        })
    };

    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocompelte options property.
            console.log(this.state.tags);
        });
    };

    handleChecked = (e) => {

        this.setState({

            isEditor: e.target.checked,

        })

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Halaman</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/pages" >Halaman</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Ubah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Ubah Halaman</h2>
                            </div>
                            <div className="col-6 text-right">
                                <a href={process.env.REACT_APP_APP_WEB+'/pages/'+this.state.slug} target='_blank' className='btn-link'>
                                    <i className="fas fa-globe-europe mr-2"> </i>
                                    Buka Halaman
                                </a>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="edit" id="editPage" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group slug-group">
                                    <i className="fas fa-link"> </i>
                                    <span>{process.env.REACT_APP_APP_WEB}/pages/</span>
                                    <input className='input-link' type='text'
                                           onChange={(e) => this.handleChange(e, 'slug')}
                                           value={this.state.slug}
                                    />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label>Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='title'
                                               name="title"
                                               label="Title"
                                               onChange={(e) => this.handleChange(e, 'title')}
                                               value={this.state.title}
                                               fullWidth
                                    />
                                    {validator.message('title', this.state.title, 'required')}
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label>HTML Editor</label>
                                    <FormGroup className='mb-3 label-tnc'>
                                        <FormControlLabel
                                            color="primary"
                                            name='isEditor'
                                            control={<Checkbox
                                                checked={this.state.isEditor}
                                                onChange={this.handleChecked}
                                                name='isEditor'
                                                value={true}
                                            />}
                                            label='Ya'
                                            className="mb-0"
                                        />
                                    </FormGroup>
                                    <div className='text-danger'>{this.state.errors.isEditor}</div>
                                </div>
                            </div>

                            {this.state.isEditor ?
                                <div className="col-lg-10">
                                    <div className="form-group">
                                        <label className='d-block'>Content</label>
                                        <textarea
                                            rows="10"
                                            value={this.state.content}
                                            onChange={(e) => this.handleChange(e, 'content',)}></textarea>
                                        <div className='text-danger'>{this.state.errors.content}</div>
                                    </div>
                                </div>
                                 :
                                <div className="col-lg-10">
                                    <div className="form-group">
                                        <label>Content</label>
                                        <Editor
                                            editorState={this.state.editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={this.onEditorStateChange}
                                        />
                                        <div className='text-danger'>{this.state.errors.editorState}</div>
                                    </div>
                                </div>
                            }


                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/pages')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Simpan { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default PageEdit;
