import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import photo from "../../../images/product.png";
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from "moment/moment";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();
const validatorAttr = new SimpleReactValidator();
const validatorSimple = new SimpleReactValidator();
const validatorVar = new SimpleReactValidator();

class ProductsEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            value: 0,

            galleries: [],
            categories: [],
            subcategoriesOld: [],
            subcategories: [],
            attributes: [],
            variations: [],
            attrVariations: [],
            levels: [],
            types: [],

            id: '',
            name: '',
            description: '',
            cat: '',
            sub: '',
            sku: '',
            regulerPrice: '',
            salePrice: '',
            hppPrice: '',
            fixedPoint: '',
            urlProduct: '',
            stockStatus: '',
            stockQuantity: '',
            weight: '',
            length: '',
            width: '',
            height: '',
            note: '',
            review: false,
            type: '',
            code: '',
            startSale: null,
            endSale: null,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Edit Produk';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Daftar Produk');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }
        Api.get('/product-category').then(resp => {

            if (resp.data) {

                let cats = [];

                _.forEach(resp.data, function(v,k){
                    if(v.isActive === true){
                        cats.push(v)
                    }
                });
                this.setState({
                        categories: cats,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });


        Api.get('/lookup/by-group-id/2').then(resp => {

            if (resp.data) {
                this.setState({
                    types: resp.data
                });
            }

        }).catch(err => {
            console.log(err);
        });


        this.__fetchData();
    }

    __fetchData = () => {
        const id = this.props.match.params.id;
        let list = [];

        Api.get('/lookup/by-group-id/1').then(resp => {

            if (resp.data) {

                let data = resp.data.map(o => {
                    return {
                        'customer-type-id': o.id,
                        name: o.name,
                        'ref-points': [
                            {
                                level: 1,
                                value: ''
                            },
                            {
                                level: 2,
                                value: ''
                            },
                            {
                                level: 3,
                                value: ''
                            },
                            {
                                level: 4,
                                value: ''
                            },
                            {
                                level: 5,
                                value: ''
                            }
                        ]
                    }
                });

                list = data;

            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/product/'+id).then(resp => {

            let data = resp.data;
            if (data) {


                let attrs = data['tab-attribute'];
                let vars = data['tab-variation'];
                _.forEach(vars, function(v,k){
                    _.forEach(v.attributes, function(a,b){
                        let x = attrs.filter((item) => item['id'] === a['id-attribute']);
                        if(x[0]) {
                            a['name'] = x[0].name;
                            a['value'] = x[0].value;
                        }
                    });
                });

                this.setState({
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        cat: data['category'] ? data['category']['id'] : '',
                        sub: data['sub-categories'] ? data['sub-categories'][0]['sub-category-id'] : '',
                        sku: data.sku,
                        regulerPrice: data['regular-price'],
                        salePrice: data['sale-price'],
                        hppPrice: data['purchase-price'],
                        fixedPoint: data['product-fixed-point'],
                        urlProduct: data['product-url'],
                        stockStatus: data['stock-status'],
                        stockQuantity: data['stock-quantity'],
                        weight: data.weight,
                        length: data.length,
                        width: data.width,
                        height: data.height,
                        note: data['notes'],
                        review: data['enable-reviews'],
                        attributes: data['tab-attribute'],
                        variations: data['tab-variation'],
                        subcategoriesOld: data['sub-categories'] !== null ? data['sub-categories'] : [],
                        galleries: data['galeries'],
                        type: data['product-type-id'],
                        code: data['product-type-code'],
                        levels: data['referal-points'].length > 0 ? data['referal-points'] : list,
                        loading: false
                }, () => {
                    this.__fetchSub();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChange (e, prop){
        this.setState({
                errors: {}
            }
        );
        if(prop === 'sku') {
            let value = e.target.value;
            let regex = /^[0-9a-zA-Z/-]*$/;

            if(regex.test(value)) {
                this.setState({

                    [prop]: value

                });
            }else {
                this.setState({
                        errors: {
                            sku: 'SKU tidak diperbolehkan adanya simbol dan spasi kecuali (/) dan (-)',
                        }
                    }
                );
            }
        }else if(prop === 'type') {
            let code = this.filterType(e.target.value);

            this.setState({
                code: code.code,
                [prop]: e.target.value

            })
        } else {
            this.setState({

                [prop]: e.target.value

            })
        }

    };

    filterType = (type) => {
        let all = this.state.types.filter((item) => item.id === type);
        return all[0];
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let cats = [];

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );
            this.showMessage(false, 'Ada field yang harus diisi');
            return false;

        }

        if(this.state.type === 2) {
            if (!validatorSimple.allValid()) {

                this.setState({
                        errors: validatorSimple.getErrorMessages()
                    }
                );
                this.showMessage(false, 'Ada field yang harus diisi');
                return false;

            }
        }

        if (!validatorVar.allValid()) {

            this.setState({
                    errors: validatorVar.getErrorMessages()
                }
            );
            this.showMessage(false, 'Ada field variasi yang harus diisi');
            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let data = this.state.galleries.map(o => {
            return {
                id: o.id,
                image: o.image,
                isFavourite: o.isFavourite,
        }
        });



        let params = {
            id: this.state.id,
            name: this.state.name,
            sku: this.state.sku,
            description: this.state.description,
            'sub-categories': this.state.subcategories,
            'regular-price': this.state.regulerPrice,
            'product-type-id': this.state.type,
            'sale-price': this.state.salePrice,
            'purchase-price': this.state.hppPrice,
            'product-fixed-point': this.state.fixedPoint,
            'product-url': this.state.urlProduct,
            'stock-status': this.state.stockStatus,
            'stock-quantity': this.state.stockQuantity,
            weight: this.state.weight,
            length: this.state.length,
            width: this.state.width,
            height: this.state.height,
            notes: this.state.note,
            galeries: data,
            'enable-reviews': this.state.review,
            'referal-points': this.state.levels,
            'tab-attribute': this.state.attributes,
            'tab-variation': this.state.variations,
            'sale-start-date': this.state.startSale ? moment(this.state.startSale).format('YYYY-MM-DD') : null,
            'sale-end-date': this.state.endSale ? moment(this.state.endSale).format('YYYY-MM-DD') : null,
        };

        Api.put('/product', params).then(resp => {

            if(resp.status == 200) {
                this.showMessage(true, 'Produk berhasil diubah');

                this.setState({

                        loadingButton: false
                    }
                );
            }else {
                this.showMessage(false, resp.message[0]);

                this.setState({

                        loadingButton: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loadingButton: false
                }
            );

        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleCat (e, prop){

        if(this.state.cat !== '' && this.state.cat !== null) {
            this.showMessage(false, 'Kategori tidak bisa diubah');
            return false
        }
        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchSub();

        });

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked,

        });

    };

    handleCheckedCat = id => e => {
        let data = this.state.subcategories;
        _.forEach(data, function(v,k){
            if(v['sub-category-id'] === id){
                v['is-check'] = e.target.checked;
            }
        });

        this.setState({
            sub: data,
            subcategories: data,
        });

    };

    __fetchSub = () => {
        Api.get('/product-sub-category/category-id/'+this.state.cat).then(resp => {

            if (resp.data) {
                let subs = [];

                let old = this.state.subcategoriesOld;
                /*_.forEach(this.state.subcategoriesOld, function (v) {
                    _.forEach(resp.data, function (a) {
                        if(v['sub-category-id'] === a.id && a.isActive) {
                            subs.push(v);
                        }
                    });
                });*/

                _.forEach(resp.data, function (v) {
                    let item = false;

                    _.forEach(old, function (a) {
                        if(a['sub-category-id'] === v.id && v.isActive) {
                            item = a['is-check'];
                        }
                    });
                    subs.push({
                        'is-check': item,
                        name: v.name,
                        'sub-category-id': v.id
                    });
                    console.log(subs)

                });

                this.setState({
                        subcategories: subs,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });
        if(e.target.files[0]) {
            let all = this.state.galleries;

            this.getBase64(e.target.files[0], (result) => {
                idCardBase64 = result;
            });

            setTimeout(
                () => {
                    let img = {
                        photo: URL.createObjectURL(url),
                        image: idCardBase64,
                        isFavourite: false,
                    };

                    all.push(img);

                    this.setState({
                        galleries: all,
                        loadingImage: false,
                    });

                },2000);
        }else {
            this.showMessage(false, 'Upload bermasalah');

            this.setState({
                loadingImage: false,
            });
        }

    };

    handleDeleteAvatar = (item,key) => {
        this.setState({
            loadingImage: true,
        });
        let data = [];
        if(item.id) {
            Api.delete('/product/delete-gallery/'+item.id).then(resp => {
                            if(resp.status === 200) {
                                this.showMessage(true, 'Image berhasil dihapus');
                                this.__fetchData();
                            }else {
                                this.showMessage(false, resp.message[0]);
                            }
                            this.setState({
                                    showDialog: false,
                                }
                            );

                        }).catch(err => {

                            this.setState({
                                    showDialog: false
                                }
                            );

                        });
        }else {
            data = this.state.galleries.filter((item, i) => i !== key);

        }


        this.setState({
            galleries: data,
            loadingImage: false,
        });
    };

    handleFavouriteAvatar = (key) => {
        this.setState({
            loadingImage: true,
        });
        let data = this.state.galleries;
        _.forEach(data, function (v, k) {
            if (k === key) {
                v.isFavourite = !v.isFavourite;
            }else {
                v.isFavourite = false
            }
        });

        this.setState({
            galleries: data,
            loadingImage: false,
        });
    };

    handleDeleteAttr = (key, id) => {

        if(key.id) {
            let params = {
                listId: [key.id]
            };

            Api.delete('/attribute', params).then(resp => {
                if(resp.status === 200) {
                    this.showMessage(true, 'Attribute berhasil dihapus');
                    this.__fetchData();
                }else {
                    this.showMessage(false, resp.message[0]);
                }
                this.setState({
                        showDialog: false,
                    }
                );

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }else {
            let data = this.state.attributes.filter((item, i) => i !== id);
            this.setState({
                attributes: data,
            });
        }
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    handleChangeAttribute = (e, prop, id) => {
        let data = this.state.attributes;

        _.forEach(data, function(v,k){
            if(k === id){
                if(prop === 'name' || prop === 'value') {
                    v[prop] = e.target.value;
                }else{
                    v[prop] = e.target.checked;
                }
            }
        });

        this.setState({
            errors: {},
            attributes: data,
        });
    };

    handleAddAttribute = () => {
        let all = this.state.attributes;
        let news = {
            'product-id': this.state.id,
            'is-active': false,
            'is-visible': false,
            name: '',
            value: '',
        };
        all.push(news);

        this.setState({
            attributes: all,
        });

    };

    handleAddVariation = () => {
        if(this.state.attributes.length < 1) {
            this.showMessage(false, 'Buat Attribute terlebih dahulu');

            return false;
        }

        let attr = [];
            this.state.attributes.map(o => {
            if(o['is-active']) {
                attr.push({
                    id: 0,
                    'id-attribute': o.id,
                    'name': o.name,
                    'value': o.value,
                    'id-value': '',
                })
            }

        });

        let all = this.state.variations;
        let news = {
            attributes: attr,
            sku: '',
            'regular-price': '',
            'stock-quantity': '',
            weight: '',
            length: '',
            width: '',
            height: '',
            description: '',
        };
        all.push(news);

        this.setState({
            variations: all,
        })

    };

    handleChangeVariations = (e, prop, id) => {
        let data = this.state.variations;

        if(prop === 'image') {
            let idCardBase64 = '';
            let url = e.target.files[0];
            this.setState({
                loadingImage: true,
            });

            this.getBase64(e.target.files[0], (result) => {
                idCardBase64 = result;
            });

            setTimeout(
                () => {

                    _.forEach(data, function(v,k){
                        if(k === id){
                            v[prop] = idCardBase64;
                            v['photo'] =  URL.createObjectURL(url);
                        }
                    });


                    this.setState({
                        errors: {},
                        variations: data,
                        loadingImage: false
                    });
                },
                3000
            );
        }else {
            _.forEach(data, function(v,k){
                if(k === id){
                    v[prop] = e.target.value;
                }
            });

            this.setState({
                errors: {},
                variations: data,
            });
        }

    };

    handleChangeAttrVariations = (e, prop, id, itemId) => {
        let data = this.state.variations;

        _.forEach(data, function(v,k){
            if(k === id){
                _.forEach(v.attributes, function(a,b){
                    if(a['id-attribute'] === itemId){
                        a['id-value'] = e.target.value;
                    }
                });
            }
        });
        this.setState({
            errors: {},
            variations: data,
        });

    };

    handleSaveAttr = (item) => {
        item['product-id'] = this.state.id;
        this.setState({
            loadingButton: true,
        });

        if (!validatorAttr.allValid()) {

            this.setState({
                    errors: validatorAttr.getErrorMessages()
                }
            );

            return false;

        }

        if(item.id) {

            Api.put('/attribute', item).then(resp => {

                if(resp.status === 200) {
                    this.showMessage(true, 'Attribute berhasil diubah');
                }else {
                    this.showMessage(false, resp.message[0]);
                }

                this.setState({
                        loadingButton: false
                    }
                );

            }).catch(err => {

                this.setState({
                        errors: err,
                        loadingButton: false
                    }
                );

            });
        }else {

             Api.post('/attribute', item).then(resp => {

                if(resp.data) {
                    if(resp.status === 200) {
                        let attrs = this.state.attributes;
                        _.forEach(attrs, function(v,k){
                            if(v['name'] === item.name){
                                v['id'] = resp.data['id'];
                            }
                        });
                        this.setState({

                                id: resp.data['product-id'],
                            }
                        );
                        this.showMessage(true, 'Attribute berhasil dibuat');
                    }else {
                        this.showMessage(false, resp.message[0]);
                    }

                    this.setState({
                            loadingButton: false
                        }
                    );
                }

            }).catch(err => {

                this.setState({
                        errors: err,
                        loadingButton: false
                    }
                );

            });
        }

    };

    handleDeleteVariation = (key) => {


        if(key.id) {
            Api.delete('/product/delete-variasi/'+key.id).then(resp => {
                if(resp.status === 200) {
                    this.showMessage(true, 'Variasi berhasil dihapus');
                }else {
                    this.showMessage(false, resp.message[0]);
                }
                this.setState({
                        showDialog: false,
                    }
                );

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
        let data = this.state.variations.filter((item) => item.id !== key.id);

        this.setState({
            variations: data,
            loadingImage: false,
        });
    };

    handleChangPoint = (e, prop, id, idx) => {
        let data = this.state.levels;

        _.forEach(data, function(v,k){
            if(idx === k) {
                v['ref-points'][id][prop] = e.target.value;
            }

        });

        this.setState({
            levels: data,
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Produk</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/products" >Produk</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Ubah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Ubah Produk</h2>
                            </div>
                        </div>

                        <form name="product" id="editProduct" className="row" noValidate>

                            <div className='col-12'>
                                <div className='internal-box'>
                                    <h3 className='section-title'>Foto Produk </h3>
                                    <div className='row justify-content-center'>
                                        {this.state.galleries.length > 0 ?
                                            this.state.galleries.map((item, key) => (
                                                <div className="col-6 col-md-2 form-group gallery-wrap" key={key}>

                                                    {item.isFavourite &&
                                                    <label className='label-star'><i className="fas fa-star"> </i></label>
                                                    }
                                                    <img src={item.image} alt="user" className="img-fluid mr-2" />
                                                    <button className='btn-star-product'
                                                            type='button'
                                                            onClick={() => this.handleFavouriteAvatar(key)}
                                                    >
                                                        <i className="fas fa-star"> </i>
                                                    </button>
                                                    <button className='btn-delete-product'
                                                            type='button' onClick={() => this.handleDeleteAvatar(item, key)}>
                                                        <i className="fas fa-trash-alt"> </i>
                                                    </button>
                                                </div>
                                            ))
                                            :
                                            <div className="col-4 col-md-2 form-group">
                                                <img src={photo} alt="user" className="img-fluid mr-2" />
                                            </div>
                                        }

                                    </div>
                                    <div className='text-center'>
                                        <TextField variant="outlined"
                                                   type="file"
                                                   id='avatar'
                                                   name="avatar"
                                                   className="avatar"
                                                   onChange={this.handleAvatar}
                                        />
                                        <label htmlFor="avatar" className="label-file mr-2">Pilih Gambar {this.state.loadingImage ? <i className="fa fa-spinner fa-spin"> </i> : <i className="fas fa-plus"> </i>}</label>
                                        <small className='d-block'>Format gambar .jpg .jpeg .png dan ukuran minimum 400px x 400px</small>
                                    </div>
                                    <div className='text-danger'>{this.state.errors.galleries}</div>
                                </div>

                            </div>
                            <div className='col-12'>
                                <div className='internal-box'>
                                    <h3 className='section-title'>Informasi Produk</h3>
                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>Nama Produk</label>
                                            {/*<small>Nama min. 5 kata, terdiri dari jenis produk, merek, dan keterangan seperti warna, bahan, atau tipe.</small>*/}
                                        </div>
                                        <div className='col-sm-8'>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='name'
                                                       name="name"
                                                       label="Nama Produk"
                                                       onChange={(e) => this.handleChange(e, 'name')}
                                                       value={this.state.name}
                                                       fullWidth
                                            />
                                            {validator.message('name', this.state.name, 'required')}
                                            <div className='text-danger'>{this.state.errors.name}</div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>SKU Produk</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='sku'
                                                       name="sku"
                                                       label="SKU"
                                                       onChange={(e) => this.handleChange(e, 'sku')}
                                                       value={this.state.sku}
                                                       fullWidth
                                            />
                                            {validator.message('sku', this.state.sku, 'required')}
                                            <div className='text-danger'>{this.state.errors.sku}</div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>Deskripsi Produk</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='description'
                                                       name="description"
                                                       label="Deskripsi"
                                                       onChange={(e) => this.handleChange(e, 'description')}
                                                       value={this.state.description}
                                                       fullWidth
                                                       multiline
                                            />
                                            <div className='text-danger'>{this.state.errors.description}</div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>Kategori Produk</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <TextField variant="outlined"
                                                       select
                                                       id="cat"
                                                       name="cat"
                                                       label="Kategori"
                                                       onChange={(e) => this.handleCat(e, 'cat')}
                                                       value={this.state.cat}
                                                       fullWidth
                                            >
                                                {this.state.categories.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <div className='text-danger'>{this.state.errors.cat}</div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>Tag Produk</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            {this.state.subcategories.length > 0 &&
                                                this.state.subcategories.map(option => (
                                                <FormGroup className='label-sub' key={option['sub-category-id']}>
                                                    <FormControlLabel
                                                        color="primary"
                                                        name='sub'
                                                        control={<Checkbox
                                                            checked={option['is-check']}
                                                            onChange={this.handleCheckedCat(option['sub-category-id'])}
                                                            name='sub'
                                                            value={true}
                                                        />}
                                                        label={option.name}
                                                        className="mb-0"
                                                    />
                                                </FormGroup>
                                            ))}
                                            {validator.message('sub', this.state.sub, 'required')}
                                            {/*<TextField variant="outlined"
                                                       select
                                                       id="sub"
                                                       name="sub"
                                                       label="Sub Kategori"
                                                       onChange={(e) => this.handleChange(e, 'sub')}
                                                       value={this.state.sub}
                                                       fullWidth
                                            >
                                                {this.state.subcategories.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>*/}
                                            <div className='text-danger'>{this.state.errors.sub}</div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4'>
                                            <label className='label-form'>Tipe Produk</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <TextField variant="outlined"
                                                       select
                                                       id="type"
                                                       name="type"
                                                       label="Tipe"
                                                       onChange={(e) => this.handleChange(e, 'type')}
                                                       value={this.state.type}
                                                       fullWidth
                                            >
                                                {this.state.types.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {validator.message('type', this.state.type, 'required')}
                                            <div className='text-danger'>{this.state.errors.type}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='internal-box'>
                                    <h3 className='section-title'>Pengaturan Produk</h3>
                                    <AppBar position="static">
                                        <Tabs value={this.state.value}
                                              variant="scrollable"
                                              scrollButtons="auto"
                                              onChange={this.handleChangeTab} aria-label="produk tabs">
                                            <Tab label="General" {...a11yProps(0)} />
                                            {this.state.code === 'SP' &&
                                            <Tab label="Inventory" {...a11yProps(1)} />
                                            }
                                            {this.state.code === 'SP' &&
                                            <Tab label="Shipping" {...a11yProps(2)} />
                                            }
                                            {this.state.code === 'VP' &&
                                            <Tab label="Attribute" {...a11yProps(3)} />
                                            }
                                            <Tab label="Advance" {...a11yProps(4)} />
                                            {this.state.code === 'VP' &&
                                            <Tab label="Variasi" {...a11yProps(5)} />
                                            }
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={this.state.value} index={0}>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Harga Pokok Penjualan</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='hppPrice'
                                                           name="hppPrice"
                                                           label="Harga"
                                                           onChange={(e) => this.handleChange(e, 'hppPrice')}
                                                           value={this.state.hppPrice}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validator.message('hppPrice', this.state.hppPrice, 'required')}
                                                <div className='text-danger'>{this.state.errors.hppPrice}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Harga Reguler</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='regulerPrice'
                                                           name="regulerPrice"
                                                           label="Harga"
                                                           onChange={(e) => this.handleChange(e, 'regulerPrice')}
                                                           value={this.state.regulerPrice}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                {validator.message('regulerPrice', this.state.regulerPrice, 'required')}
                                                <div className='text-danger'>{this.state.errors.regulerPrice}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Harga Diskon</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='salePrice'
                                                           name="salePrice"
                                                           label="Harga"
                                                           onChange={(e) => this.handleChange(e, 'salePrice')}
                                                           value={this.state.salePrice}
                                                           InputProps={{
                                                               inputComponent: this.NumberFormatCustom,
                                                           }}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.salePrice}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Periode Diskon</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <div className="form-group">
                                                    <label>Tanggal Mulai</label>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker
                                                            label="Tanggal Mulai"
                                                            value={this.state.startSale}
                                                            onChange={(date) => this.handleDate(date, 'startSale')}
                                                            format={'DD-MM-YYYY'}
                                                            cancelLabel="BATAL"
                                                            inputVariant="outlined"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className='text-danger'>{this.state.errors.startSale}</div>

                                                    <div className="form-group mt-3">
                                                        <label>Tanggal Berakhir</label>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DatePicker
                                                                label="Tanggal Berakhir"
                                                                value={this.state.endSale}
                                                                onChange={(date) => this.handleDate(date, 'endSale')}
                                                                format={'DD-MM-YYYY'}
                                                                cancelLabel="BATAL"
                                                                inputVariant="outlined"
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        <div className='text-danger'>{this.state.errors.endSale}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Poin Produk</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='fixedPoint'
                                                           name="fixedPoint"
                                                           label="Poin"
                                                           onChange={(e) => this.handleChange(e, 'fixedPoint')}
                                                           value={this.state.fixedPoint}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.fixedPoint}</div>
                                            </div>
                                        </div>
                                       {/* <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Produk URL</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='urlProduct'
                                                           name="urlProduct"
                                                           label="URL"
                                                           onChange={(e) => this.handleChange(e, 'urlProduct')}
                                                           value={this.state.urlProduct}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.urlProduct}</div>
                                            </div>
                                        </div>*/}
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={this.state.code === 'SP' ? 1 : 6}>
                                       {/* <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Stock Status</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           select
                                                           id="stockStatus"
                                                           name="stockStatus"
                                                           label="Stock Status"
                                                           onChange={(e) => this.handleChange(e, 'stockStatus')}
                                                           value={this.state.stockStatus}
                                                           fullWidth
                                                >
                                                    <MenuItem value='in stock'>
                                                        In stock
                                                    </MenuItem>
                                                    <MenuItem value='out of stock'>
                                                        Out of stock
                                                    </MenuItem>
                                                </TextField>
                                                <div className='text-danger'>{this.state.errors.stockStatus}</div>
                                            </div>
                                        </div>*/}
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Stock Quantity</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='stockQuantity'
                                                           name="stockQuantity"
                                                           label="Stock"
                                                           onChange={(e) => this.handleChange(e, 'stockQuantity')}
                                                           value={this.state.stockQuantity}
                                                           fullWidth
                                                />
                                                {validatorSimple.message('stockQuantity', this.state.stockQuantity, 'required')}
                                                <div className='text-danger'>{this.state.errors.stockQuantity}</div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={this.state.code === 'SP' ? 2 : 6}>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Berat</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='weight'
                                                           name="weight"
                                                           label="Berat"
                                                           onChange={(e) => this.handleChange(e, 'weight')}
                                                           value={this.state.weight}
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <strong>gram</strong>
                                                               )
                                                           }}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.weight}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Dimensi</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='length'
                                                           name="length"
                                                           label="Panjang"
                                                           className='mb-3'
                                                           onChange={(e) => this.handleChange(e, 'length')}
                                                           value={this.state.length}
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <strong>cm</strong>
                                                               )
                                                           }}
                                                           fullWidth
                                                />
                                                {validatorSimple.message('weight', this.state.weight, 'required')}
                                                <div className='text-danger'>{this.state.errors.length}</div>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='width'
                                                           name="width"
                                                           label="Lebar"
                                                           className='mb-3'
                                                           onChange={(e) => this.handleChange(e, 'width')}
                                                           value={this.state.width}
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <strong>cm</strong>
                                                               )
                                                           }}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.width}</div>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='height'
                                                           name="height"
                                                           label="Tinggi"
                                                           className='mb-3'
                                                           onChange={(e) => this.handleChange(e, 'height')}
                                                           value={this.state.height}
                                                           InputProps={{
                                                               endAdornment: (
                                                                   <strong>cm</strong>
                                                               )
                                                           }}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.height}</div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={this.state.code === 'SP' ? 6 : 1}>
                                        {this.state.attributes.length > 0 &&
                                        this.state.attributes.map(function (item, idx) {
                                            return <div className="form-group row border-bottom position-relative" key={idx}>
                                                <button className='btn-delete-attr'
                                                        type='button' onClick={() => this.handleDeleteAttr(item, idx)}>
                                                    <i className="fas fa-trash-alt"> </i>
                                                </button>
                                                <button className='btn-save-attr'
                                                        type='button' onClick={() => this.handleSaveAttr(item)}>
                                                    <i className="fas fa-check"> </i>
                                                </button>
                                                <div className='col-sm-4 mb-sm-3'>
                                                    <label className='label-form'>Attribute Name</label>
                                                </div>
                                                <div className='col-sm-8 col-lg-6 mb-sm-3'>
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id='name'
                                                               name="name"
                                                               label="Name"
                                                               onChange={(e) => this.handleChangeAttribute(e, 'name', idx)}
                                                               value={item.name}
                                                               fullWidth
                                                    />
                                                    {validatorAttr.message('name-'+idx, item.name, 'required')}
                                                    <div className='text-danger'>{this.state.errors['name-'+idx]}</div>
                                                </div>
                                                <div className='col-sm-4 mb-sm-3'>
                                                    <label className='label-form'>Value</label>
                                                </div>
                                                <div className='col-sm-8 col-lg-6 mb-sm-3'>
                                                    <TextField variant="outlined"
                                                               type='text'
                                                               id='value'
                                                               name="value"
                                                               label="Value"
                                                               onChange={(e) => this.handleChangeAttribute(e, 'value', idx)}
                                                               value={item.value}
                                                               fullWidth
                                                    />
                                                    {validatorAttr.message('value-'+idx, item.value, 'required')}
                                                    <div className='text-danger'>{this.state.errors['value-'+idx]}</div>
                                                </div>

                                                <div className='col-sm-4 '>
                                                    <label className='label-form'>Is Visible</label>
                                                </div>
                                                <div className='col-sm-8 col-lg-6 '>
                                                    <FormControlLabel control={
                                                        <Checkbox name="is-visible"
                                                                  checked={item['is-visible']}
                                                                  onChange={(e) => this.handleChangeAttribute(e, 'is-visible', idx)}
                                                        />} label="Visible" />
                                                    <div className='text-danger'>{this.state.errors['is-visible-'+idx]}</div>
                                                </div>

                                                <div className='col-sm-4 '>
                                                    <label className='label-form'>Is Active</label>
                                                </div>
                                                <div className='col-sm-8 col-lg-6 '>
                                                    <FormControlLabel control={
                                                        <Checkbox name="is-active"
                                                                  checked={item['is-active']}
                                                                  onChange={(e) => this.handleChangeAttribute(e, 'is-active', idx)}
                                                        />} label="Active" />
                                                    <div className='text-danger'>{this.state.errors['is-active-'+idx]}</div>
                                                </div>
                                            </div>
                                        }.bind(this))
                                        }
                                        <div className="row">
                                            <div className='col-12 text-right'>
                                                {this.state.isManage &&
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleAddAttribute}
                                                >
                                                    <i className="fas fa-plus mr-2"> </i>
                                                    Tambah Attribute
                                                </Button>
                                                }

                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={this.state.code === 'SP' ? 3 : 2}>
                                        <div className="form-group row">
                                            <div className='col-sm-4'>
                                                <label className='label-form'>Purchase Note</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='note'
                                                           name="note"
                                                           label="Note"
                                                           onChange={(e) => this.handleChange(e, 'note')}
                                                           value={this.state.note}
                                                           fullWidth
                                                />
                                                <div className='text-danger'>{this.state.errors.note}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-4 '>
                                                <label className='label-form'>Enable Reviews</label>
                                            </div>
                                            <div className='col-sm-8 col-lg-6 '>
                                                <FormControlLabel control={
                                                    <Checkbox name="review"
                                                              checked={this.state.review}
                                                              onChange={this.handleChecked('review')}
                                                    />} label="Enable" />
                                                <div className='text-danger'>{this.state.errors.review}</div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={this.state.code === 'SP' ? 6 :3}>
                                        {this.state.variations.length > 0 &&
                                        this.state.variations.map(function (item, idx) {
                                            return <Accordion key={idx}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <h3>Variasi {idx+1}</h3>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='col-12'>
                                                        {item.attributes.map(function (detail, ids) {
                                                            return <div className="form-group row" key={ids}>
                                                                <div className='col-sm-4'>
                                                                    <label className='label-form'>{detail.name}</label>
                                                                </div>
                                                                <div className='col-sm-8 col-lg-6'>
                                                                    <TextField variant="outlined"
                                                                               select
                                                                               id="id-value"
                                                                               name="id-value"
                                                                               label={detail.name}
                                                                               onChange={(e) => this.handleChangeAttrVariations(e, detail.name, idx, detail['id-attribute'])}
                                                                               value={detail['id-value']}
                                                                               fullWidth
                                                                    >
                                                                        {detail.value &&
                                                                        detail.value.split("|").map(function (item, id) {
                                                                            return <MenuItem value={item} key={id}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        }.bind(this))
                                                                        }

                                                                    </TextField>
                                                                    {validatorVar.message(detail.name+'-'+idx, detail['id-value'], 'required')}
                                                                    <div className='text-danger'>{this.state.errors[detail.name+'-'+idx]}</div>
                                                                </div>
                                                            </div>
                                                        }.bind(this))
                                                        }
                                                        <hr />
                                                       {/* <div className="form-group">
                                                            <img src={item.photo ? item.photo : photo} alt="user" className="img-product big mr-2" />
                                                            <TextField variant="outlined"
                                                                       type="file"
                                                                       id={'image-'+idx}
                                                                       name="image"
                                                                       className="avatar"
                                                                       onChange={(e) => this.handleChangeVariations(e, 'image', idx)}

                                                            />
                                                            <label htmlFor={'image-'+idx} className="label-file mr-2">Ubah Foto {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                                            <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                                                Hapus Foto
                                                            </button>
                                                            <div className='text-danger'>{this.state.errors['image-'+idx]}</div>

                                                        </div>*/}
                                                        <div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>SKU Produk</label>
                                                            </div>
                                                            <div className='col-sm-8'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='sku'
                                                                           name="sku"
                                                                           label="SKU"
                                                                           onChange={(e) => this.handleChangeVariations(e, 'sku', idx)}
                                                                           value={item.sku}
                                                                           fullWidth
                                                                />
                                                                {validatorVar.message('sku-'+idx, item.sku, 'required')}
                                                                <div className='text-danger'>{this.state.errors['sku-'+idx]}</div>
                                                            </div>
                                                        </div>

                                                        {/*<div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>Harga Reguler</label>
                                                            </div>
                                                            <div className='col-sm-8 col-lg-6'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='regular-price'
                                                                           name="regular-price"
                                                                           label="Harga"
                                                                           onChange={(e) => this.handleChangeVariations(e, 'regular-price', idx)}
                                                                           value={item['regular-price']}
                                                                           InputProps={{
                                                                               inputComponent: this.NumberFormatCustom,
                                                                           }}
                                                                           fullWidth
                                                                />
                                                                {validatorVar.message('regular-price-'+idx, item['regular-price'], 'required')}
                                                                <div className='text-danger'>{this.state.errors['regular-price-'+idx]}</div>
                                                            </div>
                                                        </div>*/}
                                                        <div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>Stok</label>
                                                            </div>
                                                            <div className='col-sm-8 col-lg-6'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='stock-quantity'
                                                                           name="stock-quantity"
                                                                           label="Stok"
                                                                           onChange={(e) => this.handleChangeVariations(e, 'stock-quantity', idx)}
                                                                           value={item['stock-quantity']}
                                                                           fullWidth
                                                                />
                                                                {validatorVar.message('stock-quantity-'+idx, item['stock-quantity'], 'required')}
                                                                <div className='text-danger'>{this.state.errors['stock-quantity-'+idx]}</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>Berat</label>
                                                            </div>
                                                            <div className='col-sm-8 col-lg-6'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='weight'
                                                                           name="weight"
                                                                           label="Berat"
                                                                           onChange={(e) => this.handleChangeVariations(e, 'weight', idx)}
                                                                           value={item['weight']}
                                                                           InputProps={{
                                                                               endAdornment: (
                                                                                   <strong>gram</strong>
                                                                               )
                                                                           }}
                                                                           fullWidth
                                                                />
                                                                {validatorVar.message('weight-'+idx, item['weight'], 'required')}
                                                                <div className='text-danger'>{this.state.errors['weight-'+idx]}</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>Dimensi</label>
                                                            </div>
                                                            <div className='col-sm-8 col-lg-6'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='length'
                                                                           name="length"
                                                                           label="Panjang"
                                                                           className='mb-3'
                                                                           onChange={(e) => this.handleChangeVariations(e, 'length', idx)}
                                                                           value={item['length']}
                                                                           InputProps={{
                                                                               endAdornment: (
                                                                                   <strong>cm</strong>
                                                                               )
                                                                           }}
                                                                           fullWidth
                                                                />
                                                                <div className='text-danger'>{this.state.errors['length-'+idx]}</div>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='width'
                                                                           name="width"
                                                                           label="Lebar"
                                                                           className='mb-3'
                                                                           onChange={(e) => this.handleChangeVariations(e, 'width', idx)}
                                                                           value={item['width']}
                                                                           InputProps={{
                                                                               endAdornment: (
                                                                                   <strong>cm</strong>
                                                                               )
                                                                           }}
                                                                           fullWidth
                                                                />
                                                                <div className='text-danger'>{this.state.errors['width-'+idx]}</div>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='height'
                                                                           name="height"
                                                                           label="Tinggi"
                                                                           className='mb-3'
                                                                           onChange={(e) => this.handleChangeVariations(e, 'height', idx)}
                                                                           value={item['height']}
                                                                           InputProps={{
                                                                               endAdornment: (
                                                                                   <strong>cm</strong>
                                                                               )
                                                                           }}
                                                                           fullWidth
                                                                />
                                                                <div className='text-danger'>{this.state.errors['height-'+idx]}</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className='col-sm-4'>
                                                                <label className='label-form'>Deskripsi Produk</label>
                                                            </div>
                                                            <div className='col-sm-8'>
                                                                <TextField variant="outlined"
                                                                           type='text'
                                                                           id='description'
                                                                           name="description"
                                                                           label="Deskripsi"
                                                                           onChange={(e) => this.handleChangeVariations(e, 'description', idx)}
                                                                           value={item['description']}
                                                                           fullWidth
                                                                           multiline
                                                                />
                                                                <div className='text-danger'>{this.state.errors['description-'+idx]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className='btn-delete-attr'
                                                            type='button' onClick={() => this.handleDeleteVariation(item)}>
                                                        <i className="fas fa-trash-alt"> </i>
                                                    </button>
                                                </AccordionDetails>
                                            </Accordion>
                                        }.bind(this))
                                        }
                                        <div className="row justify-content-end">
                                            <div className='col-md-6 text-right mt-3'>
                                                {this.state.isManage &&
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={this.handleAddVariation}
                                                >
                                                    <i className="fas fa-plus mr-2"> </i>
                                                    Tambah Variasi
                                                </Button>
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>


                            <div className='col-12'>
                                <div className='internal-box'>
                                    <h3 className='section-title'>Referal Poin GE Best Friend</h3>

                                    <div className='alert alert-warning'>
                                        <i className="fas fa-exclamation-circle mr-2"> </i>
                                        <span>
                                            Penggunaan decimal menggunakan <strong>titik .</strong> bukan <strong>koma ,</strong> (contoh: satu koma lima = 1.5)
                                        </span>
                                    </div>
                                    <div className='row'>
                                        {
                                            this.state.levels.length > 0 &&
                                            this.state.levels.map(function (item, idx) {
                                                return <div className='col-md-6 form-group' key={idx}>
                                                    <label>{item['customer-type'] ? item['customer-type'].name : ''} </label>
                                                    {item['ref-points'].map(function (list, id) {
                                                        return <TextField
                                                            key={id}
                                                            variant="outlined"
                                                            type='text'
                                                            id='value'
                                                            name="value"
                                                            label={'Level '+list.level}
                                                            className='mb-2'
                                                            onChange={(e) => this.handleChangPoint(e, 'value', id, idx)}
                                                            value={list.value}
                                                            fullWidth
                                                        />
                                                    }.bind(this))
                                                    }
                                                </div>
                                            }.bind(this))
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center mt-3">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/products')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                                }

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default ProductsEdit;
