import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import NumberFormat from 'react-number-format';
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class UsersReset extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            email: '',
            message: '',

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Reset Password';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'email': this.state.email,
        };


        Api.post('/user/reset-password-manual', params).then(resp => {

            this.setState({
                loadingButton: false,
                }
            );
            if(resp.data) {
                this.setState({
                        message: resp.data,
                    }
                );
            }

            this.showMessage(true, 'Berhasil password reset');

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });

    };


    handleGo  = (link) => {

        history.push(link);
    };
    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Detail Customer</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/customers" >Customers</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Reset Password Manual</h2>
                            </div>
                        </div>

                        <form name="customer" id="resetCustomer" className="row" noValidate>

                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='email'
                                               name="email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email|required')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <p><strong>{this.state.message}</strong></p>
                            </div>
                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/customers')}
                                >
                                    Batal
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>


                            </div>
                        </form>
                    </div>
                </div>

                <ToastContainer />

            </div>
        )
    }
}

export default UsersReset;