import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";
import _ from "lodash";

require('dotenv').config();

class Menus extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            showDialogFooter: false,
            errors: {},

            idDelete: '',
            idDeleteFooter: '',
            currDelete: '',
            currDeleteFooter: '',

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,

            headers: [],
            footers: [],
            homes: [],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Menu';
        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Menus');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        this.__fetchHeader();
        this.__fetchFooter();
        this.__fetchHome();

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleOpenFooter = (row) => {

        this.setState({
            showDialogFooter :true,
            idDeleteFooter: row.id,
            currDeleteFooter: row.name,
        })
    };

    handleCloseFooter = () => {
        this.setState({
            showDialogFooter :false,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            let params = {
                id: this.state.idDelete,
                status: false
            };

            Api.put('/menu/main/status', params).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Menu berhasil deactive');
                this.__fetchHeader();

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    handleDeleteFooter = () => {

        if(this.state.idDeleteFooter) {

            Api.delete('/menu/footer/'+this.state.idDeleteFooter).then(resp => {

                this.setState({
                    showDialogFooter: false,
                    }
                );

                this.showMessage(true, 'Menu berhasil dihapus');
                this.__fetchFooter();

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    __fetchHeader = () => {

        let params = {
            page: 0,
            size: 50,
            sort: [
                {
                    "column-name": "id",
                    "is-ascending": true
                }
            ],
        };

        Api.post('/menu/main/get-all', params).then(resp => {

            if(resp.data) {
                let menus = [];
                let child = [];
                _.forEach(resp.data.mainMenus, function(v,k){
                    if(v.parentId === 0) {
                        menus.push(v);
                    }else {
                        child.push(v);
                    }
                });
                _.forEach(menus, function(x,k){
                    let arr = [];
                    _.forEach(child, function(a,b){
                        if(x.id == a.parentId) {
                            arr.push(a);
                        }
                    });
                    x['childrens'] = arr;

                });
                this.setState({
                    headers: menus,
                    loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchFooter = () => {

        let params = {
            page: 0,
            size: 50,
            sort: [
                {
                    "column-name": "id",
                    "is-ascending": true
                }
            ],
        };

        Api.post('/menu/footer/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        footers: resp.data.footers,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchHome = () => {

        let params = {
            page: 0,
            size: 50,
            sort: [
                {
                    "column-name": "id",
                    "is-ascending": true
                }
            ],
        };

        Api.post('/menu/dashboard/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                    homes: resp.data.dashboardMenus,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleGo = (link) => {

        history.push(link);
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    filterMenu = (id) => {
        let all = this.state.headers.filter((item) => item.id === id);

        if(all.length)
            return all[0].name;
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Menu</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Menu</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Menu</h2>
                            </div>
                        </div>
                        <form className='mt-3'>
                            <AppBar position="static">
                                <Tabs value={this.state.value}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      onChange={this.handleChangeTab} aria-label="produk tabs">
                                    <Tab label="Header" {...a11yProps(0)} />
                                    <Tab label="Footer" {...a11yProps(1)} />
                                    <Tab label="Home" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-right">
                                        {this.state.isManage &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => this.handleGo('/menus/headers/add')}
                                        >
                                            <i className="fas fa-plus mr-2"> </i>
                                            Buat Menu
                                        </Button>
                                        }
                                    </div>
                                </div>
                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Menu</TableCell>
                                                <TableCell>Aktif</TableCell>
                                                <TableCell>Action</TableCell>
                                                <TableCell>Sub Menu</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.headers.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={6} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.headers.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                            <TableCell>{row['is-active']? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/menus/headers/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    {this.state.isManage &&
                                                                    <button className="btn-icon" type='button' onClick={() => this.handleOpen(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Deactive
                                                                    </button>
                                                                    }

                                                                </div>
                                                            </TableCell>
                                                            <TableCell colSpan={3}>
                                                                {row.childrens.length > 0 &&
                                                                row.childrens.map(item => (
                                                                    <div key={item.id} className='row'>
                                                                        <div className='col-6'>
                                                                            {item.name === null ? '' : item.name}
                                                                        </div>
                                                                        <div className='col-2'>
                                                                         {item['is-active'] ? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}
                                                                        </div>
                                                                        <div className='col-4 d-inline-flex'>
                                                                            <button className="btn-icon"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        history.push({
                                                                                            pathname: "/menus/headers/" + item.id,
                                                                                            state: row,
                                                                                        });
                                                                                    }}>
                                                                                <i className="fas fa-pencil-alt"> </i>
                                                                            </button>
                                                                            {this.state.isManage &&
                                                                            <button className="btn-icon" type='button' onClick={() => this.handleOpen(item)}>
                                                                                <i className="fas fa-trash-alt"> </i>
                                                                            </button>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    )
                                                                )}
                                                            </TableCell>

                                                        </TableRow>

                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>

                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>

                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-right">
                                        {this.state.isManage &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => this.handleGo('/menus/footers/add')}
                                        >
                                            <i className="fas fa-plus mr-2"> </i>
                                            Buat Menu Footer
                                        </Button>
                                        }
                                    </div>
                                </div>
                                <div className="table-list row mt-3" style={{width:600}}>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Menu</TableCell>
                                                <TableCell>Grup</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.footers.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.footers.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                            <TableCell>{row.group === null ? '' : row.group}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/menus/footers/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    {this.state.isManage &&
                                                                    <button className="btn-icon" type='button' onClick={() => this.handleOpenFooter(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Hapus
                                                                    </button>}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <div className="table-list row mt-3" style={{width:600}}>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Menu</TableCell>
                                                <TableCell>Aktif</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.homes.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.homes.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                            <TableCell>{row['is-active']? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/menus/homes/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>
                            </TabPanel>
                        </form>

                    </div>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialog}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">PERHATIKAN! {this.state.currDelete}</DialogTitle>
                        <DialogContent>
                            <p>Apakah anda yakin akan Non Aktif kan menu?</p>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleClose}>
                                Batal
                            </button>
                            <button className="btn btn-blue" onClick={this.handleDelete}>
                                Deactive
                            </button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialogFooter}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">PERHATIKAN! {this.state.currDeleteFooter}</DialogTitle>
                        <DialogContent>
                            <p>Apakah anda yakin akan menghapus kan menu?</p>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleCloseFooter}>
                                Batal
                            </button>
                            <button className="btn btn-blue" onClick={this.handleDeleteFooter}>
                                Hapus
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>


                <ToastContainer />

            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}
export default Menus;
