import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import NumberFormat from 'react-number-format';
import Default from "../../../images/default.jpeg";
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {history} from "../../../shared/configure-store";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import AuthHelper from "../../../libraries/auth-helper";
import {DatePicker, TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment/moment";

require('dotenv').config();
const validator = new SimpleReactValidator();

class OrdersEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            showDialog :false,
            loadingImage: false,
            pickup: null,
            pickuptime: null,
            errors: {},

            statuses: [],

            note: '',
            id: '',
            code: '',
            status: '',
            currentStatus: '',
            name: '',
            email: '',
            phone: '',
            username: '',
            lookup: {},
            bank: {},

            orders: [],
            histories: [],
            shipping: {},
            shippingType: {},

            noResi: '',
            payment: '',
            date: '',
            resi: '',
            shippingCost: 0,

            total: 0,
            grandTotal: 0,
            unique: 0,
            coupon: null,

            ipAddress: '',
            billing: {},

            awb: true,
            noAWB: null,

            requestDate: null,
            pin: null,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Customer Detail';

        const id = this.props.match.params.id;

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Riwayat Order');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        Api.get('/lookup/by-group-id/5').then(resp => {

            let data = resp.data;

            if (data) {

                this.setState({
                    statuses: resp.data,
                    loading: false
                    });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/order/get-order-by-code/'+id).then(resp => {

            let data = resp.data.order;

            if(data.awb === null) {
                this.setState({
                    awb: false,
                });

                Api.get('/order/send-shipment-data/'+id).then(res => {

                }).catch(err => {
                    console.log(err);
                });
            }else {
                console.log(456);
            }

            if (data) {
                this.setState({
                    noAWB: data.awb,
                    code: data.orderCode,
                    orders: data['order-details'],
                    status: data.status,
                    currentStatus: data.status,
                    name: resp.data.name,
                    username: resp.data.username,
                    email: resp.data.email,
                    phone: resp.data['phone-number'],
                    lookup: {},
                    bank: data.bank,
                    coupon: data.coupon,
                    note: data['shipping-address'] ? data['shipping-address'].note : '',

                    resi: data['resi-no'],
                    shipping: data['shipping-address'],
                    billing: data['billing-address'],
                    shippingType: data['shipping-type'],

                    payment: data['payment-method'],
                    date: data['order-date'],
                    shippingCost: data['shipping-cost'],
                    histories: data['order-history'],

                    total: data['total-amount'],
                    unique: data['unique-code'],
                    grandTotal: data['grand-total'],
                    discountCoupon: data['discount-coupon'],
                    discountRedeem: data['discount-redeem'],
                    ipAddress: data['ip-address'],
                    requestDate: data['pickup-request-number'],
                    pin: data['pin'],
                    loading: false,
                });
            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        console.log(date)
        console.log(moment(date).format('DD-MM-YYYY'))
        console.log(moment(date).format('HH:mm:ss'))
        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if(this.state.status === 'COMPLETE' || this.state.status === 'CANCELLED') {
            this.setState({
                showDialog :true,
            })
            return false;
        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        let params = {
            'status': this.state.status,
            'noResi': this.state.resi,
            'order-code': this.state.code,
        };

        Api.put('/order/update-status', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Order berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }

            this.setState({
                    loadingButton: false,
                    showDialog :false,
                }
            );

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleComplete = (e) => {

        e.preventDefault();

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        let params = {
            'status': this.state.status,
            'order-code': this.state.code,
        };

        Api.put('/order/update-status', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Order berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);

            }

            this.setState({
                    loadingButton: false,
                    showDialog :false,
                }
            );

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleChangeSelect (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleSend = (e) => {
        e.preventDefault();

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );
        let date = this.state.pickup ? moment(this.state.pickup).format('DD-MM-YYY') : null;
        let time = this.state.pickuptime ? moment(this.state.pickuptime).format('HH:mm:ss') : null;

        let params = {
            'pickup-time': date+' '+time,
            'order-code': this.state.code,
        };

        Api.post('/order/rpx/request-pickup', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'request berhasil dibuat');
            }else {
                this.showMessage(false, resp.message[0]);

            }

            this.setState({
                    loadingButton: false,
                    showDialog :false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });

    }

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Detail Order</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/orders" >Order</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row py-3 align-items-center content-title">
                            <div className="col-md-8">
                                <h2 className='mb-0'>No Order: {this.state.code}</h2>
                                <small>Pembayaran via {this.state.payment} {this.state.bank ? '- '+this.state.bank.bankName : ''}</small>
                                <p className='mb-0'>IP Address: {this.state.ipAddress}</p>
                            </div>
                            <div className="col-md-4 text-right">
                                <p className='mb-0'><strong>{this.state.currentStatus}</strong></p>
                            </div>
                        </div>
                        {this.state.shippingType !== null &&
                        (this.state.shippingType.code === 'rpx' && !this.state.awb) &&
                        <div className='alert alert-warning mt-3'>
                            Silahkan reload halaman kembali untuk mendapatkan Airwaybill
                        </div>
                        }


                        <form className='row flex-md-row-reverse pt-3'>
                            <div className='col-md-4 text-right'>
                                <p className='mb-1'>Total Pembelian</p>
                                <h5 className='big-num mb-3 border-bottom pb-3'>
                                    Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.grandTotal} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </h5>

                                <div className="text-left form-group">
                                    <label>Status</label>
                                    <TextField variant="outlined"
                                               select
                                               id="status"
                                               name="status"
                                               label="Status"
                                               onChange={(e) => this.handleChangeSelect(e, 'status')}
                                               value={this.state.status}
                                               fullWidth
                                    >
                                        {this.state.statuses.map(option => (
                                            <MenuItem key={option.id} value={option.code}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                {(this.state.status === 'SHIPPED' || this.state.status === 'COMPLETE') &&
                                <div className="text-left form-group">
                                    <label>No Resi</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='resi'
                                               name="resi"
                                               label="Nomor"
                                               onChange={(e) => this.handleChange(e, 'resi')}
                                               value={this.state.resi}
                                               fullWidth
                                    />
                                </div>
                                }

                                <div className="text-right">
                                    {(this.state.currentStatus !== 'COMPLETE' && this.state.isManage && this.state.currentStatus !==  'CANCELLED') &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className='mt-3'
                                        onClick={this.handleSubmit}
                                        disabled={this.state.loadingButton }
                                    >
                                        Simpan { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                    </Button>
                                    }

                                </div>

                                {this.state.shippingType !== null &&
                                    this.state.shippingType.code === 'rpx' &&
                                    <>
                                        {this.state.requestDate === null ?
                                            <>
                                            <div className="text-left form-group mt-4 border-top pt-3">
                                                <p>Nomor Airwaybill: <strong>{this.state.noAWB}</strong> </p>

                                                <h3>Request Pickup</h3>
                                                <label>Tanggal Waktu</label>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        label="Tanggal"
                                                        value={this.state.pickup}
                                                        className='mb-2'
                                                        onChange={(date) => this.handleDate(date, 'pickup')}
                                                        format={'DD-MM-YYYY'}
                                                        cancelLabel="BATAL"
                                                        inputVariant="outlined"
                                                    />
                                                    <TimePicker
                                                        label="Waktu"
                                                        value={this.state.pickuptime}
                                                        onChange={(date) => this.handleDate(date, 'pickuptime')}
                                                        format={'HH:ss'}
                                                        cancelLabel="BATAL"
                                                        inputVariant="outlined"
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className='text-danger'>{this.state.errors.pickuptime}</div>
                                            </div>
                                            <div className="text-right">
                                                {(this.state.currentStatus !== 'COMPLETE' && this.state.isManage && this.state.currentStatus !==  'CANCELLED') &&
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='mt-3'
                                                    onClick={this.handleSend}
                                                    disabled={this.state.loadingButton }
                                                >
                                                    Kirim { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                                </Button>
                                                }
                                            </div>
                                            </>
                                            :
                                            <div className="text-left form-group mt-4 border-top pt-3">
                                                <h3>RPX Request Pickup</h3>
                                                <label>Pickup Request Number</label>
                                                <p>{this.state.requestDate}</p>
                                                <label>PIN</label>
                                                <p>{this.state.pin}</p>
                                            </div>
                                        }

                                </>
                                }

                            </div>
                            <div className='col-md-8 form-group'>
                                <h3 className='section-title border-blue'>General</h3>

                                <div className='row'>
                                    <div className='col-md-6 '>
                                        <label className='label-form mb-0'>Tanggal Order :</label>
                                        <p>{this.state.date}</p>
                                        <label className='label-form mb-0'>Catatan Dropship : </label>
                                        <p>{this.state.note}</p>

                                    </div>
                                    <div className='col-md-6 '>
                                        <label className='label-form mb-0'>Username :</label>
                                        <p>{this.state.username}</p>
                                        <label className='label-form mb-0'>Email : </label>
                                        <p>{this.state.email}</p>
                                    </div>
                                    <div className='col-md-6 '>

                                    <h3 className='section-title border-blue'>Alamat Penagihan</h3>
                                        <label className='label-form mb-0'>Nama Lengkap : </label>
                                        <p>{this.state.name ? this.state.name : ''}</p>
                                        <label className='label-form mb-0'>Phone : </label>
                                        <p>{this.state.phone ? this.state.phone : ''}</p>
                                        <label className='label-form mb-0'>Alamat : </label>
                                        <p>{this.state.billing ? this.state.billing.address : ''}</p>
                                        <label className='label-form mb-0'>Gedung/Apartemen : </label>
                                        <p>{this.state.billing ? this.state.billing.company : ''}</p>
                                        <label className='label-form mb-0'>Provinsi : </label>
                                        <p>{this.state.billing ? this.state.billing.province ? this.state.billing.province.province : '' : ''}</p>
                                        <label className='label-form mb-0'>Kota : </label>
                                        <p>{this.state.billing ? this.state.billing.city ? this.state.billing.city.name : '' : ''}</p>
                                        <label className='label-form mb-0'>Kecamatan : </label>
                                        <p>{this.state.billing ? this.state.billing.subDistrict ? this.state.billing.subDistrict.name : '' : ''}</p>
                                        <label className='label-form mb-0'>Kode Pos : </label>
                                        <p>{this.state.billing ? this.state.billing['postalCode'] : ''}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <h3 className='section-title border-blue'>Alamat Dropship</h3>
                                        <label className='label-form mb-0'>Nama Lengkap : </label>
                                        <p>{this.state.shipping ? this.state.shipping['full-name'] : ''}</p>
                                        <label className='label-form mb-0'>Phone : </label>
                                        <p>{this.state.shipping ? this.state.shipping['phone-number'] : ''}</p>
                                        <label className='label-form mb-0'>Alamat : </label>
                                        <p>{this.state.shipping ? this.state.shipping.address : ''}</p>
                                        <label className='label-form mb-0'>Gedung/Apartemen : </label>
                                        <p>{this.state.shipping ? this.state.shipping.company : ''}</p>
                                        <label className='label-form mb-0'>Provinsi : </label>
                                        <p>{this.state.shipping ? this.state.shipping.provinceName ? this.state.shipping.provinceName : '' : ''}
                                            {this.state.shipping ? this.state.shipping.province ? this.state.shipping.province.province : '' : ''}</p>
                                        <label className='label-form mb-0'>Kota : </label>
                                        <p>{this.state.shipping ? this.state.shipping.cityName ? this.state.shipping.cityName : '' : ''}
                                            {this.state.shipping ? this.state.shipping.city ? this.state.shipping.city.name : '' : ''}</p>
                                        <label className='label-form mb-0'>Kecamatan : </label>
                                        <p>{this.state.shipping ? this.state.shipping.subdistrictName ? this.state.shipping.subdistrictName : '' : ''}
                                            {this.state.shipping ? this.state.shipping.subdistrict ? this.state.shipping.subdistrict.name : '' : ''}</p>
                                        <label className='label-form mb-0'>Kode Pos : </label>
                                        <p>{this.state.shipping ? this.state.shipping['postal-code'] : ''}</p>

                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Produk</h2>
                            </div>
                        </div>
                        <div className="table-list">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Harga Satuan</TableCell>
                                        <TableCell>Kuantitas</TableCell>
                                        <TableCell>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.orders.length === 0 ? (
                                            <TableRow style={{ height: 10 * 5}}>
                                                <TableCell colSpan={4} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.orders.map(row => (
                                                <TableRow key={row.id}>
                                                    <TableCell>
                                                        <div className='d-flex align-items-center'>
                                                            {row.image &&
                                                            <img src={row.image ? row.image : Default} className='img-product mr-2' />
                                                            }
                                                            <p className='mb-0'>
                                                            {row.name}<br />
                                                            {row.sku}
                                                            </p>
                                                        </div></TableCell>
                                                    <TableCell>
                                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </TableCell>
                                                    <TableCell>{row.quantity}</TableCell>
                                                    <TableCell><strong>
                                                        Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={row.quantity*parseFloat(row.price)} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </strong></TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                    <TableRow style={{background:'#ffe3ba'}}>
                                        <TableCell><strong>Total Harga Produk</strong></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                           <strong>
                                               Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                           </strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><strong>Kode Unik Pembayaran</strong></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <strong>
                                                Rp -<NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.unique} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div className='d-flex align-items-center'>
                                                <p className='mb-0'>
                                                    <strong>Shipping</strong> - {this.state.shippingType ? this.state.shippingType.name : ''}</p>
                                            </div>

                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <strong>
                                                Rp <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.shippingCost} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    {this.state.discountCoupon > 0 &&
                                    <TableRow>
                                        <TableCell><strong>Kupon {this.state.coupon ? this.state.coupon.code : ''}</strong></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <strong>
                                                Rp - <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.discountCoupon} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    }
                                    {this.state.discountRedeem > 0 &&
                                    <TableRow>
                                        <TableCell><strong>Redeem Point</strong></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <strong>
                                                Rp -<NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={this.state.discountRedeem} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    }


                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Riwayat</h2>
                            </div>
                        </div>
                        {this.state.histories.length > 0 &&
                        this.state.histories.map(row => (
                            <div className='history-list' key={row.id}>
                                <p>{row.description}</p>
                                <small>{row['history-date']}</small>
                            </div>
                        ))
                        }
                    </div>
                </div>

                <ToastContainer />
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">UBAH STATUS {this.state.status}? </DialogTitle>
                    <DialogContent>
                        <p>Apakah anda yakin akan melakukan perubahan menjadi <strong>{this.state.status}</strong>? <br />
                            Anda tidak dapat membatalkan kembali setelah order <strong>{this.state.status}</strong></p>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleClose}>
                            TIDAK
                        </button>
                        <button className="btn btn-blue"
                                disabled={this.state.loadingButton }
                                onClick={this.handleComplete}>
                            YA { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default OrdersEdit;
