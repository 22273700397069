import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Api from "../../../libraries/api";
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();

class Customers extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: 'id',
            sortedBy: '',
            searchBy: null,

            level: null,
            levels: [],
            rows: [],
            total: 0,
            perPage: 500,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Customer';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Customer');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        this.__fetchData(false);

        Api.get('/lookup/by-group-id/1').then(resp => {

            if (resp.data) {

                this.setState({
                        levels: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

    }
    __fetchData = update => {
        this.setState({
                loading: true
            }
        );

        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            size: this.state.perPage,
            ['search-by']: this.state.searchBy,
            ['level-id']: this.state.level,
            sort: [
                {
                    'column-name': this.state.orderBy,
                    'is-ascending': this.state.sortedBy === 'asc' ? true : false,
                }
            ],
        };

        Api.post('/customer/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                    rows: resp.data.customers,
                    total: resp.data.totalItems,
                    currentPageTable: resp.data.currentPage,
                    currentPage: resp.data.currentPage,
                    nextPage: resp.data.currentPage+1,
                    loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleSearch = () => {
        this.__fetchData(true);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(true);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleChangeSelect (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchData(true);
        });

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row['id-customer'],
            currDelete: row['full-name']+' '+row.email,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            let params = {
                listId: [this.state.idDelete]
            };

            Api.delete('/customer', params).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Customer berhasil dihapus');
                this.__fetchData(true);

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo = (link) => {

        history.push(link);
    };

    handleExport = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        let params = {
            size: 500,
            page: this.state.currentPage,
            sort: [
                {
                    'column-name': this.state.orderBy,
                    'is-ascending': this.state.sortedBy === 'asc' ? true : false,
                }
            ],
        };
        Api.postEx('/customer/export/excel',params,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'customers.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Customers</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Customers</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Daftar Customer</h2>
                            </div>
                            <div className="col-6 text-right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='mr-2'
                                    onClick={() => this.handleGo('/customers/reset')}
                                    type='button'
                                >
                                    Reset Password
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleExport(true)}
                                    type='button'
                                >
                                    <i className="fas fa-file-excel mr-2"></i>
                                    Export {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between content-filter">
                            <div className="col-md-6 col-lg-4 mb-2 mb-md-0">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    className="search-field"
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder="Search here"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="Search click"
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className="fas fa-search"> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-lg-4 text-md-right">
                                <TextField variant="outlined"
                                           select
                                           id="level"
                                           name="level"
                                           label="Level"
                                           onChange={(e) => this.handleChangeSelect(e, 'level')}
                                           value={this.state.level}
                                           fullWidth
                                >
                                    <MenuItem value=''>
                                        Semua
                                    </MenuItem>
                                    {this.state.levels.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[500]}
                            component="div"
                            count={this.state.total}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{minWidth:200}} className="clickable" onClick={() => this.handleSort('username')} >User Name
                                            {this.state.orderBy === 'username' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('fullName')}
                                        >Full Name
                                            {this.state.orderBy === 'fullName' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('email')}>Email
                                            {this.state.orderBy === 'email' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell>Level</TableCell>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell style={{minWidth:100}}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={6} style={{ textAlign: "center" }}>Customer kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row['id-customer']}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <img src={row.avatar !== null ? row.avatar : user} alt='avatar' className="img-user mr-2" />
                                                        <span>{row.username === null ? '' : row.username}</span>
                                                    </TableCell>
                                                    <TableCell>{row['full-name'] === null ? '' : row['full-name']}</TableCell>
                                                    <TableCell>{row.email === null ? '' : row.email}</TableCell>
                                                    <TableCell>{row.level === null ? '' : row.level}</TableCell>
                                                    <TableCell>{row['created-date'] === null ? '' : row['created-date']}</TableCell>
                                                    <TableCell className='action-menu'>

                                                        <button className="btn-icon">
                                                            <i className="fas fa-ellipsis-v"> </i>
                                                        </button>

                                                        <div className='action-wrap'>
                                                            <Link to={"/customers/edit/" + row['id-customer']}
                                                                  className="btn-icon mb-2">
                                                                <i className="fas fa-pencil-alt"> </i> Ubah
                                                            </Link>
                                                            {this.state.isManage &&
                                                            <button className="btn-icon"
                                                                    onClick={() => this.handleOpen(row)}>
                                                                <i className="fas fa-trash-alt"> </i> Hapus
                                                            </button>
                                                            }
                                                        </div>

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[500]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">KONFIRMASI </DialogTitle>
                    <DialogContent>
                        <p>Apakah anda yakin akan menghapus <strong>{this.state.currDelete}</strong>?</p>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleClose}>
                            Batal
                        </button>
                        <button className="btn btn-blue" onClick={this.handleDelete}>
                            Hapus
                        </button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />

            </div>
        )
    }
}

export default Customers;
