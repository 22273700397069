import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from "../../../libraries/api";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();

class RolesEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            rows: [],
            name: '',

            isManage: false,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Detail Permission';
        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Role Permission');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }


        const data = this.props.location.state;

        this.setState({
            name: data.description

        });

        this.__fetch();

    }

    __fetch = () => {
        const id = this.props.match.params.id;

        let params = {
            page: 0,
            size: 50,
            ['id-role']: parseFloat(id),
        };

        Api.post('/role-permission/role-map-menu/get-all', params).then(resp => {

            let data = resp.data;

            if (data) {
                let roles = data.roleMapeMenus.map(o => {
                    return {
                        id: o.id,
                        menu: o.menu.name,
                        name: o.role.name,
                        isCreate: o.isCreate,
                        isManage: o.isManage,
                    }
                });

                this.setState({
                        rows: roles,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
                loadingButton: true,
            }
        );

        let roles = this.state.rows.map(o => {
            return {
                id: o.id,
                isCreate: o.isCreate,
                isManage: o.isManage,
            }
        });

        Api.put('/role-permission/role-permission-menu', roles).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Roles berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);
            }

            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChecked = (item, prop) => {

        let data = this.state.rows;

        _.forEach(data, function(v,k){
            if(v.id === item.id){
                v[prop] = !item[prop];
            }
        });

        this.setState({
            rows: data,
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Roles</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/roles" >Roles</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Pengaturan Role {this.state.name}</h2>
                            </div>
                            <div className="col-6 text-right">
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                >
                                    <i className="fas fa-check-circle mr-2"> </i>
                                    Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }

                            </div>
                        </div>

                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Menu</TableCell>
                                        <TableCell>Manage</TableCell>
                                        <TableCell>View</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={4} style={{ textAlign: "center" }}>Permission kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>{row.menu === null ? '' : row.menu}</TableCell>
                                                    <TableCell>
                                                        <FormGroup className='mb-3 label-tnc'>
                                                            <FormControlLabel
                                                                color="primary"
                                                                name='active'
                                                                control={<Checkbox
                                                                    checked={row.isManage}
                                                                    onChange={() => this.handleChecked(row, 'isManage')}
                                                                    name='active'
                                                                    value={true}
                                                                />}
                                                                className="mb-0"
                                                            />
                                                        </FormGroup>
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormGroup className='mb-3 label-tnc'>
                                                            <FormControlLabel
                                                                color="primary"
                                                                name='active'
                                                                control={<Checkbox
                                                                    checked={row.isCreate}
                                                                    onChange={() => this.handleChecked(row, 'isCreate')}
                                                                    name='active'
                                                                    value={true}
                                                                />}
                                                                className="mb-0"
                                                            />
                                                        </FormGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                    </div>


                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default RolesEdit;
