import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import MomentUtils from "@date-io/moment/build/index";
import moment from "moment/moment";
import _ from "lodash";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class AdminAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            roles: [],

            id: '',
            name: '',
            email: '',
            password: '',
            phone: '',
            address: '',
            birth: null,

            roleId: '',
            isManage: false,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Admin';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Admin');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }


        Api.get('/role-permission/active').then(resp => {

            if (resp.data) {
                let all = [];
                _.forEach(resp.data, function(v,k){
                    if(v.name !== 'ADMIN' && v.name !== 'CUSTOMER' && v.name !== 'SUPER_ADMIN') {
                        all.push(v)
                    }
                });
                this.setState({
                    roles: all,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'birth-date': moment(this.state.birth).format('DD-MM-YYYY'),
            'full-name': this.state.name,
            'email': this.state.email,
            'phone-number': this.state.phone,
            'address': this.state.address,
            'role-id': this.state.roleId,
            'password': this.state.password,
            'level-id' : 1
        };


        Api.post('/admin/register', params).then(resp => {

            if(resp.status === 200) {
                history.push('/admins');

                this.showMessage(true, 'Admin berhasil ditambahkan');
            }
            else {
                this.showMessage(resp.message[0], 'Invalid format data');
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        })

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleAvatar = e => {
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            avatar: e.target.files[0]
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Tambah Admin</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/admins" >Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tambah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Tambah Admin</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="admin" id="addAdmin" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nama Lengkap</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='name'
                                               name="name"
                                               label="Nama Lengkap"
                                               onChange={(e) => this.handleChange(e, 'name')}
                                               value={this.state.name}
                                               fullWidth
                                    />
                                    {validator.message('name', this.state.name, 'required')}
                                    <div className='text-danger'>{this.state.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tanggal Lahir</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Tanggal Lahir"
                                            value={this.state.birth}
                                            onChange={(date) => this.handleDate(date, 'birth')}
                                            format={'DD-MM-YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validator.message('birth', this.state.birth, 'required')}
                                    <div className='text-danger'>{this.state.errors.birth}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='email'
                                               name="email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email|required')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <TextField variant="outlined"
                                               type='number'
                                               id='phone'
                                               name="phone"
                                               label="Phone"
                                               onChange={(e) => this.handleChange(e, 'phone')}
                                               minLength={4}
                                               maxLength={50}
                                               value={this.state.phone}
                                               fullWidth
                                    />
                                    {validator.message('phone', this.state.phone, 'required')}
                                    <div className='text-danger'>{this.state.errors.phone}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='address'
                                               name="address"
                                               label="Address"
                                               onChange={(e) => this.handleChange(e, 'address')}
                                               value={this.state.address}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.address}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Role</label>
                                    <TextField variant="outlined"
                                               select
                                               id="roleId"
                                               name="roleId"
                                               label="Role"
                                               onChange={(e) => this.handleChange(e, 'roleId')}
                                               value={this.state.roleId}
                                               fullWidth
                                    >
                                        {this.state.roles.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('roleId', this.state.roleId, 'required')}
                                    <div className='text-danger'>{this.state.errors.roleId}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <h3 className='section-title'>Kelola Password</h3>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <TextField variant="outlined"
                                               type={this.state.showPassword ? 'text' : 'password'}
                                               id="adornment-password"
                                               name="password"
                                               label="Password"
                                               onChange={(e) => this.handleChange(e,'password')}
                                               value={this.state.password}
                                               fullWidth
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="Toggle password visibility"
                                                               onClick={this.handleClickShowPassword}
                                                           >
                                                               {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                   <i className="fas fa-eye-slash"> </i>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   )
                                               }}
                                    />
                                    {validator.message('password', this.state.password, 'min:8')}
                                    <div className='text-danger'>{this.state.errors.password}</div>
                                </div>
                            </div>


                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/admins')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Tambah{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default AdminAdd;
