import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import MomentUtils from "@date-io/moment/build/index";
import moment from "moment/moment";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class CouponsAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            types: [],

            code: '',
            couponType: '',
            description: '',
            expired: null,
            limitPerCoupon: '',
            limitPerUser: '',
            value: '',
            isManage: false,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Kupon';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Kupon');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        Api.get('/lookup/by-group-id/4').then(resp => {

            if (resp.data) {

                this.setState({
                    types: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );
        let params = {
            'code': this.state.code,
            'coupon-type-id': this.state.couponType,
            'description': this.state.description,
            'expired-date':  moment(this.state.expired).format('DD/MM/YYYY'),
            'limit-usage-per-coupon': this.state.limitPerCoupon,
            'limit-usage-per-user': this.state.limitPerCoupon,
            'value': this.state.value,
        };


        Api.post('/coupon', params).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            history.push('/coupons');
            this.showMessage(true, 'Kupon berhasil dibuat');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };
    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Kupon</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/coupons" >Kupon</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tambah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Buat Kupon</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="coupon" id="addCoupon" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Kode</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='code'
                                               name="code"
                                               label="Kode"
                                               onChange={(e) => this.handleChange(e, 'code')}
                                               value={this.state.code}
                                               fullWidth
                                    />
                                    {validator.message('code', this.state.code, 'required')}
                                    <div className='text-danger'>{this.state.errors.code}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='description'
                                               name="description"
                                               label="Deskripsi"
                                               onChange={(e) => this.handleChange(e, 'description')}
                                               value={this.state.description}
                                               fullWidth
                                    />
                                    {validator.message('description', this.state.description, 'required')}
                                    <div className='text-danger'>{this.state.errors.description}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tipe</label>
                                    <TextField variant="outlined"
                                               select
                                               id="couponType"
                                               name="couponType"
                                               label="Tipe"
                                               onChange={(e) => this.handleChange(e, 'couponType')}
                                               value={this.state.couponType}
                                               fullWidth
                                    >
                                        {this.state.types.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('couponType', this.state.couponType, 'required')}

                                    <div className='text-danger'>{this.state.errors.couponType}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nilai</label>
                                    <TextField variant="outlined"
                                               type='number'
                                               id='value'
                                               name="value"
                                               label="Nilai"
                                               onChange={(e) => this.handleChange(e, 'value')}
                                               value={this.state.value}
                                               fullWidth
                                    />
                                    {validator.message('value', this.state.value, 'required')}
                                    <div className='text-danger'>{this.state.errors.value}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Expired</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Tanggal"
                                            value={this.state.expired}
                                            onChange={(date) => this.handleDate(date, 'expired')}
                                            format={'DD-MM-YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className='text-danger'>{this.state.errors.expired}</div>
                                </div>
                            </div>

                            <div className="col-12">
                                <h3 className='section-title'>Usage</h3>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Limit usage per kupon</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='limitPerCoupon'
                                               name="limitPerCoupon"
                                               label="Limit"
                                               onChange={(e) => this.handleChange(e, 'limitPerCoupon')}
                                               value={this.state.limitPerCoupon}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.limitPerCoupon}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Limit usage per user</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='limitPerUser'
                                               name="limitPerUser"
                                               label="Limit"
                                               onChange={(e) => this.handleChange(e, 'limitPerUser')}
                                               value={this.state.limitPerUser}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.limitPerUser}</div>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/coupons')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Tambah{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>

                                }

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default CouponsAdd;
