import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import user from "../../../images/default.jpeg";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import SimpleReactValidator from "simple-react-validator";
import AuthHelper from "../../../libraries/auth-helper";
import TextField from "@material-ui/core/TextField";
import moment from "moment/moment";
import ErrorHandling from "../../../libraries/error-handling";
import MenuItem from "@material-ui/core/MenuItem";

require('dotenv').config();
const validator = new SimpleReactValidator();

class Settings extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,
            include: null,
            exclude: null,
            blast: 0,

            banks: [],

            level: '',
            month: '',
            customer: '',
            note: '',
            point: '',
            levels: [],
            rows: [],
            shippings: [],
            configs: [],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Pengaturan';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Pengaturan');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }
        this.__fetchBank();

        Api.get('/lookup/by-group-id/3').then(resp => {

            let all =[];


            if (resp.data) {
                this.setState({
                        shippings: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

        let params = {
            page: 0,
            size: 50,
        };

        Api.post('/config/get-all', params).then(resp => {

            if (resp.data) {
                this.setState({
                    configs: resp.data.configs,
                    loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleChange (e, prop){

        if(prop === 'blast') {
            this.setState({
                include: null,
                exclude: null,
            });
        }

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.bankName,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            let params = {
                listId: [this.state.idDelete]
            };

            Api.delete('/bank', params).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Bank berhasil dihapus');
                this.__fetchBank();

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    __fetchBank = () => {
        let params = {
            page: 0,
            size: 50,
        };

        Api.post('/bank/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        banks: resp.data.banks,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleGo = (link) => {

        history.push(link);
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    handleSend = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let detail = this.state.link === 'detail' ? 'detail='+this.state.detail : this.state.link;

        let params = {
            'link': detail,
            'title': this.state.title,
            'message': this.state.message,
            'target': this.state.include,
            'target-exclude': this.state.exclude,
        };


        Api.post('/push-notif/broadcast-push-notif', params).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            this.showMessage(true, 'Notifikasi berhasil dikirim');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Pengaturan</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Pengaturan</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Pengaturan</h2>
                            </div>
                        </div>
                        <form className='mt-3'>
                            <AppBar position="static">
                                <Tabs value={this.state.value}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      onChange={this.handleChangeTab} aria-label="produk tabs">
                                    <Tab label="Bank" {...a11yProps(0)} />
                                    <Tab label="Ekspedisi" {...a11yProps(1)} />
                                    <Tab label="General" {...a11yProps(2)} />
                                    <Tab label="Push Notif" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-right">
                                        {this.state.isManage &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => this.handleGo('/settings/add')}
                                        >
                                            <i className="fas fa-plus mr-2"> </i>
                                            Buat Bank
                                        </Button>
                                        }

                                    </div>
                                </div>
                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama Bank</TableCell>
                                                <TableCell>Nomor Akun</TableCell>
                                                <TableCell>Aktif</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.banks.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={4} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.banks.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>
                                                                <img src={row.logo !== null ? row.logo : user} alt='image' className="img-logo mr-2" />
                                                                {row.bankName === null ? '' : row.bankName}</TableCell>
                                                            <TableCell>{row.accountNumber === null ? '' : row.accountNumber}</TableCell>
                                                            <TableCell>{row['is-active']? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/settings/edit/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    {this.state.isManage &&
                                                                    <button className="btn-icon" type='button' onClick={() => this.handleOpen(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Deactive
                                                                    </button>
                                                                    }

                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>

                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>

                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama Shipping</TableCell>
                                                <TableCell>Deskripsi</TableCell>
                                               {/* <TableCell>Action</TableCell>*/}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.shippings.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.shippings.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>
                                                                <img src={row.image !== null ? row.image : user} alt='image' className="img-logo mr-2" />
                                                                <span>{row.name}</span>
                                                            </TableCell>
                                                            <TableCell>{row.description === null ? '' : row.description}</TableCell>
                                                          {/*  <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/shippings/edit/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    <button className="btn-icon" onClick={() => this.handleOpen(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Hapus
                                                                    </button>
                                                                </div>
                                                            </TableCell>*/}

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>

                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama</TableCell>
                                                <TableCell>Value</TableCell>
                                                 <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.configs.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.configs.map(row =>  (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                            <TableCell>{row.value === null ? '' : row.value}</TableCell>
                                                              <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/settings/config/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={3}>
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Judul</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='title'
                                                   name="title"
                                                   label="Judul"
                                                   onChange={(e) => this.handleChange(e, 'title')}
                                                   value={this.state.title}
                                                   fullWidth
                                        />
                                        {validator.message('title', this.state.title, 'required')}
                                        <div className='text-danger'>{this.state.errors.title}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Pesan</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='message'
                                                   name="message"
                                                   label="Pesan"
                                                   onChange={(e) => this.handleChange(e, 'message')}
                                                   value={this.state.message}
                                                   fullWidth
                                        />
                                        {validator.message('message', this.state.message, 'required')}
                                        <div className='text-danger'>{this.state.errors.message}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Link</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   select
                                                   id="link"
                                                   name="link"
                                                   label="Link"
                                                   className="mb-2"
                                                   onChange={(e) => this.handleChange(e, 'link')}
                                                   value={this.state.link}
                                                   fullWidth>
                                            <MenuItem value='home'>Home</MenuItem>
                                            <MenuItem value='product'>Product</MenuItem>
                                            <MenuItem value='detail'>Detail</MenuItem>
                                        </TextField>

                                        {this.state.link === 'detail' &&
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='detail'
                                                   name="detail"
                                                   label="Product ID"
                                                   onChange={(e) => this.handleChange(e, 'detail')}
                                                   value={this.state.detail}
                                                   fullWidth
                                        />}
                                        {validator.message('link', this.state.link, 'required')}
                                        <div className='text-danger'>{this.state.errors.link}</div>
                                        {this.state.link === 'detail' &&
                                        <div className='mt-2'>
                                            <p className='mb-0'>Detail produk: <strong>?id=sku</strong></p>
                                            <p className='mb-0'>Filter list produk: <strong>?cat=1&sub=6</strong></p>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Blast Type</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   select
                                                   id="blast"
                                                   name="blast"
                                                   label="Blast Type"
                                                   className="mb-2"
                                                   onChange={(e) => this.handleChange(e, 'blast')}
                                                   value={this.state.blast}
                                                   fullWidth>
                                            <MenuItem value='0'>All</MenuItem>
                                            <MenuItem value='1'>Include</MenuItem>
                                            <MenuItem value='2'>Exclude</MenuItem>
                                        </TextField>
                                        {validator.message('blast', this.state.blast, 'required')}
                                        <div className='text-danger'>{this.state.errors.blast}</div>
                                    </div>
                                </div>
                                {this.state.blast === '1' &&
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Include</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='include'
                                                   name="include"
                                                   label="Include"
                                                   onChange={(e) => this.handleChange(e, 'include')}
                                                   value={this.state.include}
                                                   fullWidth
                                        />
                                        <div className='text-danger'>{this.state.errors.include}</div>
                                    </div>
                                </div>
                                }

                                {this.state.blast === '2' &&
                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Exclude</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='exclude'
                                                   name="exclude"
                                                   label="exclude"
                                                   onChange={(e) => this.handleChange(e, 'exclude')}
                                                   value={this.state.exclude}
                                                   fullWidth
                                        />
                                        <div className='text-danger'>{this.state.errors.exclude}</div>
                                    </div>
                                </div>
                                }


                                <div className="form-group row justify-content-end">

                                    <div className='col-sm-8 col-lg-6'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='mr-2'
                                            onClick={this.handleSend}
                                            type='button'
                                            disabled={this.state.loadingButton }
                                        >
                                            Kirim{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                    </div>
                                </div>

                            </TabPanel>
                        </form>

                    </div>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialog}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">PERHATIKAN! {this.state.currDelete}</DialogTitle>
                        <DialogContent>
                            <p>Apakah anda yakin akan Non Aktif kan Bank?</p>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleClose}>
                                Batal
                            </button>
                            <button className="btn btn-blue" onClick={this.handleDelete}>
                                Deactive
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>


                <ToastContainer />

            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}
export default Settings;
