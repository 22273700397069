import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class AddCategory extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            id: '',
            name: '',
            description: '',
            isManage: false,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Kategori';
        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Blog Categories');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'name': this.state.name,
            'description': this.state.description,
        };


        Api.post('/product-category', params).then(resp => {

            if(resp.status === 200) {
                history.push('/categories');
                this.showMessage(true, 'Kategori berhasil dibuat');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });


    };

    handleGo  = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Kategori</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/categories" >Kategori</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Buat</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Buat Kategori</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="category" id="addCategory" className="row" noValidate>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nama</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='name'
                                               name="name"
                                               label="Nama"
                                               onChange={(e) => this.handleChange(e, 'name')}
                                               value={this.state.name}
                                               fullWidth
                                    />
                                    {validator.message('name', this.state.name, 'required')}
                                    <div className='text-danger'>{this.state.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='description'
                                               name="description"
                                               label="Deskripsi"
                                               onChange={(e) => this.handleChange(e, 'description')}
                                               value={this.state.description}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.description}</div>
                                </div>
                            </div>


                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/categories')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Tambah{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default AddCategory;
