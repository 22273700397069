import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Default from "../../../images/default.jpeg";
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class SettingsAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            types: [],

            id: '',
            bankName: '',
            accountName: '',
            accountNumber: '',
            logo: null,
            active: false,
            photo: Default,

            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Pengaturan';
        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Pengaturan');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }
        const data = this.props.location.state;
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'account-bank-name': this.state.accountName,
            'account-bank-number': this.state.accountNumber,
            'bank-name': this.state.bankName,
            'is-active':  this.state.active,
            'logo': this.state.logo,
        };


        Api.post('/bank', params).then(resp => {
            if(resp.status === 200) {
                history.push('/settings');
                this.showMessage(true, 'Bank berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                logo: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleDeleteAvatar = () => {

        this.setState({
            photo: Default,
            logo: null
        });
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleChecked = e => {

        this.setState({

            active: e.target.checked,

        })

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Bank</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/settings" >Bank</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tambah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Tambah Bank</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="settings" id="addSettings" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group">
                                    <img src={this.state.photo} alt="user" className="img-logo-big mr-2" />
                                    <TextField variant="outlined"
                                               type="file"
                                               id='avatar'
                                               name="avatar"
                                               className="avatar"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="avatar" className="label-file mr-2">Unggah Gambar {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.logo}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nama Bank</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='bankName'
                                               name="bankName"
                                               label="Bank"
                                               onChange={(e) => this.handleChange(e, 'bankName')}
                                               value={this.state.bankName}
                                               fullWidth
                                    />
                                    {validator.message('bankName', this.state.bankName, 'required')}
                                    <div className='text-danger'>{this.state.errors.bankName}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Atas Nama Akun</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='accountName'
                                               name="accountName"
                                               label="Nama"
                                               onChange={(e) => this.handleChange(e, 'accountName')}
                                               value={this.state.accountName}
                                               fullWidth
                                    />
                                    {validator.message('accountName', this.state.accountName, 'required')}
                                    <div className='text-danger'>{this.state.errors.accountName}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nomor Akun</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='accountNumber'
                                               name="accountNumber"
                                               label="Nomor"
                                               onChange={(e) => this.handleChange(e, 'accountNumber')}
                                               value={this.state.accountNumber}
                                               fullWidth
                                    />
                                    {validator.message('accountNumber', this.state.accountNumber, 'required')}
                                    <div className='text-danger'>{this.state.errors.accountNumber}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <FormGroup className='mb-3 label-tnc'>
                                        <FormControlLabel
                                            color="primary"
                                            name='active'
                                            control={<Checkbox
                                                checked={this.state.active}
                                                onChange={this.handleChecked}
                                                name='active'
                                                value={true}
                                            />}
                                            label='Active'
                                            className="mb-0"
                                        />
                                    </FormGroup>
                                    <div className='text-danger'>{this.state.errors.active}</div>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/settings')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Tambah { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default SettingsAdd;
