import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import photo from "../../../images/default.jpeg";
import { Link } from 'react-router-dom';
import Default from "../../../images/default.jpeg";
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import moment from "moment/moment";

require('dotenv').config();
const validator = new SimpleReactValidator();

class About extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingBanner: false,
            loadingImage: false,
            loadingIcon: false,
            errors: {},

            id: '',
            description: '',
            banner: null,
            descImage: null,
            title: '',
            descTitle: '',
            descHero: '',
            why: '',
            details: [],
            photo: Default,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - About';

        Api.get('/about/1').then(resp => {

            let data = resp.data;
            if (data) {
                let details = data.details.map(o => {
                    return {
                        id: o.id,
                        title: o.title,
                        description: o.description,
                        icon: null,
                        iconImage: o.icon,
                    }
                });

                this.setState({
                    description: data.description,
                    banner: null,
                    photo: data['about-banner'] !== null ? data['about-banner'] : Default,
                    title: data['about-title'],
                    descImage: null,
                    image: data['desc-image'] !== null ? data['desc-image'] : Default,
                    descTitle: data['desc-title'],
                    descHero: data['hero-description'],
                    why: data['why-title'],
                    details: details,
                });
            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingBanner: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                banner: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingBanner: false,
            }),
            3000
        );
    };

    handleDeleteAvatar = () => {

        this.setState({
            photo: Default,
            banner: null
        });
    };


    handleImage = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                descImage: idCardBase64,
                image: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleDeleteImage = () => {

        this.setState({
            image: Default,
            descImage: null
        });
    };


    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'description': this.state.description,
            'about-banner': this.state.banner,
            'about-title': this.state.title,
            'desc-image': this.state.descImage,
            'desc-title': this.state.descTitle,
            'why-title': this.state.why,
            'hero-description': this.state.descHero,
            'id': 1,
            'details': this.state.details,
        };

        Api.put('/about', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'About berhasil diubah');
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleChangeDetails = (e, prop, id) => {
        let data = this.state.details;

        if(prop === 'icon') {
            let idCardBase64 = '';
            let url = e.target.files[0];
            this.setState({
                loadingIcon: true,
            });

            this.getBase64(e.target.files[0], (result) => {
                idCardBase64 = result;
            });

            setTimeout(
                () => {

                    _.forEach(data, function(v,k){
                        if(k === id){
                            v[prop] = idCardBase64;
                            v['iconImage'] =  URL.createObjectURL(url);
                        }
                    });


                    this.setState({
                        errors: {},
                        details: data,
                        loadingIcon: false
                    });
                },
                3000
            );
        }else {
            _.forEach(data, function(v,k){
                if(k === id){
                    v[prop] = e.target.value;
                }
            });

            this.setState({
                errors: {},
                details: data,
            });
        }

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Detail About</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Ubah About</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="about" id="editAbout" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className='d-block'>Banner</label>
                                    <img src={this.state.photo} alt="user" className="img-logo-big mr-2" />
                                    <TextField variant="outlined"
                                               type="file"
                                               id='banner'
                                               name="banner"
                                               className="avatar"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="banner" className="label-file mr-2">Unggah Gambar {this.state.loadingBanner && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.banner}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='title'
                                               name="title"
                                               label="Title"
                                               onChange={(e) => this.handleChange(e, 'title')}
                                               value={this.state.title}
                                               fullWidth
                                    />
                                    {validator.message('title', this.state.title, 'required')}
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Deskripsi</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='description'
                                               name="description"
                                               label="Deskripsi"
                                               onChange={(e) => this.handleChange(e, 'description')}
                                               value={this.state.description}
                                               fullWidth
                                               multiline
                                    />
                                    {validator.message('description', this.state.description, 'required')}
                                    <div className='text-danger'>{this.state.errors.description}</div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="form-group">
                                    <label className='d-block'>Hero Image</label>
                                    <img src={this.state.image} alt="user" className="img-logo-big mr-2" />
                                    <TextField variant="outlined"
                                               type="file"
                                               id='descImage'
                                               name="descImage"
                                               className="avatar"
                                               onChange={this.handleImage}
                                    />
                                    <label htmlFor="descImage" className="label-file mr-2">Unggah Gambar {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteImage}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.descImage}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Hero Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='descTitle'
                                               name="descTitle"
                                               label="Hero Title"
                                               onChange={(e) => this.handleChange(e, 'descTitle')}
                                               value={this.state.descTitle}
                                               fullWidth
                                    />
                                    {validator.message('descTitle', this.state.descTitle, 'required')}
                                    <div className='text-danger'>{this.state.errors.descTitle}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Hero Deskripsi</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='descHero'
                                               name="descHero"
                                               label="Hero Deskripsi"
                                               onChange={(e) => this.handleChange(e, 'descHero')}
                                               value={this.state.descHero}
                                               fullWidth
                                               multiline
                                    />
                                    {validator.message('descHero', this.state.descHero, 'required')}
                                    <div className='text-danger'>{this.state.errors.descHero}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Why Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='why'
                                               name="why"
                                               label="Why Title"
                                               onChange={(e) => this.handleChange(e, 'why')}
                                               value={this.state.why}
                                               fullWidth
                                    />
                                    {validator.message('why', this.state.why, 'required')}
                                    <div className='text-danger'>{this.state.errors.why}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Details</label>
                                    {this.state.details.length > 0 &&
                                    this.state.details.map(function (item, idx) {
                                        return <div className="row mb-3">
                                            <div className='col-12 mb-3'>
                                                <img src={item.iconImage ? item.iconImage : photo} alt="user" className="img-product mr-2" />
                                                <TextField variant="outlined"
                                                           type="file"
                                                           id={'icon-'+idx}
                                                           name="icon"
                                                           className="avatar"
                                                           onChange={(e) => this.handleChangeDetails(e, 'icon', idx)}

                                                />
                                                <label htmlFor={'icon-'+idx} className="label-file mr-2">Ubah Foto {this.state.loadingIcon && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                                <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                                    Hapus Foto
                                                </button>
                                                <div className='text-danger'>{this.state.errors['icon-'+idx]}</div>

                                            </div>
                                            <div className='col-md-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='title'
                                                           name="title"
                                                           label="Title"
                                                           onChange={(e) => this.handleChangeDetails(e, 'title', idx)}
                                                           value={item.title}
                                                           fullWidth />
                                                {validator.message('title-' + idx, item.title, 'required')}
                                                <div className='text-danger'>{this.state.errors['title-' + idx]}</div>
                                            </div>
                                            <div className='col-md-6'>
                                                <TextField variant="outlined"
                                                           type='text'
                                                           id='description'
                                                           name="description"
                                                           label="Deskripsi"
                                                           onChange={(e) => this.handleChangeDetails(e, 'description', idx)}
                                                           value={item.description}
                                                           fullWidth
                                                           multiline />
                                                {validator.message('description-' + idx, item.description, 'required')}
                                                <div className='text-danger'>{this.state.errors['description-' + idx]}</div>
                                            </div>
                                        </div>

                                    }.bind(this))
                                    }
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton && 'disabled' }
                                >
                                    Ubah{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default About;
