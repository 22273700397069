import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from '@material-ui/core/TextField';
import Logo from '../../../images/logo.png';
import LoginImg from '../../../images/login.svg';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {connect} from 'react-redux';
import {RootActions} from '../../../shared/root-action';
import {bindActionCreators} from 'redux';
import Api from '../../../libraries/api';
import {toast, ToastContainer} from "react-toastify";
import AuthHelper from '../../../libraries/auth-helper';
import {history} from '../../../shared/configure-store';

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Login extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            email: '',
            password: '',
            persistence: false,
            showPassword: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);

    }

    handleLogIn = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            username: this.state.email,

            password: this.state.password,

        };

        Api.post('/user/authenticate', params).then(resp => {

            console.log(resp.status)

            if(resp.status === 200) {
                AuthHelper.setLogin(resp.data);

                AuthHelper.setProfile(resp.data);

                let profile = resp.data;

                let user = JSON.parse(JSON.stringify(profile));

                this.props.setProfile(user);

                history.push('/');
            }else {
                this.setState({
                        errors: resp.message[0],
                        loading: false
                    }
                );

                this.showMessage(false, resp.message[0] === 'INVALID_CREDENTIALS' ? 'username dan password salah' : resp.message[0]);

            }


        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        return (
            <main className='login'>
                <section>
                    <div className='container-fluid text-center'>
                        <div className='row align-items-center wrapper'>
                            <div className='col-md-6 '>

                                <form name='login' id='login' noValidate>
                                    <img src={Logo} alt='gudangemas' className='img-fluid' />
                                    <h1>Welcome Back</h1>
                                    <div className='form-group'>
                                        <TextField variant='outlined'
                                                   type='text'
                                                   id='email'
                                                   name='email'
                                                   label='Your Email'
                                                   placeholder='Your email here...'
                                                   onChange={(e) => this.handleChange(e, 'email')}
                                                   minLength={4}
                                                   maxLength={50}
                                                   value={this.state.email}
                                                   fullWidth
                                        />
                                        {validator.message('email', this.state.email, 'required')}
                                        <div className='text-danger'>{this.state.errors.email}</div>
                                    </div>

                                    <div className='form-group'>
                                        <TextField variant='outlined'
                                                   type={this.state.showPassword ? 'text' : 'password'}
                                                   id='adornment-password'
                                                   name='password'
                                                   label='Your Password'
                                                   placeholder='Your password here..'
                                                   onChange={(e) => this.handleChange(e,'password')}
                                                   value={this.state.password}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position='end'>
                                                               <IconButton
                                                                   className='btn-input'
                                                                   aria-label='Toggle password visibility'
                                                                   onClick={this.handleClickShowPassword}
                                                               >
                                                                   {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                       <i className='fas fa-eye-slash'> </i>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       )
                                                   }}
                                                   fullWidth
                                        />
                                        {validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>
                                    <div className='text-danger mb-2'>{this.state.errors.message}</div>
                                    <button onClick={this.handleLogIn} className='btn btn-blue full my-3' disabled={this.state.loading && 'disabled' }>Log In { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }</button>
                                </form>
                            </div>
                            <div className='col-md-6 p-0'>
                                <img src={LoginImg} alt='gudangemas' className='img-fluid' />
                            </div>
                        </div>

                    </div>
                </section>
                <ToastContainer autoClose={false} />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Login);