import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from "../../../libraries/api";
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {history} from "../../../shared/configure-store";
import _ from "lodash";

require('dotenv').config();

class Roles extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            level: '',
            levels: [],
            rows: [],
            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Roles';

        this.__fetchData(false);

    }
    __fetchData = update => {
        this.setState({
                loading: true
            }
        );
        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            size: this.state.perPage,
            ['search-by']: this.state.searchBy,
        };

        Api.post('/role-permission/get-all', params).then(resp => {

            if(resp.data) {
                let all = [];
                _.forEach(resp.data.roles, function(v,k){
                 if(v.name !== 'ADMIN' && v.name !== 'CUSTOMER' && v.name !== 'SUPER_ADMIN') {
                     all.push(v)
                 }
                });
                this.setState({
                        rows: all,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    handleSearch = () => {
        this.__fetchData(false);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(false);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {

        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChangeSelect (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchData(true);
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Roles</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Roles</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>List Roles</h2>
                            </div>
                        </div>

                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nama</TableCell>
                                        <TableCell>Deksripsi</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={3} style={{ textAlign: "center" }}>Kupon kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                    <TableCell>{row.description === null ? '' : row.description}</TableCell>
                                                    <TableCell className='action-menu'>
                                                        <button className="btn-icon">
                                                            <i className="fas fa-ellipsis-v"> </i>
                                                        </button>
                                                        <div className='action-wrap'>
                                                            <button className="btn-icon"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        history.push({
                                                                            pathname: "/roles/edit/" + row.id,
                                                                            state: row,
                                                                        });
                                                                    }}>
                                                                <i className="fas fa-pencil-alt"> </i> Ubah
                                                            </button>
                                                        </div>

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <ToastContainer />

            </div>
        )
    }
}

export default Roles;
