import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Api from "../../../libraries/api";
import user from "../../../images/default.jpeg";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import SimpleReactValidator from "simple-react-validator";

require('dotenv').config();

class Medias extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,

            videos: [],
            banners: [],
            galleries: [],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Media';

        Api.get('/lookup/by-group-id/3').then(resp => {

            if (resp.data) {
                this.setState({
                        shippings: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

        this.__fetchBanner();
        this.__fetchGalleries();
        this.__fetchVides();
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {

            Api.delete('/admin/delete-media/'+this.state.idDelete).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Media berhasil dihapus');
                this.__fetchBanner();
                this.__fetchGalleries();
                this.__fetchVides();

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    __fetchBanner = () => {
        let params = {
            page: 0,
            size: 100,
            type: 'BANNER',
        };

        Api.post('/admin/get-all-media', params).then(resp => {

            if(resp.data) {
                this.setState({
                    banners: resp.data.multimediaConfigs,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchGalleries = () => {
        let params = {
            page: 0,
            size: 100,
            type: 'GALLERY',
        };

        Api.post('/admin/get-all-media', params).then(resp => {

            if(resp.data) {
                this.setState({
                    galleries: resp.data.multimediaConfigs,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchVides = () => {
        let params = {
            page: 0,
            size: 100,
            type: 'VIDEO',
        };

        Api.post('/admin/get-all-media', params).then(resp => {

            if(resp.data) {
                this.setState({
                    videos: resp.data.multimediaConfigs,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleGo = (link) => {

        history.push(link);
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };


    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Home Media</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Home Media</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Home Media</h2>
                            </div>
                        </div>
                        <form className='mt-3'>
                            <AppBar position="static">
                                <Tabs value={this.state.value}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      onChange={this.handleChangeTab} aria-label="produk tabs">
                                    <Tab label="Banners" {...a11yProps(0)} />
                                    <Tab label="Gallery" {...a11yProps(1)} />
                                    <Tab label="Video" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => this.handleGo('/homes/banner')}
                                        >
                                            <i className="fas fa-plus mr-2"> </i>
                                            Buat Banner
                                        </Button>
                                    </div>
                                </div>
                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Media</TableCell>
                                                <TableCell>Link</TableCell>
                                                <TableCell>Active</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.banners.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={4} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.banners.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                           <TableCell>
                                                               <img src={row.urlImage === null ? user : row.urlImage} alt='banner' className="img-thumb" />
                                                           </TableCell>
                                                            <TableCell>{row.link === null ? '' : row.link}</TableCell>
                                                            <TableCell>{row.title === 'true' ? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/homes/banner/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    <button className="btn-icon" type='button' onClick={() => this.handleOpen(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Hapus
                                                                    </button>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>

                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div className="row justify-content-end">
                                    <div className="col-md-6 text-right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => this.handleGo('/homes/gallery')}
                                        >
                                            <i className="fas fa-plus mr-2"> </i>
                                            Buat Gallery
                                        </Button>
                                    </div>
                                </div>
                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Media</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.galleries.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.galleries.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>
                                                                <img src={row.urlImage === null ? user : row.urlImage} alt='banner' className="img-thumb" />
                                                            </TableCell>
                                                            <TableCell>{row.title === null ? '' : row.title}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/homes/gallery/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                    <button className="btn-icon" type='button' onClick={() => this.handleOpen(row)}>
                                                                        <i className="fas fa-trash-alt"> </i> Hapus
                                                                    </button>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>

                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Media</TableCell>
                                                <TableCell>Link</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.videos.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.videos.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>
                                                                <img src={row.urlImage === null ? user : row.urlImage} alt='banner' className="img-thumb" />
                                                            </TableCell>
                                                            <TableCell>{row.link === null ? '' : row.link}</TableCell>
                                                            <TableCell className='action-menu '>
                                                                <button className="btn-icon">
                                                                    <i className="fas fa-ellipsis-v"> </i>
                                                                </button>
                                                                <div className='action-wrap'>
                                                                    <button className="btn-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                history.push({
                                                                                    pathname: "/homes/video/" + row.id,
                                                                                    state: row,
                                                                                });
                                                                            }}>
                                                                        <i className="fas fa-pencil-alt"> </i> Ubah
                                                                    </button>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                </div>
                            </TabPanel>
                        </form>

                    </div>

                    <Dialog
                        maxWidth='sm'
                        fullWidth={true}
                        open={this.state.showDialog}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">KONFIRMASI!</DialogTitle>
                        <DialogContent>
                            <p>Apakah anda yakin akan menghapus media?</p>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-blue-trans" onClick={this.handleClose}>
                                Batal
                            </button>
                            <button className="btn btn-blue" onClick={this.handleDelete}>
                                Hapus
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>


                <ToastContainer />

            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}
export default Medias;
