import React,{ Component } from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import Logo from '../../../images/logo.png';
import store from '../../../images/menu/store.svg';
import notif from '../../../images/menu/notif.svg';
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import moment from "moment/moment";

class Navbar extends Component {

    constructor(props) {

        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    handleToggleSidebar(){

        if(this.props.toggle_sidebar_state){

            this.props.setHideSidebar();

        }else{

            this.props.setShowSidebar();

        }

    }

    handleGo  = (link) => {

        history.push(link);
    };

    render() {
        const date = Date.now();
        return(

            <header className="header-nav ">
                <nav className="navbar justify-content-end">
                    <Link to="#" className="navbar-brand m-0 mr-auto p-0 d-lg-none">
                        <img src={Logo} alt='gudangemas' className='img-fluid' />
                    </Link>

                    <div className="date d-none d-lg-block">
                        <p className="mb-0 mr-3">{moment(date).format('DD MMM YYYY, dddd')}</p>
                    </div>

                    <button
                        onClick={() => this.handleGo('/settings/config/2')}
                        className="btn-icon text"
                    >
                        <img src={store} alt='icon' className='icon-nav' />
                        <span style={{width:50, display:'block'}}>Tutup Toko</span>
                    </button>


                   {/* <button
                        onClick={() => this.handleGo('/')}
                        className="btn-icon"
                    >
                        <img src={notif} alt='icon' className='icon-nav' />
                        <label className='pop-red'> </label>
                    </button>*/}

                    <button
                        onClick={this.handleToggleSidebar}
                        className="btn-icon d-lg-none"
                    >
                        <i className="fas fa-ellipsis-v"> </i>
                    </button>
                </nav>
            </header>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);