import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Api from "../../../libraries/api";
import image from "../../../images/product.png";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {history} from "../../../shared/configure-store";
import moment from "moment/moment";
import queryString from 'query-string';
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();

class Products extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            active: true,
            errors: {},

            cat: '',
            sub: '',
            orderBy: 'id',
            sortedBy: '',
            searchBy: '',

            categories: [],
            subcategories: [],

            rows: [],
            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Produk';

        let profile = AuthHelper.getProfile();
        let url = this.props.location.search;
        let urlParams = queryString.parse(url);
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Daftar Produk');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        Api.get('/product-category/all-active').then(resp => {

            if (resp.data) {

                this.setState({
                    categories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/product-sub-category/all-active').then(resp => {

            if (resp.data) {

                this.setState({
                    subcategories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        if(urlParams) {
            this.setState({
                cat: urlParams.cat ? urlParams.cat : '',
                sub: urlParams.sub ? urlParams.sub : '',
                search: urlParams.s ? urlParams.s : '',
            },() => {
                if(this.state.cat) {
                    this.__fetchSub();
                }
                this.__fetchData(true);
            });
        }else {
            this.__fetchData(false);
        }

        this.__fetchData(false);

    }

    componentWillReceiveProps() {
        setTimeout(function () {
            let url = this.props.location.search;
            let urlParams = queryString.parse(url);

            if(urlParams) {
                this.setState({
                    cat: urlParams.cat ? urlParams.cat : '',
                    sub: urlParams.sub ? urlParams.sub : '',
                    search: urlParams.s ? urlParams.s : '',
                },() => {
                    if(this.state.cat) {
                        this.__fetchSub();
                    }
                    this.__fetchData(true);
                });
            }else {
                this.__fetchData(true);
            }

        }.bind(this), 1);
    }

    __fetchData = update => {
        this.setState({
                loading: true
            }
        );
        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            size: this.state.perPage,
            ['search-by']: this.state.searchBy,
            ['category-id']: this.state.cat,
            ['sub-category-id']: this.state.sub,
            sort: [
                {
                    'column-name': this.state.orderBy,
                    'is-ascending': this.state.sortedBy === 'asc' ? true : false,
                }
            ],
        };

        Api.post('/product/view', params).then(resp => {
            let now = moment(Date.now());

            if(resp.data) {
                let products = resp.data.viewProducts.map(o => {
                    let start = moment(o['sale-start-date'],'DD/MM/YYYY');
                    let end = moment(o['sale-end-date'],'DD/MM/YYYY');
                    if(o !== null) {
                        console.log(o)
                        return {
                            id: o.id,
                            sku: o.sku,
                            image: o.image,
                            stock: parseFloat(o.stock),
                            rating: parseFloat(o.rating),
                            weight: o.weight,
                            isActive: o.isActive,
                            idProduct: o['id-product'],
                            category: o['category-name'],
                            sub: o['sub-catgeory-name'],
                            name: o['product-name'],
                            price: o['regular-price'],
                            productType: o['product-type-name'],
                            priceSale: o['sale-price'],
                            isSale: moment(now).isBetween(start, end),
                            startSale: o['sale-start-date'],
                            endSale: o['sale-end-date'],
                            quantity: 0,
                        }
                    }

                });
                this.setState({
                        rows: products,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    __fetchSub = () => {
        Api.get('/product-sub-category/category-id/'+this.state.cat).then(resp => {

            if (resp.data) {

                this.setState({
                    subcategories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleSearch = () => {
        this.__fetchData(true);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(true);
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleCat (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchData(true);
            if(prop === 'cat') {
                window.location.replace('/products?cat='+this.state.cat);
                this.__fetchSub();

            }else {
                window.location.replace('/products?cat='+this.state.cat+'&sub='+this.state.sub);
            }

        });

    };

    handleOpen = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.idProduct,
            currDelete: row.name,
            active: row.isActive,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.idDelete) {
            let params = {
                listId: [this.state.idDelete]
            };

            Api.delete('/product', params).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Produk berhasil dihapus');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo  = (link) => {
        history.push(link);
    };

    handleActive = () => {
        if(this.state.idDelete) {
            let params = {
                "id": this.state.idDelete,
                "status": true
            };

            Api.put('/product/update-status', params).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Produk berhasil kembali aktif');
                this.__fetchData(false);

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

            });
        }
    }

    handleExport = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/product/export/excel','blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'products.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };


    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Produk</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Produk</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Daftar Produk</h2>
                            </div>
                            <div className="col-6 text-right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='mr-2'
                                    onClick={() => this.handleExport(true)}
                                    type='button'
                                >
                                    <i className="fas fa-file-excel mr-2"> </i>
                                    Export {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => this.handleGo('/products/add')}
                                >
                                    <i className="fas fa-plus mr-2"> </i>
                                    Buat Produk
                                </Button>
                                }

                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between content-filter">
                            <div className="col-md-4 mb-2 mb-md-0">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    className="search-field"
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder="Search here"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="Search click"
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className="fas fa-search"> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3 text-md-right ml-auto">
                                <TextField variant="outlined"
                                           select
                                           id="cat"
                                           name="cat"
                                           label="Kategori"
                                           onChange={(e) => this.handleCat(e, 'cat')}
                                           value={this.state.cat}
                                           fullWidth
                                >
                                    <MenuItem value=''>
                                        Semua
                                    </MenuItem>
                                    {this.state.categories.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="col-md-4 col-lg-3 text-md-right">
                                <TextField variant="outlined"
                                           select
                                           id="sub"
                                           name="sub"
                                           label="Tag"
                                           onChange={(e) => this.handleCat(e, 'sub')}
                                           value={this.state.sub}
                                           fullWidth
                                >
                                    <MenuItem value=''>
                                        Semua
                                    </MenuItem>
                                    {this.state.subcategories.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>

                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="clickable" onClick={() => this.handleSort('productName')} >Nama Produk
                                            {this.state.orderBy === 'productName' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('sku')} >SKU
                                            {this.state.orderBy === 'sku' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('stock')} >Stock
                                            {this.state.orderBy === 'stock' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('regularPrice')} >Harga
                                            {this.state.orderBy === 'regularPrice' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell>Categories</TableCell>
                                        <TableCell style={{width:75}}>Type</TableCell>
                                        <TableCell>Rating</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell style={{minWidth:100}}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={9} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>Produk kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row.id}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <div className='d-flex'>
                                                            <img src={row.image !== null ? row.image : image} alt='avatar' className="img-product mr-2" />
                                                            <span>{row.name === null ? '' : row.name}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{row.sku === null ? '' : row.sku}</TableCell>
                                                    <TableCell>{row.stock > 0 ? row.stock : row.stock}
                                                    <strong className={row.stock > 0 ? 'd-block in' : 'd-block out'}>{row.stock > 0 ? 'in stock' : 'out of stock'}</strong>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.isSale ?
                                                            <>
                                                            <p className='discount-price'>
                                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                            </p>
                                                                <p>
                                                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.priceSale} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </p>
                                                            </>
                                                            :
                                                            <p>
                                                                Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                            </p>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.category === null ? '' : row.category}
                                                        <span className='label-cat'>{row.sub === null ? '' : row.sub}</span>
                                                        </TableCell>
                                                    <TableCell>{row.productType === null ? '' : row.productType}</TableCell>
                                                    <TableCell>{row.rating === null ? '' : row.rating}</TableCell>
                                                    <TableCell>{row.isActive ? <i className="fas fa-check-circle icon-green"> </i> : <i className="fas fa-times-circle icon-red"> </i>}</TableCell>



                                                    <TableCell className='action-menu'>
                                                        <button className="btn-icon">
                                                            <i className="fas fa-ellipsis-v"> </i>
                                                        </button>
                                                        <div className='action-wrap'>
                                                            <Link to={"/products/edit/"+row['idProduct']} className="btn-icon mb-2">
                                                                <i className="fas fa-pencil-alt"> </i> Ubah
                                                            </Link>
                                                            {this.state.isManage &&
                                                            <button className="btn-icon"
                                                                    onClick={() => this.handleOpen(row)}>
                                                                {row.isActive ?
                                                                    <span><i
                                                                        className="fas fa-times-circle"> </i> Deactive</span>
                                                                    :
                                                                    <span>
                                                                        <i className="fas fa-check-circle"> </i>Active</span>
                                                                }
                                                            </button>
                                                            }
                                                        </div>

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">KONFIRMASI {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        <p>Apakah anda yakin akan menyimpan produk sebagai Draft?</p>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleClose}>
                            Batal
                        </button>
                        {this.state.active ?
                            <button className="btn btn-blue" onClick={this.handleDelete}>
                                Deactive
                            </button> :
                            <button className="btn btn-blue" onClick={this.handleActive}>
                                Active
                            </button>
                        }

                    </DialogActions>
                </Dialog>

                <ToastContainer />

            </div>
        )
    }
}

export default Products;

function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}