import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import NumberFormat from 'react-number-format';
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class UsersEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            levels: [],
            provinces: [],
            cities: [],
            subdistricts: [],

            id: '',
            code: '',
            firstName: '',
            lastName: '',
            name: '',
            username: '',
            email: '',
            phone: '',

            company: '',
            place: '',
            address: '',
            province: '',
            city: '',
            subdistrict: '',
            postalCode: '',
            type: '',

            level: '',
            gender: '',
            birth: null,
            role: '',
            photo: user,
            avatar: null,
            lastTransaction: '',
            lastUpdate: '',
            customerPoint: {},
            customerPointHistories: [],
            influencers: [],
            isManage: false,

            roleId: '',
            additional_roles: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,

            arows: [],
            atotal: 0,
            aperPage: 10,
            acurrentPage: 0,
            anextPage: 0,
            acurrentPageTable: 0,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Customer Detail';

        const id = this.props.match.params.id;
        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Customer');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        Api.get('/lookup/by-group-id/1').then(resp => {

            if (resp.data) {

                this.setState({
                        levels: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/customer/'+id).then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    id: id,
                    code: data.code,
                    firstName: data['first-name'] !== null ? data['first-name'] : '',
                    lastName: data['last-name'] !== null ? data['last-name'] : '',
                    name: data['full-name'] !== null ? data['full-name'] : '',
                    username: data.username ? data.username : '',
                    email: data.email,
                    gender: data.gender ? data.gender : '',
                    birth: data['birth-date'] !== null ? moment(data['birth-date'],'DD-MM-YYYY') : null,
                    phone: data['phone-number'] !== null ? data['phone-number'] : '',
                    address: data.address ?  data.address.address : '',
                    company: data.address ?  data.address.company : '',
                    place: data.address ?  data.address.place : '',
                    province: data.address ?  data.address.province && data.address.province.province_id : '',
                    city: data.address ?  data.address.city && data.address.city.id : '',
                    postalCode: data.address ?  data.address['postal-code'] && data.address['postal-code'] : '',
                    subdistrict: data.address ?  data.address.subDistrict && data.address.subDistrict.id : '',
                    photo: data.avatar === null ? user : data.avatar,
                    level: data['customer-type-id'],
                    lastTransaction: data['last-transaction-date'],
                    lastUpdate: data['last-update'],
                    customerPoint: data['customer-point'] !== null ? data['customer-point'] : [],
                    //customerPointHistories: data['customer-point-history'] !== null ? data['customer-point-history'] : [],
                    influencers: data.influencers !== null ? data.influencers : [],
                }, () => {
                    this.__fetchData();
                    this.__afetchData();
                    if(data.address) {
                        this.__fetchAddress();
                    }
                });
            }

        }).catch(err => {
            console.log(err);
        });

    }


    __fetchData = update => {

        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            'customer-code': this.state.code,
            size: this.state.perPage,
            'sort': [
                {
                    'column-name': 'transactionDate',
                    'is-ascending': false
                }
            ],
        };

        Api.post('/customer/point-history/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                    customerPointHistories: resp.data.customerPointHistories,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    __afetchData = update => {

        let page = update ? 0 : this.state.acurrentPage;

        let params = {
            page: page,
            'customer-code': this.state.code,
            size: this.state.aperPage,

            'sort': [
                {
                    'column-name': 'orderDate',
                    'is-ascending': false
                }
            ],
        };

        Api.post('/order/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        arows: resp.data.viewOrders,
                        atotal: resp.data.totalItems,
                        acurrentPageTable: resp.data.currentPage,
                        acurrentPage: resp.data.currentPage,
                        anextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    ahandleChangePage = (event, newPage) => {
        this.setState({
            arows: [],
            acurrentPage:newPage,
            acurrentPageTable:newPage,
            loading: true,
        }, () => {
            this.__afetchData(false);
        });
    };

    __fetchProvince = () => {
        Api.get('/province').then(resp => {

            if (resp.data) {

                this.setState({
                        provinces: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchAddress = () => {

        this.__fetchProvince();

        this.__fetchCity();

        this.__fetchSubdistrict();

    };

    __fetchCity = () => {

        Api.get('/city/'+this.state.province).then(resp => {

            if (resp.data) {

                this.setState({
                        cities: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    __fetchSubdistrict = () => {

        Api.get('/subdistrict/'+this.state.city).then(resp => {

            if (resp.data) {

                this.setState({
                        subdistricts: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleProvince (e, prop){

        this.setState({

            [prop]: e.target.value

        }, () => {
            this.__fetchCity();
        });

    };

    handleCity (e, prop){

        this.setState({

            [prop]: e.target.value

        }, () => {
            this.__fetchSubdistrict();
        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'birth-date': moment(this.state.birth).format('DD-MM-YYYY'),
            'first-name': this.state.firstName,
            'last-name': this.state.lastName,
            'email': this.state.email,
            'gender': this.state.gender,
            'phone-number': this.state.phone,
            'code': this.state.code,
            'avatar': this.state.avatar,
            'level-id': this.state.level,
        };

        let params2 = {
            'address': this.state.address,
            'city-id': this.state.city,
            'company': this.state.company,
            'place': this.state.place,
            'province-id': this.state.province,
            'customer-code': this.state.code,
            'subdistrict-id': this.state.subdistrict,
            'postal-code': this.state.postalCode,
        };


        Api.put('/customer', params).then(resp => {

            this.setState({
                loadingButton: false,
                }
            );

            this.showMessage(true, 'Customer berhasil diubah');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

        Api.put('/billing-address', params2).then(resp => {

            this.setState({
                    loadingButton: false,
                }
            );

            this.showMessage(true, 'Data alamat berhasil diubah');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });

    };


    handleGo  = (link) => {

        history.push(link);
    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                avatar: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleDeleteAvatar = () => {

        this.setState({
            photo: user,
            avatar: null
        });
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Detail Customer</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/customers" >Customers</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Detail Customer</h2>
                                <small>Last transaction: {this.state.lastTransaction}</small>
                            </div>
                            <div className="col-6 text-right">
                                <p className='mb-0'><strong>Referral Code: {this.state.code}</strong></p>
{/*
                                <small>Last update: {this.state.lastUpdate}</small><br />
*/}
                            </div>
                        </div>
                        <h3 className='section-title'>Informasi Pribadi</h3>

                        <form name="customer" id="editCustomer" className="row" noValidate>
                            <div className="col-12">


                                <div className="form-group">
                                    <img src={this.state.photo} alt="user" className="user-photo mr-2" />
                                    <TextField variant="outlined"
                                               type="file"
                                               id='avatar'
                                               name="avatar"
                                               className="avatar"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="avatar" className="label-file mr-2">Ubah Foto {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.avatar}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nama Depan</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='firstName'
                                               name="firstName"
                                               label="Nama Depan"
                                               onChange={(e) => this.handleChange(e, 'firstName')}
                                               value={this.state.firstName}
                                               fullWidth
                                    />
                                    {validator.message('firstName', this.state.firstName, 'required')}
                                    <div className='text-danger'>{this.state.errors.firstName}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nama Belakang</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='lastName'
                                               name="lastName"
                                               label="Nama Belakang"
                                               onChange={(e) => this.handleChange(e, 'lastName')}
                                               value={this.state.lastName}
                                               fullWidth
                                    />

                                    <div className='text-danger'>{this.state.errors.lastName}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Username</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='username'
                                               name="username"
                                               label="Username"
                                               value={this.state.username}
                                               fullWidth
                                    />
                                    {validator.message('username', this.state.username, 'required')}
                                    <div className='text-danger'>{this.state.errors.username}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tanggal Lahir</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            label="Tanggal Lahir"
                                            value={this.state.birth}
                                            onChange={(date) => this.handleDate(date, 'birth')}
                                            format={'DD-MM-YYYY'}
                                            cancelLabel="BATAL"
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                    {validator.message('birth', this.state.birth, 'required')}
                                    <div className='text-danger'>{this.state.errors.birth}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='email'
                                               name="email"
                                               label="Email"
                                               onChange={(e) => this.handleChange(e, 'email')}
                                               value={this.state.email}
                                               fullWidth
                                    />
                                    {validator.message('email', this.state.email, 'email|required')}
                                    <div className='text-danger'>{this.state.errors.email}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Jenis Kelamin</label>
                                    <TextField variant="outlined"
                                               select
                                               id="gender"
                                               name="gender"
                                               label="Jenis Kelamin"
                                               onChange={(e) => this.handleChange(e, 'gender')}
                                               value={this.state.gender}
                                               fullWidth
                                    >
                                        <MenuItem value="P">
                                            Perempuan
                                        </MenuItem>
                                        <MenuItem value="L">
                                            Laki - Laki
                                        </MenuItem>
                                    </TextField>
                                    {validator.message('gender', this.state.gender, 'required')}
                                    <div className='text-danger'>{this.state.errors.gender}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <TextField variant="outlined"
                                               type='number'
                                               id='phone'
                                               name="phone"
                                               label="Phone"
                                               onChange={(e) => this.handleChange(e, 'phone')}
                                               value={this.state.phone}
                                               fullWidth
                                    />
                                    {validator.message('phone', this.state.phone, 'required')}
                                    <div className='text-danger'>{this.state.errors.phone}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Level</label>
                                    <TextField variant="outlined"
                                               select
                                               id="level"
                                               name="level"
                                               label="Level"
                                               onChange={(e) => this.handleChange(e, 'level')}
                                               value={this.state.level}
                                               fullWidth
                                    >
                                        {this.state.levels.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('level', this.state.level, 'required')}
                                    <div className='text-danger'>{this.state.errors.level}</div>
                                </div>
                            </div>
                            {/*<div className="col-12">
                                <h3 className='section-title'>Kelola Password</h3>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <TextField variant="outlined"
                                               type={this.state.showPassword ? 'text' : 'password'}
                                               id="adornment-password"
                                               name="password"
                                               label="Password"
                                               onChange={(e) => this.handleChange(e,'password')}
                                               value={this.state.password}
                                               fullWidth
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="Toggle password visibility"
                                                               onClick={this.handleClickShowPassword}
                                                           >
                                                               {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                   <i className="fas fa-eye-slash"> </i>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   )
                                               }}
                                    />
                                    {validator.message('password', this.state.password, 'min:8')}
                                    <div className='text-danger'>{this.state.errors.password}</div>
                                </div>
                            </div>*/}

                            <div className="col-12">
                                <h3 className='section-title'>Alamat Penagihan</h3>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Company</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='company'
                                               name="company"
                                               label="Company"
                                               onChange={(e) => this.handleChange(e, 'company')}
                                               value={this.state.company}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.company}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Place</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='place'
                                               name="place"
                                               label="Place"
                                               onChange={(e) => this.handleChange(e, 'place')}
                                               value={this.state.place}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.place}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='address'
                                               name="address"
                                               label="Address"
                                               onChange={(e) => this.handleChange(e, 'address')}
                                               value={this.state.address}
                                               fullWidth
                                    />
                                    {validator.message('address', this.state.address, 'required')}
                                    <div className='text-danger'>{this.state.errors.address}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Provinsi</label>
                                    <TextField variant="outlined"
                                               select
                                               id="province"
                                               name="province"
                                               label="Provinsi"
                                               onChange={(e) => this.handleProvince(e, 'province')}
                                               value={this.state.province}
                                               fullWidth
                                    >
                                        {this.state.provinces.map(option => (
                                            <MenuItem key={option.province_id} value={option.province_id}>
                                                {option.province}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('province', this.state.province, 'required')}
                                    <div className='text-danger'>{this.state.errors.province}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Kota</label>
                                    <TextField variant="outlined"
                                               select
                                               id="city"
                                               name="city"
                                               label="Kota"
                                               onChange={(e) => this.handleCity(e, 'city')}
                                               value={this.state.city}
                                               fullWidth
                                    >
                                        {this.state.cities.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.type} {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('city', this.state.city, 'required')}
                                    <div className='text-danger'>{this.state.errors.city}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Kecamatan</label>
                                    <TextField variant="outlined"
                                               select
                                               id="subdistrict"
                                               name="subdistrict"
                                               label="Kecamatan"
                                               onChange={(e) => this.handleChange(e, 'subdistrict')}
                                               value={this.state.subdistrict}
                                               fullWidth
                                    >
                                        {this.state.subdistricts.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {validator.message('subdistrict', this.state.subdistrict, 'required')}
                                    <div className='text-danger'>{this.state.errors.subdistrict}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='postalCode'
                                               name="postalCode"
                                               label="Postal Code"
                                               onChange={(e) => this.handleChange(e, 'postalCode')}
                                               value={this.state.postalCode}
                                               fullWidth
                                    />
                                    {validator.message('postalCode', this.state.postalCode, 'required')}
                                    <div className='text-danger'>{this.state.errors.postalCode}</div>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <Button
                                    variant="contained"
                                    className="mr-3"
                                    onClick={() => this.handleGo('/customers')}
                                >
                                    Batal
                                </Button>
                                {this.state.permissions}
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton }
                                >
                                    Simpan {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Informasi Poin</h2>
                            </div>
                        </div>
                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total Poin Aktif</TableCell>
                                        <TableCell>Total Poin Digunakan</TableCell>
                                        <TableCell>Total Kadaluarsa</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><i className='icon-p'>P</i> <strong>{this.state.customerPoint ? this.state.customerPoint['total-point-active'] : 0}</strong></TableCell>
                                        <TableCell><i className='icon-p'>P</i> <strong>{this.state.customerPoint ? this.state.customerPoint['total-point-used'] : 0}</strong></TableCell>
                                        <TableCell><i className='icon-p'>P</i> <strong>{this.state.customerPoint ? this.state.customerPoint['total-point-expired'] : 0}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>

                        <div className="row align-items-center content-title mb-3">
                            <div className="col-6">
                                <h2 className='mb-0'>GE Best Friend</h2>
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <CircularProgress color="primary"  />
                                </div>
                            </div>
                        ) : (
                            this.state.influencers.length === 0 ? (
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <p>Data Kosong</p>
                                    </div>
                                </div>
                            ) : (
                                this.state.influencers.map((row,key) => (
                                    <div className="row" key={key}>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <p><i className="fas fa-people-arrows"> </i> <strong>Influencer {key+1} :</strong></p>
                                        </div>
                                        <div className="col-6">
                                            <p>{row.name} | <strong>{row.code}</strong></p>
                                        </div>
                                    </div>
                                ))
                            )
                        )}

                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Histori Poin</h2>
                            </div>
                        </div>
                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tgl. Transaksi</TableCell>
                                        <TableCell>Debit Poin</TableCell>
                                        <TableCell>Kredit Poin</TableCell>
                                        <TableCell>Balance</TableCell>
                                        <TableCell>Tgl. Kadaluarsa</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.customerPointHistories.length === 0 ? (
                                            <TableRow style={{ height: 10 * 5}}>
                                                <TableCell colSpan={5} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.customerPointHistories.map(row => (
                                                <TableRow key={row.id}
                                                          className={row['is-expired'] ? 'true' : ''}>
                                                    <TableCell>{row.trxDate === null ? '' : row.trxDate}</TableCell>
                                                    <TableCell>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.debitPoint} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.creditPoint} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat thousandSeparator={true} fixedDecimalScale={true} value={row.balance} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    </TableCell>
                                                    <TableCell>{row.expDate === null ? '' : row.expDate}</TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}



                                </TableBody>
                            </Table>
                        </div>

                        <div className='text-right col-12'>

                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={this.state.perPage}
                                page={this.state.currentPageTable}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>

                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Histori Order</h2>
                            </div>
                        </div>
                        <div className="table-list row">


                            <Table size="small" >
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={2} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.arows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={2} style={{ textAlign: "center" }}>Order kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.arows.map(row => (
                                                <TableRow
                                                    key={row['orderCode']}
                                                    selected={row.selected}
                                                >
                                                    <TableCell>
                                                        <div className='row align-items-center'>
                                                            <div className='col-12 text-left'>
                                                                <time>{row.orderDate}</time>
                                                            </div>
                                                            <div className='col-9 text-left'>
                                                                {/*<h4 className='mb-0'>{row.orderDate}</h4>*/}
                                                                <p className='mb-0'>No pesanan:
                                                                    <Link to={'/orders/edit/'+row.orderCode} >
                                                                    <strong className='text-blue'>{row.orderCode}</strong>
                                                                    </Link>
                                                                </p>
                                                                <p className='mb-0'>Total Belanja: <strong className='text-yellow'>Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.grandTotal ? row.grandTotal : 0} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                </strong></p>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{row.status === null ? '' : row.status}</TableCell>

                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                        <div className='text-right col-12'>

                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={this.state.atotal}
                                rowsPerPage={this.state.aperPage}
                                page={this.state.acurrentPageTable}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.ahandleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>
                    </div>
                </div>

                <ToastContainer />

            </div>
        )
    }
}

export default UsersEdit;