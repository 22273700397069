import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import {history} from "../../../shared/configure-store";
import SimpleReactValidator from "simple-react-validator";
import _ from 'lodash';
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();
const validatorPoint = new SimpleReactValidator();

class PointsSettings extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,

            level: '',
            month: '',
            customer: '',
            note: '',
            point: '',
            levels: [],
            rows: [],
            customers: [],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Pengaturan Poin';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Pengaturan Point');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        Api.get('/setting-point').then(resp => {

            if (resp.data) {
                this.setState({
                    levels: resp.data['level-details'] ? resp.data['level-details'] : [],
                    month: resp.data.period,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });


        Api.get('/customer/find-all-email').then(resp => {

            if (resp.data) {
                let data = resp.data.map(o=>{
                    return {
                        label: o,
                        value: o,
                    }
                });
                this.setState({
                        customers: data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

        this.__fetchData(false);
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };


    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleGo = (link) => {

        history.push(link);
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator
                isNumericString
            />
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            period: this.state.month,
            'level-details': this.state.levels,

        };

        Api.put('/setting-point', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Data berhasil diubah');

                this.setState({
                        loadingButton: false
                    }
                );
            }else {
                this.showMessage(true, 'Data gagal diubah');

            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loadingButton: false
                }
            );

        });

    };

    handleSend = (e) => {
        e.preventDefault();

        if (!validatorPoint.allValid()) {

            this.setState({
                    errors: validatorPoint.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            description: this.state.note,
            email: this.state.customer.value,
            point: this.state.point,

        };

        Api.post('/setting-point/addition-point', params).then(resp => {

            if(resp.status === 200) {
                this.showMessage(true, 'Poin berhasil dikirim');

                this.setState({
                        loadingButton: false
                    }
                );
            }else {
                this.showMessage(true, 'Poin gagal dikirim');

            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loadingButton: false
                }
            );

        });

    };

    handleChangPoint = (e, prop, id) => {
        let data = this.state.levels;

        _.forEach(data, function(v,k){
            if(v['level-id'] === id){
                v[prop] = e.target.value;
            }
        });

        this.setState({
            errors: {},
            levels: data,
        });
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    handleChangeCustomer (e, prop){

        this.setState({

            [prop]: e

        });

    };

    __fetchData = update => {
        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            size: this.state.perPage,
            sort: [
                {
                    'column-name': 'transactionDate',
                    'is-ascending': false,
                }
            ],
        };

        Api.post('/setting-point/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                        rows: resp.data.additionPoints,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleExport = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/setting-point/export/excel','blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'additional-point.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Pengaturan Poin</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Poin</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Pengaturan Poin</h2>
                            </div>

                            <div className="col-6 text-right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='mr-2'
                                    onClick={() => this.handleExport(true)}
                                    type='button'
                                >
                                    <i className="fas fa-file-excel mr-2"> </i>
                                    Export {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>
                            </div>
                        </div>
                        <form className='mt-3'>
                            <AppBar position="static">
                                <Tabs value={this.state.value}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      onChange={this.handleChangeTab} aria-label="produk tabs">
                                    <Tab label="Periode Poin" {...a11yProps(0)} />
                                    <Tab label="Penambahan Poin" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>

                                <div className="form-group row">
                                    <div className='col-sm-4'>
                                        <label className='label-form'>Periode (jumlah bulan)</label>
                                    </div>
                                    <div className='col-sm-8 col-lg-6'>
                                        <TextField variant="outlined"
                                                   type='number'
                                                   id='month'
                                                   name="month"
                                                   label="Jumlah Bulan"
                                                   onChange={(e) => this.handleChange(e, 'month')}
                                                   value={this.state.month}
                                                   fullWidth
                                        />
                                        {validator.message('month', this.state.month, 'required')}
                                        <div className='text-danger'>{this.state.errors.month}</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        this.state.levels.length > 0 &&
                                        this.state.levels.map(function (item, idx) {
                                            return <div className='col-md-6 form-group' key={idx}>
                                                <label>{item.level} </label>
                                                <TextField
                                                    variant="outlined"
                                                    type='text'
                                                    id='nominal'
                                                    name="nominal"
                                                    label={'Level '+item.level}
                                                    className='mb-2'
                                                    onChange={(e) => this.handleChangPoint(e, 'nominal', item['level-id'])}
                                                    value={item.nominal}
                                                    InputProps={{
                                                        inputComponent: this.NumberFormatCustom,
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                        }.bind(this))
                                    }
                                    <div className="col-12 text-center mt-3">
                                       {/* <Button
                                            className="mr-3"
                                            variant="contained"
                                            onClick={() => this.handleGo('/point-settings')}
                                        >
                                            Batal
                                        </Button>*/}
                                        {this.state.isManage &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton }
                                        >
                                            Simpan{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>}

                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <form className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Customer</label>
                                            <Select options = {this.state.customers}
                                                    onChange={(e) => this.handleChangeCustomer(e,'customer')}
                                                    value={this.state.customer}
                                            />
                                            {validatorPoint.message('customer', this.state.customer, 'required')}
                                            <div className='text-danger'>{this.state.errors.customer}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Poin yang akan ditambahkan</label>
                                            <TextField variant="outlined"
                                                       type='number'
                                                       id='point'
                                                       name="point"
                                                       label="Poin"
                                                       onChange={(e) => this.handleChange(e, 'point')}
                                                       value={this.state.point}
                                                       fullWidth
                                            />
                                            {validatorPoint.message('point', this.state.point, 'required')}
                                            <div className='text-danger'>{this.state.errors.point}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Keterangan</label>
                                            <TextField variant="outlined"
                                                       type='text'
                                                       id='note'
                                                       name="note"
                                                       label="Keterangan"
                                                       onChange={(e) => this.handleChange(e, 'note')}
                                                       value={this.state.note}
                                                       fullWidth
                                            />
                                            {validatorPoint.message('note', this.state.note, 'required')}
                                            <div className='text-danger'>{this.state.errors.note}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        {/* <Button
                                            className="mr-3"
                                            variant="contained"
                                            onClick={() => this.handleGo('/point-settings')}
                                        >
                                            Batal
                                        </Button>*/}
                                        {this.state.isManage &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSend}
                                            disabled={this.state.loadingButton }
                                        >
                                            Kirim{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                        </Button>
                                        }


                                    </div>
                                </form>

                                <div className="table-list row mt-3">
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Customer</TableCell>
                                                <TableCell>Poin</TableCell>
                                                <TableCell>Waktu Penambahan</TableCell>
                                                <TableCell>Expired</TableCell>
                                                <TableCell>Ket.</TableCell>
                                                <TableCell>Admin Pemberi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center" className="py-5">
                                                        <CircularProgress color="primary"  />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.rows.length === 0 ? (
                                                    <TableRow style={{ height: 33 * this.state.perPage}}>
                                                        <TableCell colSpan={6} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    this.state.rows.map(row => (
                                                        <TableRow
                                                            key={row.id}
                                                            selected={row.selected}>
                                                            <TableCell>
                                                                {row.username === null ? '' : row.username}<br/>
                                                                {row.email === null ? '' : row.email}</TableCell>
                                                            <TableCell>{row.point === null ? '' : row.point}</TableCell>
                                                            <TableCell>{row.extensionTime === null ? '' : row.extensionTime}</TableCell>
                                                            <TableCell>{row['addition-date'] === null ? '' : row['addition-date']}</TableCell>
                                                            <TableCell>{row.description === null ? '' : row.description}</TableCell>
                                                            <TableCell>{row.provider === null ? '' : row.provider}</TableCell>

                                                        </TableRow>
                                                    ))
                                                )
                                            )}

                                        </TableBody>
                                    </Table>

                                    <div className='text-right col-12'>

                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            count={this.state.total}
                                            rowsPerPage={this.state.perPage}
                                            page={this.state.currentPageTable}
                                            backIconButtonProps={{
                                                'aria-label': 'previous page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'next page',
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>

                                </div>



                            </TabPanel>
                        </form>

                    </div>


                </div>


                <ToastContainer />

            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}
export default PointsSettings;
