import React, {Component} from 'react';
import ContentDashboard from "../content-dashboard";
import NotFound from "../not-found";
import {Switch} from 'react-router-dom'
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Customers from "../customers";
import CustomersEdit from "../customers/edit";
import CustomersReset from "../customers/reset";
import Admins from "../admins";
import AdminsAdd from "../admins/add";
import AdminsEdit from "../admins/edit";
import Products from "../products";
import ProductsAdd from "../products/add";
import ProductsEdit from "../products/edit";
import ProductsImport from "../products/import";
import Orders from "../orders";
import OrdersEdit from "../orders/edit";
import Points from "../points";
import Settings from "../points/settings";
import Coupons from "../coupons";
import CouponsAdd from "../coupons/add";
import CouponsEdit from "../coupons/edit";
import Comments from "../comments";
import CommentsEdit from "../comments/edit";
import Pages from "../pages";
import PagesAdd from "../pages/add";
import PagesEdit from "../pages/edit";
import Blogs from "../blogs";
import BlogsAdd from "../blogs/add";
import BlogsEdit from "../blogs/edit";
import BlogsCategories from "../blogs/categories";
import EditBlogsCategories from "../blogs/editCategory";
import AddBlogsCategories from "../blogs/addCategory";
import Quiz from "../quizes";
import Setting from "../settings";
import SettingAdd from "../settings/add";
import SettingEdit from "../settings/edit";
import GeneralEdit from "../settings/general";
import Categories from "../categories";
import CategoriesAdd from "../categories/add";
import CategoriesEdit from "../categories/edit";
import SubCategories from "../subcategories";
import SubCategoriesAdd from "../subcategories/add";
import SubCategoriesEdit from "../subcategories/edit";
import AuthHelper from "../../../libraries/auth-helper";
import {PrivateRoute} from '../../../libraries/route-handling'
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import {history} from "../../../shared/configure-store";
import Menus from "../menus";
import MenusHeaders from "../menus/edit";
import MenusHeadersAdd from "../menus/add";
import MenusAddFooter from "../menus/addFooter";
import MenusEditHome from "../menus/editHome";
import MenusEditFooter from "../menus/editFooter";
import Homes from "../homes";
import HomesBanner from "../homes/banner";
import HomesBannerEdit from "../homes/edit";
import HomesGallery from "../homes/gallery";
import HomesGalleryEdit from "../homes/editGallery";
import HomesVideo from "../homes/video";
import Medias from "../medias";
import MediasAdd from "../medias/add";
import Abouts from "../abouts";
import Roles from "../roles";
import RolesAdd from "../roles/add";
import RolesEdit from "../roles/edit";

const styleSide = {
    maxWidth: '180px',
    background: '#fbfbfb'
};

const styleDashboard = {
    zIndex: '5',
    position: 'relative',
};

class Dashboard extends Component {

    /*constructor(props) {

        super(props);

    }*/

    componentDidMount() {
        if(AuthHelper.isLoggedIn()){
            this.props.setProfile(AuthHelper.getProfile());
        }else{
            history.push('/login');
        }
    }

    render() {
        return (

            <div>
                <Navbar />

                <main className="dashboard">

                    <div className="container-fluid">

                        <div className="row">

                            <div className="col-lg px-0" style={styleSide}>

                                <Sidebar/>

                            </div>

                            <div className="col-lg dashbboard-container" style={styleDashboard}>

                                <Switch>

                                    <PrivateRoute exact path="/" component={ContentDashboard}/>

                                    <PrivateRoute exact path="/customers" component={Customers}/>
                                    <PrivateRoute path="/customers/edit/:id" component={CustomersEdit}/>
                                    <PrivateRoute path="/customers/reset" component={CustomersReset}/>
                                    <PrivateRoute exact path="/admins" component={Admins}/>
                                    <PrivateRoute path="/admins/add" component={AdminsAdd}/>
                                    <PrivateRoute path="/admins/edit/:id" component={AdminsEdit}/>
                                    <PrivateRoute exact path="/products" component={Products}/>
                                    <PrivateRoute path="/products/add" component={ProductsAdd}/>
                                    <PrivateRoute path="/products/edit/:id" component={ProductsEdit}/>
                                    <PrivateRoute path="/products/import" component={ProductsImport}/>
                                    <PrivateRoute exact path="/orders" component={Orders}/>
                                    <PrivateRoute path="/orders/edit/:id" component={OrdersEdit}/>
                                    <PrivateRoute exact path="/points" component={Points}/>
                                    <PrivateRoute exact path="/point-settings" component={Settings}/>
                                    <PrivateRoute exact path="/coupons" component={Coupons}/>
                                    <PrivateRoute path="/coupons/add" component={CouponsAdd}/>
                                    <PrivateRoute path="/coupons/edit/:id" component={CouponsEdit}/>
                                    <PrivateRoute exact path="/comments" component={Comments}/>
                                    <PrivateRoute path="/comments/edit/:id" component={CommentsEdit}/>
                                    <PrivateRoute exact path="/pages" component={Pages}/>
                                    <PrivateRoute path="/pages/add" component={PagesAdd}/>
                                    <PrivateRoute path="/pages/edit/:id" component={PagesEdit}/>
                                    <PrivateRoute exact path="/categories" component={Categories}/>
                                    <PrivateRoute path="/categories/add" component={CategoriesAdd}/>
                                    <PrivateRoute path="/categories/edit/:id" component={CategoriesEdit}/>
                                    <PrivateRoute exact path="/sub-categories" component={SubCategories}/>
                                    <PrivateRoute path="/sub-categories/add" component={SubCategoriesAdd}/>
                                    <PrivateRoute path="/sub-categories/edit/:id" component={SubCategoriesEdit}/>
                                    <PrivateRoute exact path="/blogs" component={Blogs}/>
                                    <PrivateRoute path="/blogs/add" component={BlogsAdd}/>
                                    <PrivateRoute path="/blogs/edit/:id" component={BlogsEdit}/>
                                    <PrivateRoute exact path="/blogs-categories" component={BlogsCategories}/>
                                    <PrivateRoute path="/blogs-categories/add" component={AddBlogsCategories}/>
                                    <PrivateRoute exact path="/blogs-categories/:id" component={BlogsCategories}/>
                                    <PrivateRoute path="/blogs-categories/edit/:id" component={EditBlogsCategories}/>
                                    <PrivateRoute exact path="/quiz" component={Quiz}/>
                                    <PrivateRoute exact path="/settings" component={Setting}/>
                                    <PrivateRoute path="/settings/add" component={SettingAdd}/>
                                    <PrivateRoute path="/settings/edit/:id" component={SettingEdit}/>
                                    <PrivateRoute path="/settings/config/:id" component={GeneralEdit}/>
                                    <PrivateRoute exact path="/homes" component={Homes}/>
                                    <PrivateRoute exact path="/homes/banner" component={HomesBanner}/>
                                    <PrivateRoute path="/homes/banner/:id" component={HomesBannerEdit}/>
                                    <PrivateRoute exact path="/homes/gallery" component={HomesGallery}/>
                                    <PrivateRoute path="/homes/gallery/:id" component={HomesGalleryEdit}/>
                                    <PrivateRoute path="/homes/video/:id" component={HomesVideo}/>
                                    <PrivateRoute exact path="/menus" component={Menus}/>
                                    <PrivateRoute path="/menus/headers/add" component={MenusHeadersAdd}/>
                                    <PrivateRoute path="/menus/footers/add" component={MenusAddFooter}/>
                                    <PrivateRoute path="/menus/footers/:id" component={MenusEditFooter}/>
                                    <PrivateRoute path="/menus/headers/:id" component={MenusHeaders}/>
                                    <PrivateRoute path="/menus/homes/:id" component={MenusEditHome}/>
                                    <PrivateRoute exact path="/medias" component={Medias}/>
                                    <PrivateRoute path="/medias/add" component={MediasAdd}/>
                                    <PrivateRoute exact path="/abouts" component={Abouts}/>
                                    <PrivateRoute exact path="/roles" component={Roles}/>
                                    <PrivateRoute path="/roles/add" component={RolesAdd}/>
                                    <PrivateRoute path="/roles/edit/:id" component={RolesEdit}/>
                                    <PrivateRoute component={NotFound}/>
                                </Switch>

                            </div>

                        </div>

                    </div>

                </main>

            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

