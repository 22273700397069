import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateRangePicker } from "@shinabr2/react-material-daterange-picker";
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Api from "../../../libraries/api";
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {history} from "../../../shared/configure-store";
import ErrorHandling from "../../../libraries/error-handling";
import AuthHelper from "../../../libraries/auth-helper";
import moment from "moment/moment";

require('dotenv').config();

class Orders extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: null,

            startDate: null,
            endDate: null,

            statusToUpdate: '',
            current: '',
            orderCode: '',
            resi: '',

            status: null,
            statuses: [],
            rows: [],
            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Order';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Riwayat Order');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        this.__fetchData(false);

        Api.get('/lookup/by-group-id/5').then(resp => {

            if (resp.data) {

                this.setState({
                    statuses: resp.data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });
    }

    __fetchData = update => {
        this.setState({
                loading: true
            }
        );
        let page = update ? 0 : this.state.currentPage;

        let sort = [];
        if(this.state.orderBy !== '') {
            sort = [{
                'column-name': this.state.orderBy,
                'is-ascending': this.state.sortedBy === 'asc' ? true : false,
            }];
        }
        let params = {
            page: page,
            size: this.state.perPage,
            ['search-by']: this.state.searchBy,
            ['status']: this.state.status,
            ['start-date']: this.state.startDate,
            ['end-date']: this.state.endDate,
            sort: sort,
        };

        Api.post('/order/get-all', params).then(resp => {

            if(resp.data) {
                this.setState({
                    rows: resp.data.viewOrders,
                    total: resp.data.totalItems,
                    currentPageTable: resp.data.currentPage-0,
                    currentPage: resp.data.currentPage,
                    nextPage: resp.data.currentPage+1,
                    loading: false
                    }
                );
            }

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    setDateRange = (date, prop) => {

        this.setState({
            startDate: moment(date.startDate).format('DD/MM/YYYY'),
            endDate: moment(date.endDate).format('DD/MM/YYYY'),
            openDate: false,
        }, () => {
            this.__fetchData(false);
        })
    };

    filterStatus = () => {

    };

    handleSearch = () => {
        this.__fetchData(true);
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.__fetchData(true);
        }
    };

    openDate = (date, prop) => {

        this.setState({
            [prop]: !this.state[prop],
        });
    };


    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleChangeSelect (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchData(true);
        });

    };

    handleOpen = (prop, row) => {
        this.setState({
            showDialog :true,
            statusToUpdate: prop,
            orderCode: row['orderCode'],
            current: row['orderCode']+' oleh '+row['customerName']
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };

    handleDelete = () => {

        if(this.state.orderCode) {
            this.setState({
                loadingButton: true,
            });
            let params = {
                'status': this.state.statusToUpdate,
                'order-code': this.state.orderCode,
                'noResi': this.state.resi,
            };

            Api.put('/order/update-status', params).then(resp => {

                if(resp.status === 200) {
                    this.showMessage(true, 'Order status berhasil diubah');
                    this.__fetchData(false);
                }else {
                    this.showMessage(false, resp.message[0]);

                }

                this.setState({
                        loadingButton: false,
                        showDialog :false,
                    }
                );
            }).catch(err => {

                if(ErrorHandling.checkErrorTokenExpired(err)){

                }else{

                    this.setState({
                            errors: err.errors,
                            loadingButton: false
                        }
                    );

                    this.showMessage(false, 'Invalid format data');
                }
            });
        }
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleChangePage = (event, newPage) => {

        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };

    handleGo = (link) => {

        history.push(link);
    };

    handleExport = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        let params = {
            size: this.state.total,
            ['status']: this.state.status,
            ['start-date']: this.state.startDate,
            ['end-date']: this.state.endDate,
        };
/*
        if(this.state.startDate && this.state.endDate) {
            params = {
                size: this.state.perPage,
                ['status']: this.state.status,
                ['start-date']: this.state.startDate,
                ['end-date']: this.state.endDate,
            };
        }*/
        Api.postEx('/order/export/excel', params, 'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'orders.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };


    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Riwayat Order</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Order</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Daftar Riwayat Order</h2>
                            </div>
                            <div className="col-6 text-right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleExport(true)}
                                    type='button'
                                    disabled={this.state.startDate === null && this.state.endDate === null}
                                >
                                    <i className="fas fa-file-excel mr-2"> </i>
                                    Export {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                </Button>

                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between content-filter">
                            <div className="col-md-6 col-lg-4 mb-2 mb-md-0">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    className="search-field"
                                    onChange={(e) => this.handleChange(e, 'searchBy')}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleSearch}
                                    placeholder="Search here"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="Search click"
                                                    onClick={this.handleSearch}
                                                >
                                                    <i className="fas fa-search"> </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-md-6  col-lg-8 text-md-right filter-wrap">
                                <button className='btn btn-date btn-order'
                                        onClick={(e) => this.openDate(e,'openDate')}
                                        disabled={this.state.type === ''}
                                >
                                    {this.state.startDate ? this.state.startDate: 'mulai'} - {this.state.endDate ? this.state.endDate : 'akhir'}
                                    <i className="fas fa-calendar-week"> </i>
                                </button>

                                <TextField variant="outlined"
                                           select
                                           id="status"
                                           name="status"
                                           label="Status"
                                           onChange={(e) => this.handleChangeSelect(e, 'status')}
                                           value={this.state.status}
                                           fullWidth
                                >
                                    <MenuItem value=''>
                                        Semua
                                    </MenuItem>
                                    {this.state.statuses.map(option => (
                                        <MenuItem key={option.id} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <DateRangePicker
                                open={this.state.openDate}
                                onChange={(range) => this.setDateRange(range, 'dateRange')}
                            />
                        </div>

                        <div className="table-list row">
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell  className="clickable" onClick={() => this.handleSort('customerCode')}>Order
                                            {this.state.orderBy === 'customerCode' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('orderDate')} >Date
                                            {this.state.orderBy === 'orderDate' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}
                                        </TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell className="clickable" onClick={() => this.handleSort('grandTotal')}>Total
                                            {this.state.orderBy === 'grandTotal' && (
                                                <span className="icon-sort">{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className="fas fa-sort-up"> </i>
                                                    ) : (
                                                        <i className="fas fa-sort-down"> </i>
                                                    )
                                                }</span>
                                            )}</TableCell>
                                        <TableCell style={{minWidth:100}}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className="py-5">
                                                <CircularProgress color="primary"  />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.length === 0 ? (
                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                <TableCell colSpan={6} style={{ textAlign: "center" }}>Order kosong</TableCell>
                                            </TableRow>
                                        ) : (
                                            this.state.rows.map(row => (
                                                <TableRow
                                                    key={row['orderCode']}
                                                    selected={row.selected}>
                                                    <TableCell>
                                                        <Link to={"/orders/edit/"+row['orderCode']} className='link'>
                                                              {row['orderCode'] === null ? '' : row['orderCode']}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{row['customerName'] === null ? '' : row['customerName']}</TableCell>
                                                    <TableCell>{row.orderDate === null ? '' : row.orderDate}</TableCell>
                                                    <TableCell>{row.status === null ? '' : row.status}</TableCell>
                                                    <TableCell>Rp {row.grandTotal === null ? 0 :
                                                        <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} value={row.grandTotal} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                    }</TableCell>
                                                    <TableCell className='action-menu '>

                                                        <button className="btn-icon">
                                                            <i className="fas fa-ellipsis-v"> </i>
                                                        </button>

                                                        {(row.status !== 'Complete' && this.state.isManage && row.status !== 'Cancelled') &&
                                                        <div className='action-wrap order'>
                                                            < button className="btn-icon"
                                                                     onClick={() => this.handleOpen('SHIPPED', row)}>
                                                                <i className="fas fa-shipping-fast"> </i> Proses
                                                                Shipping
                                                            </button>
                                                            <button className="btn-icon"
                                                                    onClick={() => this.handleOpen('COMPLETE', row)}>
                                                                <i className="fas fa-check-circle"> </i> Complete Order
                                                            </button>
                                                        </div>
                                                        }

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    )}

                                </TableBody>
                            </Table>
                        </div>

                    </div>

                    <TablePagination
                        rowsPerPageOptions={[20]}
                        component="div"
                        count={this.state.total}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </div>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">UBAH STATUS!? </DialogTitle>
                    <DialogContent>
                        <p>Apakah anda yakin akan melakukan perubahan <strong>{this.state.current}</strong>?</p>
                        {this.state.statusToUpdate === 'SHIPPED' &&
                        <div className="text-left form-group">
                            <label>No Resi</label>
                            <TextField variant="outlined"
                                       type='text'
                                       id='resi'
                                       name="resi"
                                       label="Nomor"
                                       onChange={(e) => this.handleChange(e, 'resi')}
                                       value={this.state.resi}
                                       fullWidth
                            />
                        </div> }
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-blue-trans" onClick={this.handleClose}>
                            TIDAK
                        </button>
                        <button className="btn btn-blue"
                                disabled={this.state.loadingButton && 'disabled' }
                                onClick={this.handleDelete}>
                            YA { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                        </button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />

            </div>
        )
    }
}

export default Orders;
