import React,{Component} from 'react';
import photo from "../../../images/product.png";
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class CommentsEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            levels: [],
            provinces: [],
            cities: [],
            subdistricts: [],

            id: '',
            new: 0,
            ratingPoint: 0,
            title: '',
            description: '',
            status: '',

            customer: '',
            product: '',

            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Comments Detail';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Comments');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        const id = this.props.match.params.id;

        Api.get('/product-review/'+id).then(resp => {

            let data = resp.data;
            if (data) {
                this.setState({
                    id: id,
                    ratingPoint: data.ratingPoint ? parseFloat(data.ratingPoint) : 0,
                    title: data.title,
                    description: data.description,
                    customer: data['reviewer-name'],
                    product: data['product-name'],
                    status: data.status
                });
            }

        }).catch(err => {
            console.log(err);
        });

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };


    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {};
        let status = '';

        if(this.state.new === 1) {
            params = {
                "id": this.state.id,
                "is-approve": true,
                "is-pending": false,
                "is-spam": false
            };

            status = 'APPROVE';
        }else if(this.state.new === 2) {
            params = {
                "id": this.state.id,
                "is-approve": false,
                "is-pending": true,
                "is-spam": false
            };
            status = 'PENDING';
        }else {
            params = {
                "id": this.state.id,
                "is-approve": false,
                "is-pending": false,
                "is-spam": true
            };
            status = 'SPAM';
        }

        Api.put('/product-review', params).then(resp => {

            if(resp.status === 200) {
                this.setState({
                        loadingButton: false,
                        status: status,
                    }
                );
            }


            this.showMessage(true, 'Status berhasil diubah');

        }).catch(err => {

            if(ErrorHandling.checkErrorTokenExpired(err)){

            }else{

                this.setState({
                        errors: err.errors,
                        loadingButton: false
                    }
                );

                this.showMessage(false, 'Invalid format data');
            }
        });


    };

    handleGo  = (link) => {

        history.push(link);
    };


    render() {
        const items = [];

        for (let i = 1; i <= 5 ; i++) {
            items.push(
                <span className={i <= this.state.ratingPoint ? 'star-icon yes' : 'star-icon'}>
                    <i className="fas fa-star"> </i>
                </span>
            )
        }


        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Detail Comments</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/comments" >Comments</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Detail Comment</h2>
                            </div>
                            <div className="col-6 text-right">
                                <p className='mb-0'><strong>{this.state.status}</strong></p>

                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className='col-12 mb-3'>
                                <strong>Customer: {this.state.customer}</strong>
                            </div>
                            <div className='col-6 col-md-3 col-lg-2'>
                                <img src={photo} className='img-fluid mb-2' alt='product' />
                            </div>
                            <div className='col-6 col-md-3 col-lg'>
                                <h3>{this.state.product}</h3>
                                {items}
                                <h3>{this.state.title}</h3>
                                <p>{this.state.description}</p>
                            </div>
                            <div className='col-6 col-md-3 col-lg-3 border-left'>
                                <form>
                                    <div className="form-group">
                                        <label>Status</label>
                                        <TextField variant="outlined"
                                                   select
                                                   id="new"
                                                   name="new"
                                                   label="Status"
                                                   onChange={(e) => this.handleChange(e, 'new')}
                                                   value={this.state.new}
                                                   fullWidth
                                        >
                                            <MenuItem value={1}>Approve</MenuItem>
                                            <MenuItem value={2}>Pending</MenuItem>
                                            <MenuItem value={3}>Spam</MenuItem>
                                        </TextField>
                                        {validator.message('new', this.state.new, 'required')}
                                        <div className='text-danger'>{this.state.errors.new}</div>
                                    </div>
                                    {this.state.isManage &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className='full'
                                        onClick={this.handleSubmit}
                                        disabled={this.state.loadingButton }
                                    >
                                        Simpan { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                    </Button>
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />

            </div>
        )
    }
}

export default CommentsEdit;