import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import Chart from 'chart.js';
import { DateRangePicker } from "@shinabr2/react-material-daterange-picker";
import NumberFormat from 'react-number-format';
import moment from "moment/moment";
import Api from "../../../libraries/api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Button from "@material-ui/core/Button";


class ContentDashboard extends Component {
    constructor(props) {

        super(props);
        this.state = {
            totalTarget: 0,
            totalTargetValue: 0,
            targetEach: 0,
            totalTargetEach: 0,

            startMonth: moment().startOf('year').format('MM-YYYY'),
            endMonth: moment().format('MM-YYYY'),

            startDate: moment().startOf('year').format('DD-MM-YYYY'),
            endDate: moment().format('DD-MM-YYYY'),

            targetRevenue: [],
            dataRevenue: [],
            labelRevenue: [],
            targetPenjualan: [],
            dataPenjualan: [],
            labelPenjualan: [],
            targetPendapatan: [],
            dataPendapatan: [],
            labelPendapatan: [],
            dataGramAverage: [],
            labelGramAverage: [],
            dataPurhaseAverage: [],
            labelPurhaseAverage: [],

            favourites: [],

            dataTopItem: [],
            labelTopItem: [],

            dataMargin: [],
            labelMargin: [],

            resumes: [],

            openDate: false,

            dateRange: null,

            orderBy: '',
            sortedBy: '',
            cat: 0,
            categories: [],
            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };
    }

    componentDidMount() {
        document.title = 'Gudangemas - Dashboard';

        Api.get('/config/3').then(resp => {

            if (resp.data) {

                this.setState({
                        totalTarget: resp.data.value,
                    targetEach: resp.data.value ? parseFloat(resp.data.value)/30 : '',
                        loading: false
                }, () => {
                    this.__fetchPenjualan();
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/config/4').then(resp => {

            if (resp.data) {

                this.setState({
                        totalTargetValue: resp.data.value,
                    totalTargetEach: resp.data.value ? parseFloat(resp.data.value)/30 : '',
                        loading: false
                }, () => {
                    this.__fetchPendapatan();
                    this.__fetchRevenue();
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/product-category/all-active').then(resp => {

            if (resp.data) {

                this.setState({
                        categories: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/dashboard/find-resume-sales').then(resp => {

            if (resp.data) {

                this.setState({
                        resumes: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/dashboard/find-fav-product').then(resp => {

            if (resp.data) {

                this.setState({
                    favourites: resp.data,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

        this.__fetchGramAverage();
        this.__fetchPurchaseAverage();
        this.__fetchTopItem();
        this.__fetchMargin();
        this.__fetchData(true);

    }

    openDate = (date, prop) => {

        this.setState({
            [prop]: !this.state[prop],
        });
    };

    __fetch = () => {
        this.buildChartPendapatan()
    };

    setDateRange = (date, prop) => {

        this.setState({
            startDate: moment(date.startDate).format('DD-MM-YYYY'),
            endDate: moment(date.endDate).format('DD-MM-YYYY'),
            openDate: false,
        }, () => {
            this.__fetchPenjualan();
            this.__fetchPendapatan();
            this.__fetchGramAverage();
            this.__fetchPurchaseAverage();
            this.__fetchTopItem();
            this.__fetchMargin();
        })
    };

    handleCat (e, prop){

        this.setState({

            [prop]: e.target.value

        },() => {
            this.__fetchPenjualan();
            this.__fetchPendapatan();
            this.__fetchGramAverage();
            this.__fetchPurchaseAverage();
            this.__fetchTopItem();
            this.__fetchMargin();

        });

    };

    __fetchPenjualan = () => {
        Api.get('/dashboard/find-total-sales/'+this.state.startDate+'/'+this.state.endDate+'/'+this.state.cat).then(resp => {
            let target = [];
            let i;
            if(resp.data.data) {
                for (i = 0; i < resp.data.data.length; i++) {
                    target.push(this.state.targetEach)
                }
            }
            if (resp.data) {
                this.setState({
                        dataPenjualan: resp.data.data,
                        labelPenjualan: resp.data.label,
                        targetPenjualan: target,
                        loading: false
                }, () => {
                    this.buildChartPenjualan()
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchPendapatan = () => {
        Api.get('/dashboard/find-total-income/'+this.state.startDate+'/'+this.state.endDate+'/'+this.state.cat).then(resp => {
            if (resp.data) {
                let target = [];
                let i;
                if(resp.data.data) {
                    for (i = 0; i < resp.data.data.length; i++) {
                        target.push(this.state.totalTargetEach)
                    }
                }

                this.setState({
                    dataPendapatan: resp.data.data,
                    labelPendapatan: resp.data.label,
                    targetPendapatan: target,
                    loading: false
                }, () => {
                    this.buildChartPendapatan();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchRevenue = () => {
        Api.get('/dashboard/find-revenue').then(resp => {
            if (resp.data) {
                let target = [];
                let i;
                if(resp.data.data) {
                    for (i = 0; i < resp.data.data.length; i++) {
                        target.push(this.state.totalTargetEach)
                    }
                }

                this.setState({
                    dataRevenue: resp.data.data.revenue,
                    labelRevenue: resp.data.label,
                    targetRevenue: resp.data.data.revenueAccumulative,
                    loading: false
                }, () => {
                    this.buildChartRevenue();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchGramAverage = () => {
        Api.get('/dashboard/find-avg-price-per-gram/'+this.state.startMonth+'/'+this.state.endMonth+'/'+this.state.cat).then(resp => {
            if (resp.data) {
                this.setState({
                    dataGramAverage: resp.data.data,
                    labelGramAverage: resp.data.label,
                    loading: false
                }, () => {
                    this.buildChartAverage();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchPurchaseAverage = () => {
        Api.get('/dashboard/find-purchase-avg/'+this.state.startMonth+'/'+this.state.endMonth+'/'+this.state.cat).then(resp => {
            if (resp.data) {
                this.setState({
                    dataPurhaseAverage: resp.data.data,
                    labelPurhaseAverage: resp.data.label,
                    loading: false
                }, () => {
                    this.buildChartAveragePembelian();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchTopItem = () => {
        Api.get('/dashboard/find-top-sell-item/'+this.state.startMonth+'/'+this.state.cat).then(resp => {
            if (resp.data) {
                this.setState({
                    dataTopItem: resp.data.data,
                    labelTopItem: resp.data.label,
                    loading: false
                }, () => {
                    this.buildChartPie();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    __fetchMargin = () => {
        Api.get('/dashboard/find-margin/'+this.state.startDate+'/'+this.state.endDate+'/'+this.state.cat).then(resp => {
            if (resp.data) {
                this.setState({
                    dataMargin: resp.data.data,
                    labelMargin: resp.data.label,
                    loading: false
                }, () => {
                    this.buildChartMargin();
                });
            }

        }).catch(err => {
            console.log(err);
        });
    };

    buildChartPenjualan = () => {
        let data = {
            labels: this.state.labelPenjualan,
            datasets: [{
                label: 'Penjualan',
                data: this.state.dataPenjualan,
                backgroundColor: '#36a1eb',
                borderColor: '#36a1eb',
                borderWidth: 1
            }
            ]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        }
                    }
                }]
            },
        };

        if(window.chartBarPenjualan != undefined){
            window.chartBarPenjualan.destroy();
        }

        let ctxHBar = document.getElementById("bar_penjualan");
        window.chartBarPenjualan = new Chart(ctxHBar, {
            type: 'bar',
            data: data,
            options: options
        });

    };

    buildChartRevenue = () => {
        console.log(this.state.dataRevenue)
        let data = {
            labels: this.state.labelRevenue,
            datasets: [{
                label: 'Revenue',
                data: this.state.dataRevenue,
                backgroundColor: 'transparent',
                borderColor: '#36a1eb',
                borderWidth: 2
            },
                {
                    label: 'Revenue Akumulatif',
                    data: this.state.targetRevenue,
                    backgroundColor: 'transparent',
                    borderColor: '#ff6384',
                    borderWidth: 2
                }
            ]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return 'Rp ' + value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return 'Rp ' + value;
                            }
                        }
                    }
                }]
            },

        };

        let ctxHBar = document.getElementById("bar_revenue");
        window.chartBarRevenue = new Chart(ctxHBar, {
            type: 'line',
            data: data,
            options: options
        });

    };

    buildChartPendapatan = () => {
        let data = {
            labels: this.state.labelPendapatan,
            datasets: [{
                label: 'Pendapatan',
                data: this.state.dataPendapatan,
                backgroundColor: 'transparent',
                borderColor: '#36a1eb',
                borderWidth: 2
            }
            ]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return 'Rp ' + value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return 'Rp ' + value;
                            }
                        }
                    }
                }]
            },

        };

        if(window.chartBarPendapatan != undefined){
            window.chartBarPendapatan.destroy();
        }

        let ctxHBar = document.getElementById("bar_pendapatan");
        window.chartBarPendapatan = new Chart(ctxHBar, {
            type: 'line',
            data: data,
            options: options
        });

    };

    buildChartAverage = () => {
        let data = {
            labels: this.state.labelGramAverage,
            datasets: [{
                label: 'Gram',
                data: this.state.dataGramAverage,
                backgroundColor: 'transparent',
                borderColor: '#36a1eb',
                borderWidth: 2
            }]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return 'Rp ' + value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            // Convert the number to a string and splite the string every 3 charaters from the end
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return 'Rp ' + value;
                            }
                        }
                    }
                }]
            }
        };

        if(window.chartAverage != undefined){
            window.chartAverage.destroy();
        }
        let ctxHBar = document.getElementById("average_weight");
        window.chartAverage = new Chart(ctxHBar, {
            type: 'line',
            data: data,
            options: options
        });

    };

    buildChartAveragePembelian = () => {
        let data = {
            labels: this.state.labelPurhaseAverage,
            datasets: [{
                label: 'Rata rata',
                data: this.state.dataPurhaseAverage,
                backgroundColor: 'transparent',
                borderColor: '#36a1eb',
                borderWidth: 2
            },
            ]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return value;
                            }
                        }
                    }
                }]
            }
        };

        if(window.chartAveragePembelian != undefined){
            window.chartAveragePembelian.destroy();
        }

        let ctxHBar = document.getElementById("average_pembelian");
        window.chartAveragePembelian =  new Chart(ctxHBar, {
            type: 'line',
            data: data,
            options: options
        });

    };

    buildChartPie = () => {
        let data = {
            labels: this.state.labelTopItem,
            datasets: [{
                data: this.state.dataTopItem,
                backgroundColor: ['#36a1eb','#ff6384','#ff9123','#ffcd56','#4bc0c0','#4e4bc0','#0ca6cc', '#9552EA', '#FFEC21', '#F54F52', '#93F03B'],
                borderColor: ['#36a1eb','#ff6384','#ff9123','#ffcd56','#4bc0c0','#4e4bc0','#0ca6cc', '#9552EA', '#FFEC21', '#F54F52', '#93F03B'],
                borderWidth: 1
            }]
        };
        let options = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        let text = data.labels[tooltipItem.index] + ': ';
                        if (parseInt(value) >= 1000) {
                            return text + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return text + value;
                        }
                    }
                }
            },
        };

        if(window.chartPie != undefined){
            window.chartPie.destroy();
        }

        let ctxHBar = document.getElementById("top_sell");
        window.chartPie = new Chart(ctxHBar, {
            type: 'doughnut',
            data: data,
            options: options
        });

    };

    buildChartMargin = () => {
        let data = {
            labels: this.state.labelMargin,
            datasets: [{
                label: 'Margin',
                data: this.state.dataMargin,
                backgroundColor: 'transparent',
                borderColor: '#36a1eb',
                borderWidth: 2
            },
            ]
        };

        let options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if(parseInt(value) >= 1000){
                            return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return 'Rp ' + value;
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        offsetGridLines: false
                    },
                    ticks: {
                        userCallback: function(value, index, values) {
                            value = value.toString();
                            if(parseInt(value) >= 1000){
                                return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return 'Rp ' + value;
                            }
                        }
                    }
                }]
            }
        };

        if(window.chartMargin != undefined){
            window.chartMargin.destroy();
        }
        let ctxHBar = document.getElementById("bar_margin");
        window.chartMargin = new Chart(ctxHBar, {
            type: 'line',
            data: data,
            options: options
        });

    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if(val !== this.state.orderBy){
            sortedBy = 'asc';
        }else{
            if(sortedBy && sortedBy === 'asc' ){
                sortedBy = 'desc';
            }else{
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this.__fetchData(false);
        });
    };

    __fetchData = update => {

        let page = update ? 0 : this.state.currentPage;

        let params = {
            page: page,
            size: this.state.perPage,
        };

        if(this.state.orderBy) {
            params = {
                page: page,
                size: this.state.perPage,
                sort: [
                    {
                        'column-name': this.state.orderBy,
                        'is-ascending': this.state.sortedBy === 'asc' ? true : false,
                    }
                ],
            };
        }

        Api.post('/dashboard/find-loyal-customer', params).then(resp => {

            if (resp.data) {

                this.setState({
                        rows: resp.data.viewLoyalCustomers,
                        total: resp.data.totalItems,
                        currentPageTable: resp.data.currentPage,
                        currentPage: resp.data.currentPage,
                        nextPage: resp.data.currentPage+1,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };
    handleExportRevenue = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-revenue/export/excel','blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'revenue.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportPenjualan = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-total-sales/export/excel/'+this.state.startDate+'/'+this.state.endDate,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'total-penjualan.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportPendapatan = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-total-income/export/excel/'+this.state.startDate+'/'+this.state.endDate,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'total-pendapatan.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportAverage = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-avg-price-per-gram/export/excel/'+this.state.startMonth+'/'+this.state.endMonth,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'rata-rata-gram.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportPurchase = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-purchase-avg/export/excel/'+this.state.startMonth+'/'+this.state.endMonth,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'rata-rata-pembelian.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportItem = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-top-sell-item/export/excel/'+this.state.startMonth,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'top-sell.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportMargin = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-margin/export/excel/'+this.state.startDate+'/'+this.state.endDate,'blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'margin.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    handleExportCustomer = ()  => {

        this.setState({
                loadingButton: true
            }
        );
        Api.get('/dashboard/find-loyal-customer/export/excel','blob').then(res=>{
            res.blob().then(resp => {

                let url = window.URL.createObjectURL(resp);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'loyal.xlsx';
                a.click();
            });
            this.setState({
                loadingButton:false
            })
        });

    };
    render() {

        return (

            <div className="row main-content">
                <div className="col-12">
                    <h2 className="page-title">Dashboard</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">Home</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-12 dashboard">
                    <div className='row mt-3 mt-md-4'>
                        {/*<div className='col-md-6'>
                            <div className='card-history mb-2'>
                                <p>Target
                                    Penjualan</p>
                                <h5 className='big-num mb-0'>
                                    <NumberFormat thousandSeparator={true} decimalScale={false} fixedDecimalScale={true} value={this.state.totalTarget} displayType={'text'} renderText={value => <span>{value}</span>}/> Gram
                                </h5>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='card-history blue mb-2'>
                                <p>Target
                                    Penghasilan</p>
                                <h5 className='big-num mb-0'>
                                    Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={this.state.totalTargetValue} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                </h5>
                            </div>
                        </div>*/}
                        {/*  <div className='col-12 text-right my-3'>
                            <strong>Filter Tanggal: </strong>
                            <button className='btn btn-date'
                                    onClick={(e) => this.openDate(e,'openDate')}
                                    disabled={this.state.type === ''}
                            >
                                {this.state.startDate ? moment(this.state.startDate).format('DD MMM YYYY') : 'mulai'} - {this.state.endDate ? moment(this.state.endDate).format('DD MMM YYYY') : 'akhir'}
                                <i className="fas fa-calendar-week"> </i>
                            </button>
                            <DateRangePicker
                                open={this.state.openDate}
                                onChange={(range) => this.setDateRange(range, 'dateTransaction')}
                            />

                        </div>*/}

                        <div className='col-12'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Pencatatan Revenue</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportRevenue(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="bar_revenue"> </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className='card-dashboard'>
                                <h3>Resume Sales</h3>

                                {this.state.resumes.length === 0 ? (
                                        <div className='row table-sales'>
                                            <div className='col'>
                                                <p>no data</p>
                                            </div>
                                        </div>
                                    ) :
                                    <div className='row table-sales'>
                                        {this.state.resumes.map((row,id) => (
                                            <div className='col-2' key={id}>
                                                <div className='head'>
                                                    <p>{row.Header}</p>
                                                </div>
                                                <div className='body'>
                                                    {row.Values.map((item,idx) => {
                                                        if(idx !== 3 && idx !== 4) {
                                                            return <div className='item' key={idx}>
                                                                {id === 0 ?
                                                                    <p>{item}</p> :
                                                                    (idx === 8 || idx === 9 || idx === 6) ?
                                                                        <p>{item}</p> :
                                                                        <p>Rp <NumberFormat thousandSeparator={true}
                                                                                            fixedDecimalScale={false}
                                                                                            value={item}
                                                                                            displayType={'text'}
                                                                                            renderText={value =>
                                                                                                <span>{value}</span>}/>
                                                                        </p>
                                                                }
                                                            </div>
                                                        }}
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>
                        </div>

                        <div className='col-12 dashbboard-container' style={{maxWidth:'none'}}>
                            <div className='card-dashboard '>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Jumlah Produk Terlaku</h3>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <div className="table-list">
                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Periode</TableCell>
                                                        <TableCell>Nama Produk Terlaku</TableCell>
                                                        <TableCell>Keping</TableCell>
                                                        <TableCell>Rupiah</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.loading ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center" className="py-5">
                                                                <CircularProgress color="primary"  />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        this.state.favourites.length === 0 ? (
                                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                                <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            this.state.favourites.map((row,id) => (
                                                                <TableRow
                                                                    key={id}>
                                                                    <TableCell>{row.rangeTime === null ? '' : row.rangeTime}</TableCell>
                                                                    <TableCell>{row.productName === null ? '' : row.productName}</TableCell>
                                                                    <TableCell>{row.quantity === null ? '' : row.quantity}</TableCell>
                                                                    <TableCell>
                                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        )
                                                    )}

                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 mb-2'>
                            <div className='filter-wrap'>
                                <label>FILTER: </label>
                                <div className='filter-cat'>
                                    <TextField variant="outlined"
                                               select
                                               id="cat"
                                               name="cat"
                                               label="Kategori"
                                               onChange={(e) => this.handleCat(e, 'cat')}
                                               value={this.state.cat === 0 ? '' : this.state.cat}
                                               fullWidth
                                    >
                                        {this.state.categories.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <button className='btn btn-date'
                                        onClick={(e) => this.openDate(e,'openDate')}
                                        disabled={this.state.type === ''}
                                >
                                    {this.state.startDate ? this.state.startDate: 'mulai'} - {this.state.endDate ? this.state.endDate : 'akhir'}
                                    <i className="fas fa-calendar-week"> </i>
                                </button>
                                <DateRangePicker
                                    open={this.state.openDate}
                                    onChange={(range) => this.setDateRange(range, 'dateRange')}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>

                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Total Item Terjual</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportPenjualan(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="bar_penjualan"> </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Total Pendapatan</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportPendapatan(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"> </i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="bar_pendapatan"> </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Rata-rata harga per gram</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportAverage(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"> </i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="average_weight"> </canvas>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Rata-rata Pembelian</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportPurchase(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="average_pembelian"> </canvas>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Item Terlaku</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportItem(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="top_sell"> </canvas>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card-dashboard'>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Margin</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportMargin(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <canvas id="bar_margin"> </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 dashbboard-container' style={{maxWidth:'none'}}>
                            <div className='card-dashboard '>
                                <div className='row header'>
                                    <div className='col-6'>
                                        <h3>Loyal Customer</h3>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            className='btn-export'
                                            onClick={() => this.handleExportCustomer(true)}
                                            type='button'
                                        >
                                            <i className="fas fa-file-excel mr-2"></i>
                                            {this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i>}
                                        </button>
                                    </div>
                                </div>
                                <div className='row body'>
                                    <div className='col-12'>
                                        <div className="table-list">
                                            <Table size="small" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nama</TableCell>
                                                        <TableCell className="clickable" onClick={() => this.handleSort('price')}>Total Pembelian (Rp)
                                                            {this.state.orderBy === 'price' && (
                                                                <span className="icon-sort">{
                                                                    this.state.sortedBy === 'asc' ? (
                                                                        <i className="fas fa-sort-up"> </i>
                                                                    ) : (
                                                                        <i className="fas fa-sort-down"> </i>
                                                                    )
                                                                }</span>
                                                            )}</TableCell>
                                                        <TableCell className="clickable" onClick={() => this.handleSort('weight')}>Total Pembelian (gram)
                                                            {this.state.orderBy === 'weight' && (
                                                                <span className="icon-sort">{
                                                                    this.state.sortedBy === 'asc' ? (
                                                                        <i className="fas fa-sort-up"> </i>
                                                                    ) : (
                                                                        <i className="fas fa-sort-down"> </i>
                                                                    )
                                                                }</span>
                                                            )}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.loading ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center" className="py-5">
                                                                <CircularProgress color="primary"  />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        this.state.rows.length === 0 ? (
                                                            <TableRow style={{ height: 33 * this.state.perPage}}>
                                                                <TableCell colSpan={3} style={{ textAlign: "center" }}>Data kosong</TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            this.state.rows.map((row,id) => (
                                                                <TableRow
                                                                    key={id}
                                                                    selected={row.selected}>
                                                                    <TableCell>{row.name === null ? '' : row.name}</TableCell>
                                                                    <TableCell>
                                                                        Rp <NumberFormat thousandSeparator={true} fixedDecimalScale={false} value={row.price} displayType={'text'} renderText={value => <span>{value}</span>}/>
                                                                    </TableCell>
                                                                    <TableCell>{row.weight === null ? '' : row.weight}</TableCell>

                                                                </TableRow>
                                                            ))
                                                        )
                                                    )}

                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[20]}
                                            component="div"
                                            count={this.state.total}
                                            rowsPerPage={this.state.perPage}
                                            page={this.state.currentPageTable}
                                            backIconButtonProps={{
                                                'aria-label': 'previous page',
                                            }}
                                            nextIconButtonProps={{
                                                'aria-label': 'next page',
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )

    }
}

export default ContentDashboard;