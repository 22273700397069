import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import MenuItem from '@material-ui/core/MenuItem';
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class GeneralEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            id: '',
            name: '',
            value: '',
            isManage: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Ubah Konfigurasi';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Pengaturan');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        const id = this.props.match.params.id;

        Api.get('/config/'+id).then(resp => {

            if (resp.data) {

                this.setState({
                    id: resp.data.id,
                    name: resp.data.name,
                    value: resp.data.value,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'id': this.state.id,
            'name': this.state.name,
            'value': this.state.value,
            'is-active': true,
        };


        Api.put('/config', params).then(resp => {
            if(resp.status === 200) {
                history.push('/settings');
                this.showMessage(true, 'Pengaturan berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Bank</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/settings" >General</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Ubah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Ubah Pengaturan {this.state.name}</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="settings" id="editGeneral" className="row" noValidate>
                            {this.state.name === 'TUTUP_TOKO' ?
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Value</label>
                                        <TextField variant="outlined"
                                                   select
                                                   id='value'
                                                   name="value"
                                                   label="Value"
                                                   onChange={(e) => this.handleChange(e, 'value')}
                                                   value={this.state.value}
                                                   fullWidth
                                        >
                                            <MenuItem value='OPEN'>Buka Toko</MenuItem>
                                            <MenuItem value='CLOSE'>Tutup Toko</MenuItem>
                                        </TextField>
                                        {validator.message('value', this.state.value, 'required')}
                                        <div className='text-danger'>{this.state.errors.value}</div>
                                    </div>
                                </div> :
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Value</label>
                                        <TextField variant="outlined"
                                                   type='text'
                                                   id='value'
                                                   name="value"
                                                   label="Value"
                                                   onChange={(e) => this.handleChange(e, 'value')}
                                                   value={this.state.value}
                                                   fullWidth
                                        />
                                        {validator.message('value', this.state.value, 'required')}
                                        <div className='text-danger'>{this.state.errors.value}</div>
                                    </div>
                                </div>
                            }

                            <div className="col-12">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/settings')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Simpan { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default GeneralEdit;
