import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter, Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import Logo from '../../../images/logo.png';
import Collapse from '@material-ui/core/Collapse';
import icond from '../../../images/menu/dash.svg';
import icon0 from '../../../images/menu/0.svg';
import icon1 from '../../../images/menu/1.svg';
import icon2 from '../../../images/menu/2.svg';
import icon3 from '../../../images/menu/3.svg';
import icon4 from '../../../images/menu/4.svg';
import icon5 from '../../../images/menu/5.svg';
import icon6 from '../../../images/menu/6.svg';
import icon7 from '../../../images/menu/7.svg';
import icon8 from '../../../images/menu/8.svg';
import icon9 from '../../../images/menu/9.svg';
import icon10 from '../../../images/menu/10.svg';
import icon11 from '../../../images/menu/11.svg';
import icon12 from '../../../images/menu/12.svg';
import icon13 from '../../../images/menu/13.svg';
import iconmenu from '../../../images/menu/menu.svg';
import profile from '../../../images/menu/profile.svg';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {history} from "../../../shared/configure-store";

class Sidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            menus: [],
            name: '',
            role: '',
            top: false,
            data: false,
            client: false,
            projects: false,
            finance: false,
            tasks: false,
            product: false,
            blogs: false,
            pages: false,
            permissions: [],
        };
    }

    componentDidMount() {

        let profile = AuthHelper.getProfile();

        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            this.setState({
                name: profile['full-name'],
                role: profile['role'],
                permissions: roles,
            })
        }


    }

    checkPermission = (name) => {
        let all = this.state.permissions.filter((item) => item.name === name);
        if(all.length > 0 && all[0].isCreate)
            return true;
    };

    handleLogout = () => {

        AuthHelper.logOut();

    };

    handleGo  = (link) => {

        history.push(link);
    };

    openMenu  = (menu) => {

        this.setState({
            [menu]: !this.state[menu],
        })

    };

    CustomLink = to => <Link to={to} />;

    render() {

        const { location } = this.props;

        const text = location.pathname.split("/");

        return(
            <aside className={"side-nav " + (this.props.toggle_sidebar_state ? "show" : "hide")  } >
                <header className="d-none d-lg-block">
                    <nav className="navbar justify-content-center">
                        <Link to="/" className="navbar-brand m-0 p-0">
                            <img src={Logo} alt='gudangemas' className='img-fluid' />
                        </Link>
                    </nav>
                </header>
                <ul className="nav flex-column">
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {this.checkPermission('Dashboard') &&
                        <ListItem button
                                  href='/'
                                  component='a'
                                  className={location.pathname === '/' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icond} alt='ge-icon' className='img-icon' />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                        }
                        {this.checkPermission('Customer') &&
                        <ListItem button
                                  href='/customers'
                                  component='a'
                                  className={text[1] === 'customers' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon0} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Customer"/>
                        </ListItem>
                        }
                        {this.checkPermission('Admin') &&
                        <ListItem button
                                  href='/admins'
                                  component='a'
                                  className={text[1] === 'admins' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon1} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Admin"/>
                        </ListItem>
                        }

                        {/*<ListItem button onClick={() => this.handleGo('/')}
                                  className={text[1] === 'roles' ? 'active' : ''}>
                        <ListItemIcon>
                                <img src={icon2} alt='ge-icon' className='img-icon' />
                            </ListItemIcon>
                            <ListItemText primary="Role Permission"/>
                        </ListItem>*/}
                        {this.checkPermission('Riwayat Order') &&
                        <ListItem button
                                  href='/orders'
                                  component='a'
                                  className={text[1] === 'orders' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon3} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Riwayat Order"/>
                        </ListItem>
                        }
                        {this.checkPermission('Daftar Produk') &&
                        <ListItem button
                                  onClick={() => this.openMenu('product')}
                                  className={(text[1] === 'products' || text[1] === 'categories' || text[1] === 'sub-categories') ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon4} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Produk"/>
                        </ListItem>
                        }
                        <Collapse in={this.state.product} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button
                                          href='/products'
                                          component='a'>
                                    <ListItemText primary="Daftar Produk"/>
                                </ListItem>
                                <ListItem button
                                          href='/products/add'
                                          component='a'>
                                    <ListItemText primary="Tambah Produk"/>
                                </ListItem>
                                <ListItem button
                                          href='/products/import'
                                          component='a'>
                                    <ListItemText primary="Import Produk"/>
                                </ListItem>
                                {this.checkPermission('Daftar Produk') &&
                                <ListItem button
                                          href='/categories'
                                          component='a'>
                                    <ListItemText primary="Kategori"/>
                                </ListItem>
                                }
                                {this.checkPermission('Daftar Produk') &&
                                <ListItem button
                                          href='/sub-categories'
                                          component='a'>
                                    <ListItemText primary="Tag Kategori"/>
                                </ListItem>
                                }
                            </List>
                        </Collapse>

                        {this.checkPermission('Riwayat Point') &&
                        <ListItem button
                                  href='/points'
                                  component='a'
                                  className={text[1] === 'points' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon5} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Riwayat Poin"/>
                        </ListItem>

                        }
                        {this.checkPermission('Pengaturan Point') &&
                        <ListItem button
                                  href='/point-settings'
                                  component='a'
                                  className={text[1] === 'point-settings' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon6} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Pengaturan Poin"/>
                        </ListItem>
                        }
                        {this.checkPermission('Kupon') &&
                        <ListItem button
                                  href='/coupons'
                                  component='a'
                                  className={text[1] === 'coupons' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon7} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Kupon"/>
                        </ListItem>
                        }
                        {this.checkPermission('Comments') &&
                        <ListItem button
                                  href='/comments'
                                  component='a'
                                  onClick={() => this.handleGo('/comments')}
                                  className={text[1] === 'comments' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon10} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Reviews"/>
                        </ListItem>
                        }
                        {this.checkPermission('Halaman') &&
                        <ListItem button
                                  onClick={() => this.openMenu('pages')}
                                  className={(text[1] === 'pages' || text[1] === 'homes' || text[1] === 'abouts') ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon11} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Pages"/>
                        </ListItem>
                        }
                        <Collapse in={this.state.pages} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button
                                          href='/pages'
                                          component='a'>
                                    <ListItemText primary="Halaman"/>
                                </ListItem>
                                <ListItem button
                                          href='/homes'
                                          component='a'>
                                    <ListItemText primary="Home"/>
                                </ListItem>
                                <ListItem button
                                          href='/abouts'
                                          component='a'>
                                    <ListItemText primary="About"/>
                                </ListItem>
                            </List>
                        </Collapse>

                        {this.checkPermission('Blog') &&
                        <ListItem button
                                  onClick={() => this.openMenu('blogs')}
                                  className={(text[1] === 'blogs' || text[1] === 'blogs-categories') ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon12} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Blogs"/>
                        </ListItem>
                        }
                        <Collapse in={this.state.blogs} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button
                                          href='/blogs'
                                          component='a'>
                                    <ListItemText primary="Blog"/>
                                </ListItem>
                                <ListItem button
                                          href='/blogs-categories'
                                          component='a'>
                                    <ListItemText primary="Blog Categories"/>
                                </ListItem>
                            </List>
                        </Collapse>


                        {this.checkPermission('Kuis') &&
                        <ListItem button
                                  href='/quiz'
                                  component='a'
                                  className={text[1] === 'quizzes' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon13} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Kuis"/>
                        </ListItem>

                        }
                        {this.checkPermission('Menus') &&
                        <ListItem button
                                  href='/menus'
                                  component='a'
                                  className={text[1] === 'menus' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={iconmenu} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Menus"/>
                        </ListItem>
                        }
                        {this.checkPermission('Pengaturan') &&
                        <ListItem button
                                  href='/settings'
                                  component='a'
                                  className={text[1] === 'settings' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon9} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Pengaturan"/>
                        </ListItem>
                        }
                        {this.checkPermission('Media') &&
                        <ListItem button
                                  href='/medias'
                                  component='a'
                                  className={text[1] === 'media' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon8} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Media"/>
                        </ListItem>
                        }
                        {this.checkPermission('Role Permission') &&
                        <ListItem button
                                  href='/roles'
                                  component='a'
                                  className={text[1] === 'roles' ? 'active' : ''}>
                            <ListItemIcon>
                                <img src={icon2} alt='ge-icon' className='img-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Roles"/>
                        </ListItem>
                        }
                    </List>



                    <li className="nav-item profile-picture">
                       {/* <img src={profile} className='img-profile' alt='profile' />*/}
                        <div className="content">
                            <h3>{this.state.name}</h3>
                            <span>{this.state.role}</span>
                            <strong onClick={this.handleLogout} style={{cursor:'pointer'}}>Log Out</strong>
                        </div>

                    </li>


                    </ul>
            </aside>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));