import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Default from "../../../images/default.jpeg";
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import MomentUtils from "@date-io/moment/build/index";
import moment from "moment/moment";

require('dotenv').config();
const validator = new SimpleReactValidator();

class SettingsAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            types: [],

            id: '',
            link: '',
            title: '',
            content: false,
            urlImage: null,
            active: false,
            photo: Default,

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Banner';

        const data = this.props.location.state;
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            'urlImage': this.state.urlImage,
            'link': this.state.link,
            'title': this.state.title,
            'content': this.state.content,
            'type': 'BANNER',
        };


        Api.post('/admin/create-media', params).then(resp => {
            if(resp.status === 200) {
                history.push('/homes');
                this.showMessage(true, 'Media berhasil ditambah');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });

    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                urlImage: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleDeleteAvatar = () => {

        this.setState({
            photo: Default,
            logo: null
        });
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleChecked = e => {

        this.setState({

            content: e.target.checked,

        })

    };


    handleActive = e => {

        this.setState({

            title: e.target.checked,

        })

    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Media</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/homes" >Media</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Tambah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Tambah Media</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="banner" id="addBanner" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group">
                                    <img src={this.state.photo} alt="user" className="img-logo-big mr-2" />
                                    <TextField variant="outlined"
                                               type="file"
                                               id='urlImage'
                                               name="urlImage"
                                               className="avatar"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="urlImage" className="label-file mr-2">Unggah Gambar {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.urlImage}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Link</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='link'
                                               name="link"
                                               label="Link"
                                               onChange={(e) => this.handleChange(e, 'link')}
                                               value={this.state.link}
                                               fullWidth
                                    />
                                    <div className='text-danger'>{this.state.errors.link}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Open New Tab</label>
                                    <FormGroup className='mb-3 label-tnc'>
                                        <FormControlLabel
                                            color="primary"
                                            name='content'
                                            control={<Checkbox
                                                checked={this.state.content}
                                                onChange={this.handleChecked}
                                                name='content'
                                                value={true}
                                            />}
                                            label='Yes'
                                            className="mb-0"
                                        />
                                    </FormGroup>
                                    <div className='text-danger'>{this.state.errors.content}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Active</label>
                                    <FormGroup className='mb-3 label-tnc'>
                                        <FormControlLabel
                                            color="primary"
                                            name='title'
                                            control={<Checkbox
                                                checked={this.state.title}
                                                onChange={this.handleActive}
                                                name='title'
                                                value={true}
                                            />}
                                            label='Yes'
                                            className="mb-0"
                                        />
                                    </FormGroup>
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/homes')}
                                >
                                    Batal
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Tambah { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>

                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default SettingsAdd;
