import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import user from "../../../images/menu/avatar.svg";
import Button from '@material-ui/core/Button';
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class PageAdd extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            editorState: '',

            id: '',
            title: '',
            content: '',
            slug: '',
            photo: null,
            image: '',
            tags: [],

            isEditor: false,

            categories: [],
            isManage: false,
        };

        this.handleChecked = this.handleChecked.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Tambah Halaman';

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Halaman');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }
    }

    handleChange (e, prop){
        if(prop === 'title') {

            this.setState({
                slug: this.convertToSlug(e.target.value)
            })
        }

        this.setState({

            [prop]: e.target.value

        })

    };

    convertToSlug(Text) {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    onEditorStateChange = (editorState) => {
        console.log(editorState)
        this.setState({
            editorState,
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let text = '';
        if(this.state.editorState) {
            text = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
        }

        let params = {
            'title': this.state.title,
            'content': this.state.isEditor ? this.state.content : text,
            'slug': this.state.slug,
            'is-blog': false,
            'is-editor': this.state.isEditor,
        };
        Api.post('/blog-page', params).then(resp => {

            if(resp.status === 200) {
                history.push('/pages');
                this.showMessage(true, 'Halaman berhasil dibuat');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );



        }).catch(err => {
            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');
        });


    };

    handleChecked = (e) => {

        this.setState({

            isEditor: e.target.checked,

        })

    };

    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                image: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleGo  = (link) => {

        history.push(link);
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleDeleteAvatar = () => {

        this.setState({
            photo: user,
            image: null
        });
    };

    handleDelete = (chipToDelete) => () => {
        let data = this.state.categories.filter((chip) => chip.id !== chipToDelete.id);
        this.setState({
            categories: data,
        })
    };

    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocompelte options property.
            console.log(this.state.tags);
        });
    }
    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Halaman</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/pages" >Halaman</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Buat</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-6">
                                <h2 className='mb-0'>Buat Halaman</h2>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="page" id="addPage" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group slug-group">
                                    <i className="fas fa-link"> </i>
                                    <span>{process.env.REACT_APP_APP_WEB}/</span>
                                    <input className='input-link' type='text'
                                           onChange={(e) => this.handleChange(e, 'slug')}
                                           value={this.state.slug}
                                    />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label>Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='title'
                                               name="title"
                                               label="Title"
                                               onChange={(e) => this.handleChange(e, 'title')}
                                               value={this.state.title}
                                               fullWidth
                                    />
                                    {validator.message('title', this.state.title, 'required')}
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label>HTML Editor</label>
                                    <FormGroup className='mb-3 label-tnc'>
                                        <FormControlLabel
                                            color="primary"
                                            name='isEditor'
                                            control={<Checkbox
                                                checked={this.state.isEditor}
                                                onChange={this.handleChecked}
                                                name='isEditor'
                                                value={true}
                                            />}
                                            label='Ya'
                                            className="mb-0"
                                        />
                                    </FormGroup>
                                    <div className='text-danger'>{this.state.errors.isEditor}</div>
                                </div>
                            </div>

                            {this.state.isEditor ?
                                <div className="col-lg-10">
                                    <div className="form-group">
                                        <label className='d-block'>Content</label>
                                        <textarea
                                            rows="10"
                                            value={this.state.content}
                                            onChange={(e) => this.handleChange(e, 'content',)}></textarea>
                                        <div className='text-danger'>{this.state.errors.content}</div>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-10">
                                    <div className="form-group">
                                        <label>Content</label>
                                        <Editor
                                            editorState={this.state.editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={this.onEditorStateChange}
                                        />
                                        <div className='text-danger'>{this.state.errors.editorState}</div>
                                    </div>
                                </div>
                            }


                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/pages')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Tambah{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default PageAdd;
