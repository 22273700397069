import React,{Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import Button from '@material-ui/core/Button';
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../shared/configure-store";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();
const validator = new SimpleReactValidator();

class BlogEdit extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingImage: false,
            errors: {},

            editorState: '',

            id: '',
            title: '',
            content: '',
            slug: '',
            excerpt: '',
            photo: null,
            image: null,
            tags: [],

            categories: [],
            isManage: false,

            test: '{"blocks":[{"key":"fh119","text":"dfsfdsfs fsdfsd","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":9,"length":6,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}'
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Gudangemas - Ubah Blog'

        let profile = AuthHelper.getProfile();
        if(profile['map-menu']) {
            let roles = profile['map-menu'].map(o => {
                return {
                    id: o.id,
                    name: o.menu.name,
                    isCreate: o.isCreate,
                    isManage: o.isManage,
                }
            });

            let all = roles.filter((item) => item.name === 'Blog');

            if (all.length > 0) {
                this.setState({
                    isManage: all[0] ? all[0].isManage : false
                });
            }
        }

        let params = {
            page: 0,
            size: 50,
        };
        Api.post('/blog-page/get-all-category', params).then(resp => {

            if (resp.data.blogPageCategories) {
                let data = resp.data.blogPageCategories.map(o=>{
                    return {
                        label: o.name,
                        value: o.id,
                    }
                });
                this.setState({
                    categories: data,
                        loading: false
                    }
                );            }

        }).catch(err => {
            console.log(err);
        });

        const id = this.props.match.params.id;

        this.setState({
            loading: true
        });

        Api.get('/blog-page/by-slug/'+id).then(resp => {

            if (resp.data) {
                let editorState = '';
                if(!resp.data['is-editor']) {
                    let data = convertFromRaw(JSON.parse(resp.data.content));
                    editorState = EditorState.createWithContent(data);
                }

                let cats = [];
                if(resp.data.categories.length > 0) {
                    cats = resp.data.categories.map(o=>{
                        return {
                            label: o.category.name,
                            value: o.id,
                        }
                    });
                }

                this.setState({
                        id: resp.data.id,
                        title: resp.data.title,
                        content: resp.data.content,
                        photo: resp.data.image,
                        isEditor: resp.data['is-editor'],
                        editorState: editorState,
                        tags: cats,
                        slug: resp.data.slug,
                        excerpt: resp.data.excerpt,
                        loading: false
                    }
                );
            }

        }).catch(err => {
            console.log(err);
        });
    }

    handleChange (e, prop){
        if(prop === 'title') {

            this.setState({
                slug: this.convertToSlug(e.target.value)
            })
        }

        this.setState({

            [prop]: e.target.value

        })

    };

    convertToSlug(Text) {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    onEditorStateChange = (editorState) => {
        console.log(editorState)
        this.setState({
            editorState,
        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let text = '';
        if(this.state.editorState) {
            text = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
        }

        let data = this.state.tags.map(o=>{
            return {
                id: o.value,
                'is-check': true,
            }
        });

        let params = {
            'id': this.state.id,
            'title': this.state.title,
            'content': text,
            'slug': this.state.slug,
            'excerpt': this.state.excerpt,
            'is-blog': true,
            'is-editor': false,
            'categories': data,
        };


        if(this.state.image != null) {
            params = {
                'id': this.state.id,
                'title': this.state.title,
                'content': text,
                'slug': this.state.slug,
                'image': this.state.image,
                'excerpt': this.state.excerpt,
                'is-blog': true,
                'is-editor': false,
                'categories': data,
            };
        }

        Api.put('/blog-page', params).then(resp => {

            if(resp.status === 200) {
                history.push('/blogs');
                this.showMessage(true, 'Blog berhasil diubah');
            }else {
                this.showMessage(false, resp.message[0]);
            }
            this.setState({
                    loadingButton: false,
                }
            );

        }).catch(err => {
            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );
        });


    };


    handleAvatar = e => {
        let idCardBase64 = '';
        let url = e.target.files[0];
        this.setState({
            loadingImage: true,
        });

        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
        });

        setTimeout(
            () => this.setState({
                image: idCardBase64,
                photo: URL.createObjectURL(url),
                loadingImage: false,
            }),
            3000
        );
    };

    handleGo  = (link) => {

        history.push(link);
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleDeleteAvatar = () => {

        this.setState({
            photo: '',
            image: null
        });
    };

    handleDelete = (chipToDelete) => () => {
        let data = this.state.categories.filter((chip) => chip.id !== chipToDelete.id);
        this.setState({
            categories: data,
        })
    };

    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocompelte options property.
            console.log(this.state.tags);
        });
    };

    render() {
        return (
            <div className="row main-content">
                <div className="col-12">
                    <h1 className="page-title">Blog</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/blogs" >Blog</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Ubah</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-12">
                    <div className="table-wrapper">
                        <div className="row align-items-center content-title">
                            <div className="col-md-6">
                                <h2 className='mb-0'>Ubah Blog</h2>
                            </div>
                            <div className="col-md-6 text-right">
                                <a href={process.env.REACT_APP_APP_WEB+'/blogs/'+this.state.slug} target='_blank' className='btn-link'>
                                    <i className="fas fa-globe-europe mr-2"> </i>
                                    Buka Halaman
                                </a>
                            </div>
                        </div>
                        <h3 className='section-title'> </h3>

                        <form name="blog" id="editBlog" className="row" noValidate>
                            <div className="col-12">
                                <div className="form-group slug-group">
                                    <i className="fas fa-link"> </i>
                                    <span>{process.env.REACT_APP_APP_WEB}/blogs/</span>
                                    <input className='input-link' type='text'
                                           onChange={(e) => this.handleChange(e, 'title')}
                                           value={this.state.slug}
                                    />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label>Title</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='title'
                                               name="title"
                                               label="Title"
                                               onChange={(e) => this.handleChange(e, 'title')}
                                               value={this.state.title}
                                               fullWidth
                                    />
                                    {validator.message('title', this.state.title, 'required')}
                                    <div className='text-danger'>{this.state.errors.title}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='d-block'>Featured Image</label>

                                    {this.state.photo &&
                                    <img src={this.state.photo} alt="user" className="img-thumbnail mr-2" />
                                    }
                                    <TextField variant="outlined"
                                               type="file"
                                               id='avatar'
                                               name="avatar"
                                               className="avatar"
                                               onChange={this.handleAvatar}
                                    />
                                    <label htmlFor="avatar" className="label-file mr-2">Upload Foto {this.state.loadingImage && <i className="fa fa-spinner fa-spin"> </i>}</label>
                                    <button className='btn-delete' type='button' onClick={this.handleDeleteAvatar}>
                                        Hapus Foto
                                    </button>
                                    <div className='text-danger'>{this.state.errors.avatar}</div>

                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label className='d-block'>Kategori</label>

                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        onChange={this.onTagsChange}
                                        options={this.state.categories}
                                        value={this.state.tags}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Kategori"
                                                placeholder="Kategori"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label className='d-block'>Excerpt</label>
                                    <TextField variant="outlined"
                                               type='text'
                                               id='excerpt'
                                               name="excerpt"
                                               label="Excerpt"
                                               onChange={(e) => this.handleChange(e, 'excerpt')}
                                               value={this.state.excerpt}
                                               fullWidth
                                               multiline
                                    />
                                    {validator.message('excerpt', this.state.excerpt, 'required')}
                                    <div className='text-danger'>{this.state.errors.excerpt}</div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="form-group">
                                    <label>Content</label>
                                    <Editor
                                        editorState={this.state.editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                    <div className='text-danger'>{this.state.errors.bod}</div>
                                </div>
                            </div>


                            <div className="col-12 text-center">
                                <Button
                                    className="mr-3"
                                    variant="contained"
                                    onClick={() => this.handleGo('/admins')}
                                >
                                    Batal
                                </Button>
                                {this.state.isManage &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadingButton}
                                >
                                    Simpan { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </Button>
                                }
                            </div>
                        </form>
                    </div>

                </div>
                <ToastContainer />

            </div>
        )
    }
}

export default BlogEdit;
